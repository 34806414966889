import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table"
import { useSticky } from "react-table-sticky"
import AutoSizer from "react-virtualized-auto-sizer"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden"
import PropTypes from "prop-types"
import {
  allPass,
  assoc,
  compose,
  defaultTo,
  filter,
  has,
  head,
  identity,
  keys,
  map,
  min,
  pluck,
  propEq,
  reduce,
  reject,
  symmetricDifference,
} from "ramda"

import {
  CaretDownIcon,
  DownloadIconLight,
  EllipsisHIcon,
  ExclamationCircle,
  ExternalLinkIconLight,
  MagnifyingGlass,
  OpenArrowIcon,
  PlusIcon,
  RefreshLightIcon,
  RegularInfoCircleIcon,
  SettingsIcon,
  SortDownIcon,
  SortUpIcon,
} from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { getTextSize, isRequiredIf, sizer } from "@ninjaone/utils"
import { useMountedState, usePrevious } from "@ninjaone/webapp/src/js/includes/common/hooks"
import showModal from "@ninjaone/webapp/src/js/includes/common/services/showModal"
import { byColumnSort, getLocalStorageParsedObject, isNotEmpty } from "@ninjaone/webapp/src/js/includes/common/utils"
import {
  isNotNilOrEmpty,
  localizationKey,
  localized,
} from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

import Checkbox from "../Checkbox"
import { Box, Flex, visuallyHiddenStyles } from "@ninjaone/webapp/src/js/includes/components/Styled"
import ExternalLink from "js/includes/components/ExternalLink"
import LastUpdatedLabel from "./Components/LastUpdatedLabel"
import Row from "./Components/Row"
import { textBaseClassName } from "./Components/Text"
import ShowSelectedButton from "./Components/ShowSelectedButton"
import CustomFilters from "./Filters/CustomFilters"
import DataTableSettingsModal from "./DataTableSettingsModal"
import GlobalFilter from "./GlobalFilter"
import Button from "../Button"
import Dropdown from "../Dropdown"
import IconButton from "../IconButton"
import Text, { preventNativeTooltipInSafariStyles } from "../Text"
import TitleGroup from "../TitleGroup"
import Tooltip from "../Tooltip"
import {
  cellTypes,
  forceColumnWidth,
  getDisabledRows,
  getHasMultiplePages,
  getIsSortedDescFromPreviousValue,
  getSelectAllCount,
  getSwitchContainerStyles,
  getSwitchSize,
  rowStatusTypes,
  runAfterDataTableRenders,
} from "./utils"
import { StyledEmptyTable } from "../Table"
import { fixDragPreviewOverlappingContentStyle } from "../Styled"

import {
  useAutoRefreshPolling,
  useAutoRefreshPubSub,
  useCustomFilters,
  useDragAndDropRenderer,
  useKeyboardActions,
  useLazyRowLoading,
  useRemoteData,
  useRowStateRenderer,
  useShowSelected,
  useStatusIndicatorRenderer,
} from "./hooks"
import { getLocalStorageKeys } from "./tableSettingsUtils"

const { width, height } = getSwitchSize("md")
const rowHoverColor = ({ theme }) => theme.colorForegroundHover

export const pluralResultsLabelToken = localizationKey("{{results}} results")
export const multiplePagesPaginationToken = localizationKey("{{start}} - {{end}} of {{total}}")
export const selectAllOnCurrentPageToken = localizationKey("Select {{count}} on current page")
export const unselectAllOnCurrentPageToken = localizationKey("Unselect {{count}} on current page")
export const selectAllResultsToken = localizationKey("Select all {{count}} result(s)")
export const unselectAllResultsToken = localizationKey("Unselect all {{count}} result(s)")

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${({ theme }) => theme.colorTextWeak};
  background-color: transparent;
  border-radius: ${tokens.spacing[1]};
  gap: ${tokens.spacing[2]};

  .data-table-settings {
    margin-top: auto;
    justify-content: flex-end;
    min-height: 32px;
  }

  @keyframes skeleton-pulse {
    0% {
      transform: translateX(-200px);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .data-table-graphic-wrapper {
    display: flex;
  }

  &.is-loading,
  &.is-reactive-loading td.no-value {
    .data-table-cell {
      position: relative;
      min-height: 21px;
      overflow: hidden;
      background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};

      &[style] {
        max-width: none !important; // override any inline styles to allow skeletons to stretch
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        animation: skeleton-pulse 1300ms ease-in-out infinite;
        background-image: linear-gradient(
          90deg,
          ${({ theme }) => theme.colorBackgroundAccentNeutralWeak},
          ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest},
          ${({ theme }) => theme.colorBackgroundAccentNeutralWeak}
        );
        background-size: 200px 100%;
        background-repeat: no-repeat;
        border-radius: ${sizer(1)};
      }
    }

    .data-table-text-wrapper {
      display: none;
    }

    .data-table-graphic-wrapper {
      display: none;
    }
  }

  &.skeleton-rows-only {
    margin-top: 10px;

    .pagination-skeleton {
      margin-left: ${sizer(4)};
      margin-bottom: 8px;
      width: 180px;
      height: 24px;
    }

    table tr:first-of-type td {
      border-top: 0;
    }
  }

  table.hidden-headers {
    thead {
      ${visuallyHiddenStyles}
    }

    tr:first-of-type td {
      border-top: 0;
    }
  }
`

const headerCellZIndex = 99
export const selectionHeaderCellZIndex = headerCellZIndex + 1

const StyledDataTable = styled.div`
  height: 100%;

  table {
    border-spacing: 0;
    border-collapse: separate;
    width: 100%;

    thead th {
      position: sticky;
      top: 0;
      z-index: ${headerCellZIndex};

      &.data-table-header-cell-selection {
        z-index: ${selectionHeaderCellZIndex} !important; // needs to override inline style added by react-table-sticky
      }
    }

    th {
      user-select: none;

      background-color: ${({ theme }) => theme.colorBackground};
      border-color: ${({ theme }) => theme.colorBorderWeakest};
      color: ${({ theme }) => theme.colorTextStrong};

      &:focus-visible {
        outline-offset: -1px;
        outline: 2px auto ${({ theme }) => theme.colorForegroundFocus};
      }
    }

    tr {
      &[data-selected="true"] {
        background-color: ${({ theme }) => theme.colorForegroundSelected};
      }

      &.pointer {
        cursor: pointer;
      }
    }

    td {
      height: 1px; // hack to get child to flex to this correctly
      border-top: 1px solid ${({ theme }) => theme.colorBorderWeakest};

      [data-visible-on-row-hover] {
        visibility: hidden;
      }

      background-color: transparent;

      &[data-no-pad="true"] {
        padding: 0;

        &:last-of-type {
          padding-right: 0;
        }
      }

      &[data-error="true"] {
        color: ${({ theme }) => theme.colorBorderError};
      }

      &:focus-visible {
        outline-offset: -1px;
        outline: 2px auto ${({ theme }) => theme.colorForegroundFocus};

        .data-table-interactive-cell {
          color: ${({ theme }) => theme.colorTextAction};
        }

        [data-visible-on-row-hover] {
          background-color: transparent;
          visibility: visible;
        }
      }
    }

    th,
    td {
      position: relative; // this fixes safari not displaying cells when horizontal scrollbar isn't present
      text-align: left;

      padding: ${tokens.spacing[2]} ${tokens.spacing[3]};

      &:first-of-type {
        padding-left: ${tokens.spacing[4]};
      }

      &:last-of-type {
        padding-right: ${tokens.spacing[4]};
      }
    }

    th {
      border-color: ${({ theme }) => theme.colorBorderWeakest};
    }

    tbody {
      tr:focus {
        outline: none;

        td {
          border-top: 1px solid ${({ theme }) => theme.colorBorderDecorativeStrong};
        }

        + tr {
          td {
            border-top: 1px solid ${({ theme }) => theme.colorBorderDecorativeStrong};
          }
        }

        &:last-child {
          td {
            border-bottom: 1px solid ${({ theme }) => theme.colorBorderDecorativeStrong};
          }
        }
      }

      tr.dnd-hover td {
        background-color: ${rowHoverColor};
      }

      tr:not(.no-hover).force-hover,
      tr:hover:not(.no-hover) {
        background-color: ${rowHoverColor};

        td {
          [data-visible-on-row-hover] {
            background-color: transparent;
            visibility: visible;
          }

          a {
            color: ${({ theme }) => theme.colorTextAction};
          }
        }

        .data-table-cell-selection {
          background-color: ${rowHoverColor};
        }

        .data-table-button-link {
          color: ${({ theme }) => theme.colorTextAction};
        }
      }

      tr[data-selected="false"] td.data-table-cell-selection {
        background-color: ${({ theme }) => theme.colorBackground};
      }

      tr[data-selected="true"] td.data-table-cell-selection {
        background-color: ${({ theme }) => theme.colorForegroundSelected};
      }

      tr[data-selected="false"] td.data-table-cell-actions {
        background-color: transparent;
        &:hover {
          // This is necessary to avoid actions cells overlapping the dropdown container
          z-index: 4 !important;
        }
      }

      tr[data-disabled="true"] {
        color: ${({ theme }) => theme.colorTextWeakest};

        span[data-state="checked"] svg {
          display: none;
        }
      }

      tr[data-disabled="true"][data-selected="true"] {
        background-color: ${({ theme }) => theme.colorBackgroundWidget};

        td.data-table-cell-selection {
          background-color: ${({ theme }) => theme.colorBackgroundWidget};
        }
      }
    }

    [data-sticky-last-left-td] {
      + th,
      + td {
        border-left: 0;
      }
    }

    .data-table-row {
      > th,
      > td {
        ${({ priorityWidthColumnIdx, hasActions, hasCheckboxes, hasStatusIndicators }) => {
          if (priorityWidthColumnIdx === -1) {
            return `
            &:nth-last-of-type(${hasActions ? 2 : 1}) {
              width: 100%;
            }
            `
          } else {
            let totalOffset = hasStatusIndicators ? 2 : 1
            totalOffset += hasCheckboxes ? 1 : 0
            return `
            &:nth-of-type(${priorityWidthColumnIdx + totalOffset}) {
              width: 100%;
            }
            `
          }
        }}
        height: 38px;
      }

      &.cursor-pointer {
        cursor: pointer;
      }

      &.greyed-out > td:not(.data-table-cell-selection) > * {
        opacity: 0.45;
      }
    }
  }

  .data-table-text-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.flex-shrink-0 {
      flex-shrink: 0;
    }
  }

  .data-table-cell {
    display: flex;
    align-items: center;

    // padding to match space for sorting icon
    padding-right: ${sizer(5)};

    &.right-aligned {
      padding-right: 0;
      padding-left: ${sizer(5)};
    }

    &.gap-2 {
      gap: ${sizer(2)};
    }

    &.gap-4 {
      gap: ${sizer(4)};
    }

    svg:not([color]) {
      color: ${({ theme }) => theme.colorTextWeakest};
    }

    .secondary-icon-container {
      width: ${sizer(5)};
    }
  }

  .data-table-cell-selection[data-sticky-last-left-td] {
    ${forceColumnWidth("60px")}
  }

  a {
    color: ${({ theme }) => theme.colorTextWeak};
    text-decoration: none;

    &:disabled {
      color: ${({ theme }) => theme.colorTextDisabled};
    }
  }

  .data-table-button-link {
    cursor: pointer;
    user-select: none;
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    width: auto;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  }

  .${textBaseClassName} {
    font-size: ${getTextSize("sm")};
    line-height: 1.5;
    font-weight: 400;
    user-select: auto;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;

    &.no-text-wrap {
      white-space: nowrap;
    }

    &.show-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.enable-tooltip {
      ${preventNativeTooltipInSafariStyles};
    }

    &.clamp-lines {
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    &.bold {
      font-weight: 500; // matches bold in Text
    }

    &.description {
      color: ${({ theme }) => theme.colorTextWeakest};
      font-size: ${tokens.typography.fontSize.bodyXs};
    }

    .cell-span {
      margin-left: ${sizer(1)};
    }
  }

  .data-table-copy-to-clipboard {
    border: 0;
    background: none;
    display: inherit;

    svg {
      color: ${({ theme }) => theme.colorTextWeakest};
    }

    &:hover {
      svg {
        color: ${({ theme }) => theme.colorTextStrong};
      }
    }
  }

  .data-table-fake-switch-container {
    ${({ theme }) => getSwitchContainerStyles({ width, color: theme.colorTextStrong })};
  }

  .data-table-fake-switch {
    height: ${height}px;
    width: ${width}px;
    margin: 0;
    position: relative;
    background: ${({ theme }) => theme.colorBackgroundAccentNeutral};
    border-radius: 10px;
    cursor: pointer;

    &:before {
      content: "";
      height: 18px;
      width: 18px;
      background: ${({ theme }) => theme.colorBackground};
      display: inline-block;
      border-radius: 50%;
      position: absolute;
      transform: translateX(1px);
      top: 1px;
      outline: 0px;
      border: 0px;
    }

    &.checked {
      background: ${({ theme }) => theme.colorForegroundSelectedStrongest};

      &::before {
        transform: translateX(21px);
      }
    }
  }

  .data-table-inline-action-wrap {
    display: flex;
    height: 100%;
    padding: ${sizer(0, 2)};
    justify-content: flex-end;
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  .data-table-inline-action {
    height: 100%;
    display: flex;
    gap: ${sizer(2)};
    align-items: center;

    button {
      width: 32px;
      height: 32px;

      :not(:disabled) {
        background-color: ${({ theme }) => theme.colorBackgroundCta};
      }

      :focus-visible {
        outline-offset: -2px;
        outline-style: solid;
        outline-color: ${({ theme }) => theme.colorForegroundFocus};
      }
    }

    .actions-dropdown-button {
      button {
        border: 0;
        margin: 0;
        border-radius: ${tokens.borderRadius["1"]};

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        background-color: ${({ theme }) => theme.colorBackground};
        border: 1px solid ${({ theme }) => theme.colorBorderWeak};

        &[aria-expanded="true"] {
          visibility: visible;
        }

        &:hover {
          background-color: ${({ theme }) => theme.colorForegroundHover};
        }
      }

      svg {
        font-size: 1rem;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.colorTextStrong};
      }
    }
  }

  .status-indicator-column {
    ${forceColumnWidth("6px")}

    left: 0px !important;
    padding: 0 !important;
  }

  .status-indicator-cell {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 100%;
  }

  .dnd-row {
    &[draggable="true"] {
      ${fixDragPreviewOverlappingContentStyle}
    }
  }

  .dnd-cell {
    .content {
      display: flex;
    }

    .value {
      width: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .status-indicator {
    width: 100%;
    height: 90%;
  }

  .hover-cursor-grab {
    cursor: grab;
  }

  @supports (-moz-appearance: none) {
    .status-indicator-row {
      height: 100%;

      .status-indicator-column {
        height: 100%;
      }
    }
  }
`

const StyledTableHeader = styled.div`
  display: flex;
  font-size: ${getTextSize("sm")};
  font-weight: 500;
  user-select: auto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
  white-space: nowrap;
  align-items: center;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};

  flex-direction: ${({ isRightAligned }) => (isRightAligned ? "row-reverse" : "row")};
  justify-content: ${({ isRightAligned }) => (isRightAligned ? "end" : "unset")};
`
const StyledSort = styled.div`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  color: ${({ theme }) => theme.colorTextWeak};
`
const StyledAccessoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledFlexRowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  grid-column-gap: ${sizer(2)};
  grid-row-gap: ${sizer(2)};

  align-items: center;

  &:empty {
    display: none;
  }
`
const StyledFlexRow = styled.div`
  display: flex;
  grid-column-gap: ${({ gap }) => gap || sizer(2)};

  align-items: center;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? "space-between" : "")};

  ${({ flex }) => flex && `flex: ${flex}`};
`
const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: ${sizer(2)};
  height: 100%;

  &:first-of-type {
    flex: 1;
  }
`
const StyledActionsContainer = styled.div`
  height: 32px;

  display: flex;
  align-items: center;
  grid-column-gap: ${sizer(2)};

  visibility: ${({ showActions }) => (showActions ? "visible" : "hidden")};
`

const StyledMoreActionsDropdownContainer = styled.div`
  height: 32px;
`

const StyledSelectDropdownContainer = styled.div`
  [data-radix-custom-checkbox-input] {
    pointer-events: none;
  }

  [data-ninja-dropdown-trigger] {
    cursor: pointer;
    height: ${sizer(5)};
    display: flex;
    align-items: center;

    &:hover,
    &[aria-expanded="true"] {
      ::after {
        content: "";
        position: absolute;

        top: 1px;
        right: 6px;
        bottom: 1px;
        left: 6px;

        border-radius: ${sizer(1)};

        z-index: -1;
        background-color: ${({ theme }) => theme.colorForegroundHover};
      }
    }
  }
`

export const StyledActionsButton = styled(Button)`
  max-height: 32px;
  height: 32px;

  padding-left: 12px;
  padding-right: 12px;

  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
  ${({ borderColor }) => borderColor && `border-color: ${borderColor};`}
`

const StyledGlobalActionsButton = styled(StyledActionsButton)`
  height: 38px;
  min-height: 38px;
`

// This is implemented because we can't disable the modal functionality for the Select component.
// Check: https://github.com/radix-ui/primitives/issues/1496#issuecomment-1172107676
const ScrollableBox = styled(Box)`
  overflow: auto;
  scroll-padding-top: 38px; // must be height of thead

  body.ninja-select-open & {
    overflow: hidden;
  }
`

function useActionsRenderer({
  actions,
  actionButtons,
  unknownTotalCount,
  hideCheckboxes,
  actionsRenderer,
  enableLazyLoadRows,
  handleTogglePageRows,
  handleToggleAllRows,
  pageSizeLimit,
  hideSelectAllDropdownOption,
  hideSelectPageDropdownOption,
  onSelectRows,
  getCustomRowProps,
  loadedRowsWithDisabled,
  showSelected,
  showSelectedToggleAllPageRowsSelected,
  showSelectedToggleAllRowsSelected,
  fetchData,
  totalCount,
  omitDisabledInSelection,
}) {
  return hooks => {
    if (actions?.primary?.length || actionsRenderer || onSelectRows) {
      hooks.visibleColumns.push(columns => [
        ...(!hideCheckboxes
          ? [
              {
                id: "selection",
                sticky: "left",
                disableSortBy: true,
                Header: ({
                  getToggleAllPageRowsSelectedProps,
                  getToggleAllRowsSelectedProps,
                  pageCount,
                  page,
                  rows,
                  toggleAllRowsSelected,
                  toggleAllPageRowsSelected,
                  isAllRowsSelected,
                  selectedFlatRows,
                  toggleRowSelected,
                  state: { totalRowCount, selectedRowIds },
                  dispatch,
                }) => {
                  const { title, checked: isAllPageRowsSelected } = getToggleAllPageRowsSelectedProps()
                  const { checked: isChecked } = getToggleAllRowsSelectedProps()
                  const hasMultiplePages = getHasMultiplePages({
                    enableLazyLoadRows,
                    totalRowCount,
                    pageSizeLimit,
                    pageCount,
                    showSelected,
                    rowsSelectedCount: selectedFlatRows.length,
                  })
                  const {
                    isAllSelectableRowsSelected,
                    isAllSelectablePageRowsSelected,
                    disableSelectAll,
                    selectableRowsCount,
                    selectablePageRowsCount,
                  } = getDisabledRows({
                    rows,
                    page,
                    hasMultiplePages,
                    getCustomRowProps,
                    loadedRowsWithDisabled,
                  })

                  const checked =
                    showSelected ||
                    (loadedRowsWithDisabled
                      ? (isAllRowsSelected || isAllSelectableRowsSelected) && !disableSelectAll
                      : isChecked)

                  const isAllOnCurrentPageSelected =
                    isAllPageRowsSelected || (loadedRowsWithDisabled && isAllSelectablePageRowsSelected) || showSelected

                  const disabledCheckbox = !rows?.length || disableSelectAll
                  const checkboxCustomProps = disabledCheckbox && { disabled: true, style: { cursor: "not-allowed" } }

                  return hasMultiplePages ? (
                    <StyledSelectDropdownContainer>
                      <Dropdown
                        childIsButton
                        disabled={disabledCheckbox}
                        onOpenChange={open => {
                          /**
                           * This is to focus on the cell when we close the dropdown.
                           */
                          if (!open) {
                            document.querySelector(".data-table-header-cell-selection").focus()
                          }
                        }}
                        options={[
                          ...(hideSelectPageDropdownOption
                            ? []
                            : [
                                {
                                  LabelComponent: () => (
                                    <Text
                                      size="sm"
                                      token={
                                        isAllOnCurrentPageSelected
                                          ? unselectAllOnCurrentPageToken
                                          : selectAllOnCurrentPageToken
                                      }
                                      tokenArgs={{
                                        count: loadedRowsWithDisabled ? selectablePageRowsCount : page.length,
                                      }}
                                    />
                                  ),
                                  action: () => {
                                    if (enableLazyLoadRows || fetchData) {
                                      handleTogglePageRows(!isAllPageRowsSelected, page)
                                    }

                                    if (omitDisabledInSelection && getCustomRowProps) {
                                      page.forEach(({ original, isSelected, toggleRowSelected }) => {
                                        if (
                                          !getCustomRowProps(original).disabled &&
                                          isSelected === isAllOnCurrentPageSelected
                                        ) {
                                          toggleRowSelected(!isAllOnCurrentPageSelected)
                                        }
                                      })
                                    } else {
                                      toggleAllPageRowsSelected()
                                    }
                                    showSelectedToggleAllPageRowsSelected(page, !isAllOnCurrentPageSelected)
                                  },
                                  id: "toggle-select-current",
                                },
                              ]),
                          ...(!unknownTotalCount && !hideSelectAllDropdownOption
                            ? [
                                {
                                  LabelComponent: () => (
                                    <Text
                                      size="sm"
                                      token={checked ? unselectAllResultsToken : selectAllResultsToken}
                                      tokenArgs={{
                                        count: getSelectAllCount({
                                          enableLazyLoadRows,
                                          totalRowCount,
                                          loadedRowsWithDisabled,
                                          selectableRowsCount,
                                          rows,
                                          fetchData,
                                          totalCount,
                                        }),
                                      }}
                                    />
                                  ),
                                  action: () => {
                                    if (unknownTotalCount) {
                                      // TODO: this will need to be special cased by server.
                                      // The current plan is to have a boolean and a list.
                                      // The boolean controls if the list is inclusive or
                                      // exclusive.
                                      // please reach out to Ryan Kersh for more
                                      // information.
                                    } else {
                                      if (enableLazyLoadRows || fetchData) {
                                        handleToggleAllRows(!checked)
                                      }

                                      if (omitDisabledInSelection && getCustomRowProps) {
                                        rows.forEach(({ original, id }) => {
                                          !getCustomRowProps(original).disabled && toggleRowSelected(id, !checked)
                                        })
                                      } else {
                                        toggleAllRowsSelected()
                                      }
                                      if (fetchData && checked) {
                                        dispatch({ type: "clearSelection" })
                                      }
                                      showSelectedToggleAllRowsSelected(rows, !checked)
                                    }
                                  },
                                  id: "toggle-select-all",
                                },
                              ]
                            : []),
                        ]}
                        variant="compact"
                      >
                        <div>
                          <Checkbox
                            {...{
                              title,
                              // We should focus the cell (that triggers the action), not the Checkbox.
                              tabIndex: -1,
                              ...checkboxCustomProps,
                              onClick: e => e.preventDefault(),
                              ariaLabel: localized("Select All"),
                              checked:
                                checked ||
                                (selectedFlatRows.length || (fetchData && isNotEmpty(selectedRowIds))
                                  ? "partial"
                                  : false),
                            }}
                          />
                          <CaretDownIcon size="xs" fixedWidth={false} color="colorTextWeakest" />
                        </div>
                      </Dropdown>
                    </StyledSelectDropdownContainer>
                  ) : (
                    <Checkbox
                      {...{
                        title,
                        tabIndex: -1,
                        checked: checked || (selectedFlatRows.length ? "partial" : false),
                        onChange: () => {
                          if (enableLazyLoadRows) {
                            handleTogglePageRows(!isAllPageRowsSelected, page)
                          }

                          if (omitDisabledInSelection && getCustomRowProps) {
                            rows.forEach(({ original, id }) => {
                              !getCustomRowProps(original).disabled && toggleRowSelected(id, !checked)
                            })
                          } else {
                            toggleAllRowsSelected()
                          }

                          showSelectedToggleAllPageRowsSelected(page, !isAllPageRowsSelected)
                        },
                        ...checkboxCustomProps,
                        ariaLabel: localized("Select All"),
                      }}
                    />
                  )
                },
              },
            ]
          : []),
        ...columns,
        ...(actions?.primary?.length
          ? [
              {
                id: "actions",
                sticky: "right",
                canSort: false,
                Header: () => null,
              },
            ]
          : []),
      ])
    }
  }
}

const getStateReducer = ({ fetchData, enableLazyLoadRows }) => {
  return (newState, action) => {
    const { type, totalRowCount, selectedRowIds } = action
    // intercept actions that should reset the current page
    if (fetchData && ["toggleSortBy", "setGlobalFilter"].includes(type)) {
      return { ...newState, pageIndex: 0 }
    }
    if (enableLazyLoadRows && type === "totalRowCountUpdate") {
      return { ...newState, totalRowCount }
    }
    if (type === "manuallySetSelectedRowIds") {
      return { ...newState, selectedRowIds }
    }
    if (type === "clearSelection") {
      return { ...newState, selectedRowIds: {} }
    }
    return newState
  }
}

const showSecondaryMultiActions = (actions, selected) =>
  actions?.length > 0 && actions.some(({ hideMultiAction }) => !hideMultiAction?.(selected))

const getActiveFilters = filter(f => isNotNilOrEmpty(f.value) || isNotNilOrEmpty(f.initialValue))

const defaultActions = {
  // Keep primary actions length minimal (1-4). Put rest in secondary
  primary: [],
  secondary: [],
  row: null,
}

const disableColumnsSort = map(assoc("disableSortBy", true))
const TABLE_HEADER_ID_PREFIX = "header_"

export default function DataTable({
  autoResetPage = true,
  resultsCountLabel,
  pageSizeLimit = 250,
  tableId,
  rowLabelColumn = "name",
  columns: _columns,
  shouldUseUserSettings = true,
  visibleColumnsIds: _visibleColumnsIds = [],
  onSettingsChange,
  rows: rowsData,
  loading,
  searchPlaceholderText,
  globalFilterClearButtonId,
  onGlobalFilterChange,
  globalFilterCharLimit,
  enableManualGlobalFilter = false,
  shouldTrimGlobalFilter = false,
  initialGlobalFilter,
  noRowsToken,
  onRefresh,
  actions = defaultActions,
  actionButtons,
  titleGroup,
  filters = {
    // Keep primary filters length minimal (1-4). Put rest in secondary
    primary: [],
    secondary: [],
  },
  clearFiltersOnReset = false,
  onClearFilters,
  fetchData,
  hidePaginationButtons = false,
  hideResultsCount = false,
  hideCheckboxes,
  initialSortBy: _initialSortBy = [],
  settingsSaveButtonToken = localizationKey("Save"),
  hideSettingsButton,
  getExportButtonProps,
  globalActionsButton = {},
  secondaryGlobalActions = [],
  secondaryGlobalActionsButtons = [],
  showSearchBar = true,
  searchBarFullWidth,
  searchBarAutoFocus,
  additionalColumnSettings,
  actionsRenderer,
  onSelectRows,
  lazyLoadRows,
  autoResetSelectedRows = true,
  isShowSelectedEnabled: _isShowSelectedEnabled = false,
  dataKey = "id",
  autoResetSortBy = true,
  getRowId,
  onSort,
  manualSortBy,
  manualPagination,
  getStatusIndicatorColor,
  getStatusIndicatorBorderColor,
  scrollToTopIfDataChanges,
  maxColumnCount = 20,
  hideSelectAllDropdownOption,
  hideSelectPageDropdownOption,
  externalLinkUrl,
  externalLinkLabelToken,
  isReactive,
  selectedRowIds: _selectedRowIds = [],
  autoRefresh = {},
  displayedSettingsFields = {
    sort: true,
    columns: true,
  },
  dragAndDrop,
  hideFilters = false,
  getCustomRowProps,
  error,
  resetPageOnFetchChange,
  SearchComponent = GlobalFilter,
  onSettingsModalMount,
  onSettingsModalUnmount,
  omitDisabledInSelection,
  enableAutoHeight = false,
  hideHeaders = false,
  usesStateSelectedRows = false,
}) {
  const scrollableBoxRef = useRef(null)
  const paginationContainerRef = useRef(null)
  const pageIndexRef = useRef(0)
  const tableHeaderRef = useRef(null)
  const sortByRef = useRef(_initialSortBy.length ? _initialSortBy[0] : null)
  const theme = useTheme()

  const hasActions = !!actions?.primary?.length
  const hasRowSelection = hasActions || actionsRenderer || onSelectRows
  const hasCheckboxes = hasRowSelection && !hideCheckboxes
  const isShowSelectedEnabled = _isShowSelectedEnabled && hasCheckboxes

  const defaultGetRowId = useCallback(row => row[dataKey], [dataKey])

  const { customFilters, filterFunctionList, updateFilter, clearFilters } = useCustomFilters(
    filters,
    onClearFilters,
    clearFiltersOnReset,
  )
  const activeFilters = useMemo(() => getActiveFilters(customFilters), [customFilters])
  const [lastUpdated, setLastUpdated] = useMountedState(null)
  const [tableColumnWidths, setTableColumnWidths] = useState({})

  const storeTableColumnsWidths = useCallback(() => {
    const tableHeaders = tableHeaderRef.current?.children[0]?.children ?? []
    const columnsWidths = [...tableHeaders].reduce((acc, header) => {
      const id = header.id?.split(TABLE_HEADER_ID_PREFIX)[1]
      acc[id] = header.getBoundingClientRect().width
      return acc
    }, {})
    setTableColumnWidths(columnsWidths)
  }, [setTableColumnWidths])

  const {
    remoteData,
    isLoading: isLoadingRemoteData,
    setIsLoading,
    setPaginationProps,
    pageCount: remotePageCount,
    totalCount,
    updateRemoteData,
    isError: isErrorRemoteData,
  } = useRemoteData(fetchData, activeFilters, setLastUpdated, storeTableColumnsWidths)

  const isLoading = isLoadingRemoteData || loading
  const isError = isErrorRemoteData || error

  const { pubsubChannel, autoRefreshEnabledTooltipToken, pollingTime } = autoRefresh || {}
  const defaultAutoRefreshEnabled = false
  const canAutoRefresh = !!pubsubChannel || !!pollingTime

  const skeletonRows = useMemo(() => Array(min(20, pageSizeLimit)).fill({}), [pageSizeLimit])

  const { onDrop, columnHeader: dndColumnHeader } = dragAndDrop || {}
  const usingDragAndDrop = !!onDrop
  const columns = useMemo(() => (usingDragAndDrop ? disableColumnsSort(_columns) : _columns), [
    _columns,
    usingDragAndDrop,
  ])

  const showSelectedOptions = {
    isShowSelectedEnabled,
    _selectedRowIds,
    getCustomRowProps,
    rowsData,
    paginationContainerRef,
    onSelectRows,
    getRowId: getRowId || defaultGetRowId,
  }

  const {
    showSelected,
    showSelectedRowIds,
    paginationContainerWidth,
    toggleShowSelected,
    showSelectedToggleRowSelected,
    showSelectedToggleAllPageRowsSelected,
    showSelectedToggleAllRowsSelected,
    showSelectedTableData,
  } = useShowSelected(showSelectedOptions)

  const tableData = useMemo(() => {
    if (fetchData) return remoteData

    return filter(allPass(filterFunctionList), rowsData)
  }, [fetchData, filterFunctionList, remoteData, rowsData])

  const localStorageKeys = getLocalStorageKeys(tableId)
  const settingsButtonVisible = shouldUseUserSettings && !hideSettingsButton

  const getSortByValue = () => {
    const userSortBy = getLocalStorageParsedObject(localStorageKeys.initialSortBy)
    return settingsButtonVisible && userSortBy?.length && displayedSettingsFields.sort ? userSortBy : _initialSortBy
  }

  const getVisibleColumnIdValue = () => {
    const userColumns = getLocalStorageParsedObject(localStorageKeys.visibleColumnsIds)
    return settingsButtonVisible && userColumns && displayedSettingsFields.columns ? userColumns : _visibleColumnsIds
  }

  const [visibleColumnsIds, setVisibleColumnsIds] = useState([])
  const [initialSortBy, setInitialSortBy] = useState(getSortByValue)

  const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(() => {
    /**
     * In the case this table uses the feature for the first time and we want it to be On by default,
     * the local storage will return [ null ] so we default it to the initial value of [ isOnByDefault ].
     */
    const userAutoRefreshEnabled = defaultTo(
      !!autoRefresh.isOnByDefault,
      getLocalStorageParsedObject(autoRefresh.localStorageKey || localStorageKeys.autoRefreshEnabled),
    )

    return shouldUseUserSettings && userAutoRefreshEnabled && canAutoRefresh
      ? userAutoRefreshEnabled
      : defaultAutoRefreshEnabled
  })

  const [lastSelectedRow, setLastSelectedRow] = useState(null)

  const visibleColumns = useMemo(() => {
    return !visibleColumnsIds.length
      ? columns
      : compose(
          filter(identity),
          map(id => columns.find(col => col.id === id)),
        )(visibleColumnsIds)
  }, [columns, visibleColumnsIds])

  const defaultVisibleColumns = useMemo(() => {
    return !_visibleColumnsIds.length
      ? columns
      : compose(
          filter(identity),
          map(id => columns.find(col => col.id === id)),
        )(_visibleColumnsIds)
  }, [columns, _visibleColumnsIds])

  const loadedRowsWithDisabled = useMemo(() => {
    if (tableData.length) {
      return has(rowStatusTypes.DISABLED, getCustomRowProps?.(head(tableData)))
    }

    return false
  }, [tableData, getCustomRowProps])

  const loadedRowsWithError = useMemo(() => {
    if (tableData.length) {
      return has(rowStatusTypes.ERROR, getCustomRowProps?.(head(tableData)))
    }
    return false
  }, [tableData, getCustomRowProps])
  const hasRowStateIcons = loadedRowsWithDisabled || loadedRowsWithError

  const priorityWidthColumnIdx = visibleColumns.findIndex(propEq("priorityWidth", true))

  const handleTableSettingsChange = newSettings => {
    const { sortBy: newSortBy, columns: newColumns, autoRefreshEnabled: newAutoRefreshEnabled } = newSettings

    shouldUseUserSettings &&
      displayedSettingsFields.sort &&
      window.localStorage.setItem(localStorageKeys.initialSortBy, JSON.stringify(newSortBy))
    setInitialSortBy(newSortBy)
    setSortBy(newSortBy)

    const newVisibleColumnsIds = pluck("id", newColumns)
    shouldUseUserSettings &&
      displayedSettingsFields.columns &&
      window.localStorage.setItem(localStorageKeys.visibleColumnsIds, JSON.stringify(newVisibleColumnsIds))
    setVisibleColumnsIds(newVisibleColumnsIds)

    if (canAutoRefresh) {
      shouldUseUserSettings &&
        window.localStorage.setItem(
          autoRefresh.localStorageKey || localStorageKeys.autoRefreshEnabled,
          JSON.stringify(newAutoRefreshEnabled),
        )
      setAutoRefreshEnabled(newAutoRefreshEnabled)
    }

    onSettingsChange?.(newSettings)
  }

  const { onLoadMore, totalRowCount, requestPageSize } = lazyLoadRows || {}
  const enableLazyLoadRows = onLoadMore && totalRowCount && requestPageSize

  const unknownTotalCount = fetchData && totalCount === undefined && !enableLazyLoadRows

  const {
    initialSelectedRowIds,
    handleToggleRow,
    handleTogglePageRows,
    handleToggleAllRows,
    getRowChecked,
    handleLazyLoadRowsAndNext,
    nextPageIndex,
    canLoadMoreRows,
    isInSelectAllMode,
    unselectedRowIds,
  } = useLazyRowLoading({
    tableData,
    pageSizeLimit,
    onLoadMore,
    totalRowCount,
    requestPageSize,
    getRowId,
    omitDisabledInSelection,
    getCustomRowProps,
  })

  const selectedRowIds = useMemo(
    () =>
      initialSelectedRowIds ||
      (showSelected ? showSelectedRowIds : false) ||
      reduce((acc, curr) => ({ ...acc, [curr]: true }), {}, _selectedRowIds),
    [initialSelectedRowIds, showSelected, showSelectedRowIds, _selectedRowIds],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageCount,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter, sortBy, selectedRowIds: stateSelectedRowIds },
    setGlobalFilter,
    selectedFlatRows: _selectedFlatRows,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    toggleRowSelected,
    gotoPage,
    setSortBy,
    toggleSortBy,
    visibleColumns: tableVisibleColumns,
    dispatch,
  } = useTable(
    {
      autoResetPage: showSelected ? false : autoResetPage,
      defaultColumn: {
        sortType: byColumnSort,
      },
      columns: visibleColumns,
      data: showSelected ? showSelectedTableData : tableData,
      autoResetSortBy: isShowSelectedEnabled ? false : autoResetSortBy,
      initialState: {
        pageSize: pageSizeLimit,
        sortBy: initialSortBy,
        ...(selectedRowIds && {
          selectedRowIds,
        }),
        ...(initialGlobalFilter && { globalFilter: initialGlobalFilter }),
        ...(nextPageIndex && { pageIndex: nextPageIndex }),
        ...(enableLazyLoadRows && { totalRowCount }),
      },
      manualSortBy,
      ...(manualPagination?.enable && {
        manualPagination: true,
        pageCount: manualPagination.pageCount,
        autoResetPage: false,
      }),
      ...(fetchData && {
        manualSortBy: true,
        manualPagination: true, // Tell the usePagination
        // hook that we'll handle our own data fetching
        // This means we'll also have to provide our own
        // pageCount.
        pageCount: remotePageCount,
        autoResetPage: false,
      }),
      ...(fetchData || enableManualGlobalFilter
        ? { manualGlobalFilter: true }
        : { ...(isShowSelectedEnabled && { manualGlobalFilter: showSelected }) }),
      ...((fetchData || enableLazyLoadRows || isShowSelectedEnabled) && {
        stateReducer: getStateReducer({ fetchData, enableLazyLoadRows }),
      }),
      autoResetGlobalFilter: false,
      autoResetSelectedRows: isShowSelectedEnabled ? false : initialSelectedRowIds ? true : autoResetSelectedRows,
      getRowId: isShowSelectedEnabled ? getRowId || defaultGetRowId : getRowId,
      ...(usingDragAndDrop && { autoResetPage: false }),
    },
    useSticky,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useActionsRenderer({
      actions,
      actionButtons,
      unknownTotalCount,
      hideCheckboxes,
      actionsRenderer,
      enableLazyLoadRows,
      handleTogglePageRows,
      handleToggleAllRows,
      pageSizeLimit,
      hideSelectAllDropdownOption,
      hideSelectPageDropdownOption,
      onSelectRows,
      getCustomRowProps,
      loadedRowsWithDisabled,
      showSelected,
      showSelectedToggleAllPageRowsSelected,
      showSelectedToggleAllRowsSelected,
      fetchData,
      totalCount,
      omitDisabledInSelection,
    }),
    useStatusIndicatorRenderer({ getStatusIndicatorColor, getStatusIndicatorBorderColor, theme, isLoading }),
    useDragAndDropRenderer({ onDrop, columnHeader: dndColumnHeader }),
    useRowStateRenderer({ isLoading, hasRowStateIcons }),
  )

  useEffect(() => {
    if (!isShowSelectedEnabled) return

    const selectedRowIdsAreOutOfSync = symmetricDifference(
      Object.keys(stateSelectedRowIds),
      Object.keys(showSelectedRowIds),
    ).length

    if (selectedRowIdsAreOutOfSync) {
      dispatch({ type: "manuallySetSelectedRowIds", selectedRowIds: showSelectedRowIds }) // use showSelectedRowIds as the source of truth
    }
  }, [dispatch, isShowSelectedEnabled, showSelectedRowIds, stateSelectedRowIds])

  const goBackToFirstPageIfCurrentPageIsEmpty = useCallback(() => {
    const shouldChangePages = page.length === 0 && pageIndex + 1 > pageCount && pageIndex !== 0

    if (shouldChangePages) {
      gotoPage(pageIndex - 1)
    }
  }, [gotoPage, page.length, pageCount, pageIndex])

  // show selected - if we uncheck any selected rows while show selected is enabled we need to go back to previous page
  useEffect(() => {
    if (!isShowSelectedEnabled) return

    if (showSelected) {
      goBackToFirstPageIfCurrentPageIsEmpty()
    }
  }, [goBackToFirstPageIfCurrentPageIsEmpty, isShowSelectedEnabled, showSelected])

  useEffect(() => {
    setLastSelectedRow(null)
  }, [sortBy])

  const selectedFlatRows = showSelected ? rows : _selectedFlatRows
  const selected = hasRowSelection && pluck("original", selectedFlatRows)
  const prevSelectedRows = usePrevious(selected)

  const handeRowSelected = useCallback(
    (rowId, checked, row) => {
      showSelectedToggleRowSelected(rowId, checked, row)
      toggleRowSelected(rowId, checked)
    },
    [showSelectedToggleRowSelected, toggleRowSelected],
  )

  const { handleKeyDown, tbodyRef, lastCellFocused } = useKeyboardActions({
    rows,
    tableId,
    selected,
    hasRowSelection,
    handleToggleRow,
    toggleRowSelected: handeRowSelected,
    getCustomRowProps,
    enableLazyLoadRows,
    hideCheckboxes,
  })

  const getRowRange = ({ rows, currentId, selectedId }) => {
    const rangeStart = selectedId > currentId ? currentId : selectedId
    const rangeEnd = rangeStart === currentId ? selectedId : currentId

    return rows.slice(rangeStart, rangeEnd + 1)
  }

  const handleOnClick = useCallback(
    ({ row, event }) => {
      setLastSelectedRow(row)

      if (event.shiftKey && hasRowSelection && lastSelectedRow) {
        const selectedId = rows.findIndex(_row => _row.id === lastSelectedRow.id)
        const currentId = rows.findIndex(_row => _row.id === row.id)

        const rowsToToggle = getRowRange({ rows, currentId, selectedId })
        const isCellSelected = rows[row.id]?.isSelected

        rowsToToggle.forEach(_row => {
          if (enableLazyLoadRows || fetchData) {
            handleToggleRow(_row.id, !isCellSelected)
          }

          handeRowSelected(_row.id, !isCellSelected)
        })
      }
    },
    [hasRowSelection, handleToggleRow, lastSelectedRow, enableLazyLoadRows, rows, handeRowSelected, fetchData],
  )

  const hasPendingLazyLoadData = showSelected ? false : rows.length < totalRowCount

  const rowsSelectedCount =
    isInSelectAllMode && hasPendingLazyLoadData
      ? totalRowCount - Object.keys(unselectedRowIds || {}).length
      : reject(row => {
          const props = getCustomRowProps?.(row)
          return props?.disabled
        })(selected || []).length

  const getTotalRowCount = () => {
    if (showSelected) return rowsSelectedCount
    if (enableLazyLoadRows) return totalRowCount
    if (fetchData) return totalCount
    if (manualPagination?.total) return manualPagination.total
    return rows.length
  }

  const getCurrentPageCounts = () => {
    if (manualPagination?.enable) {
      return manualPagination.currentPageCounts
    }
    const pageIndexSize = pageIndex * pageSize
    const start = pageIndexSize + 1
    const paginationEndBasedOnLength = () => pageIndexSize + page.length
    if (showSelected) {
      return { start, end: Math.min(paginationEndBasedOnLength(), rowsSelectedCount) }
    }
    if (!!fetchData) {
      return {
        start,
        end: canNextPage ? pageIndexSize + pageSize : getTotalRowCount() ?? paginationEndBasedOnLength(),
      }
    }
    return { start, end: paginationEndBasedOnLength() }
  }
  const currentPageCounts = getCurrentPageCounts()

  const resetData = useCallback(
    (keepCurrentPage = false, showLoading) => {
      if (pageIndex === 0 || keepCurrentPage) {
        updateRemoteData(isLoading || showLoading)
      } else {
        gotoPage(0)
      }
    },
    [pageIndex, isLoading, gotoPage, updateRemoteData],
  )

  // Needed to avoid infinite re-renders on InputWithDropdown component
  const stableFilterUpdate = useCallback(
    (name, newValue) => {
      gotoPage(0)
      updateFilter(name, newValue)
    },
    [updateFilter, gotoPage],
  )

  const loadDataTableSettings = () => {
    setInitialSortBy(getSortByValue())
    setVisibleColumnsIds(getVisibleColumnIdValue())
  }

  useEffect(() => {
    loadDataTableSettings()
    // TECH DEBT:
    // - We intentionally disabled ESLint here since @ninjaone/components is now integrated into our build pipeline & linting warnings/errors will trigger a failure.
    // - We decided not to fix the warning (missing dependency: 'loadDataTableSettings') for now as that could lead to unexpected bugs.
    // - 'tableId', 'shouldUseUserSettings', 'hideSettingsButton' are required for https://ninjarmm.atlassian.net/browse/NJ-48022
    // - If adding any new code here do it with caution & make sure to add missing dependencies for your code only.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableId, shouldUseUserSettings, hideSettingsButton])

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    if (enableLazyLoadRows) return

    setPaginationProps({
      pageIndex,
      pageSize,
      globalFilter,
      sortBy,
    })
  }, [globalFilter, pageIndex, pageSize, setPaginationProps, sortBy, enableLazyLoadRows])

  //TODO check if this is working after merge
  useEffect(() => {
    if (isShowSelectedEnabled) return

    if (onSelectRows && selected && prevSelectedRows && selected.length !== prevSelectedRows.length) {
      // TODO: revisit this once we upgrade to React 18
      // useEffect is not guaranteed to run after painting updates
      // onSelectRows will now run after the paint making the clicking of checkboxes feel much faster
      // unselectedRowIds is only used with lazyLoadRows
      runAfterDataTableRenders(() => {
        onSelectRows(selected, keys(unselectedRowIds))
      })
    }
  }, [selected, prevSelectedRows, onSelectRows, unselectedRowIds, isShowSelectedEnabled])

  useEffect(() => {
    if (scrollToTopIfDataChanges) {
      // since the ref to the scrollable <Box> lives inside an <Autosizer> we have to scroll until after the render
      runAfterDataTableRenders(scrollToTopOfTable)
    }
  }, [tableData, scrollToTopIfDataChanges])

  useEffect(() => {
    if (enableLazyLoadRows) {
      dispatch({ type: "totalRowCountUpdate", totalRowCount })
    }
  }, [dispatch, enableLazyLoadRows, totalRowCount])

  useEffect(() => {
    if (fetchData && resetPageOnFetchChange) {
      gotoPage(0)
    }
  }, [gotoPage, fetchData, resetPageOnFetchChange])

  //Initializes the lastUpdated for when the data comes from the props instead of being paginated
  useEffect(() => rowsData && setLastUpdated(new Date()), [rowsData, setLastUpdated])

  useAutoRefreshPubSub({ pubsubChannel, autoRefreshEnabled, resetData })
  useAutoRefreshPolling({
    pollingTime,
    resetData,
    pollingEnabled: !!pollingTime && !pubsubChannel && autoRefreshEnabled,
  })

  function renderGlobalActionsButton() {
    const {
      buttonProps: {
        Icon = PlusIcon,
        variant = "primary",
        labelToken = localizationKey("Add"),
        action: buttonAction,
        disabled = false,
        tooltipToken,
      } = {},
      actions: buttonActions = [],
    } = globalActionsButton

    if (!buttonAction && !buttonActions.length) return null

    if (!!buttonAction) {
      return (
        <StyledGlobalActionsButton
          Icon={Icon}
          variant={variant}
          onClick={buttonAction}
          labelToken={labelToken}
          disabled={disabled}
          {...(disabled && tooltipToken && { tooltip: localized(tooltipToken) })}
        />
      )
    }

    return (
      <Dropdown alignRight variant="compact" options={buttonActions}>
        <StyledGlobalActionsButton renderAsDiv Icon={Icon} variant={variant} labelToken={labelToken} />
      </Dropdown>
    )
  }

  function renderExportButton() {
    const exportParams = {
      filters: customFilters,
      globalFilter,
      visibleColumnsIds,
      sortBy,
    }

    const { action, options } = getExportButtonProps?.(exportParams) ?? {}

    if (action) {
      return (
        <IconButton tooltipAlignRight size="sm" handleClick={action} tooltip={localized("Export")}>
          <DownloadIconLight size="sm" color="colorTextStrong" label={localized("Export")} />
        </IconButton>
      )
    }

    if (options) {
      return (
        <Dropdown alignRight childIsButton variant="compact" options={options}>
          <IconButton tooltipAlignRight size="sm" tooltip={localized("Export")}>
            <DownloadIconLight size="sm" color="colorTextStrong" label={localized("Export")} />
          </IconButton>
        </Dropdown>
      )
    }

    return null
  }

  function renderSecondaryGlobalActions() {
    if (!secondaryGlobalActions.length) return null

    return (
      <Dropdown alignRight options={secondaryGlobalActions} variant="compact">
        <Button tooltip={localized("More actions")} renderAsDiv variant="secondary" Icon={EllipsisHIcon} />
      </Dropdown>
    )
  }

  function renderSecondaryGlobalActionsButtons() {
    return secondaryGlobalActionsButtons.map(
      ({ action, disabled = false, Icon, labelToken, tooltipToken, variant = "secondary" }) => (
        <StyledGlobalActionsButton
          Icon={Icon}
          key={labelToken}
          variant={variant}
          labelToken={labelToken}
          disabled={disabled}
          onClick={() => action({ resetData })}
          {...(disabled && {
            tooltip: tooltipToken,
          })}
        />
      ),
    )
  }

  const isShowSelectedLastPage = showSelected && currentPageCounts.end === rowsSelectedCount

  const enableNextBtn =
    (enableLazyLoadRows &&
      canLoadMoreRows({ pageIndex, rowsLength: rows.length, pageSize }) &&
      !isShowSelectedLastPage) ||
    canNextPage
  const hasMultiplePages = getHasMultiplePages({
    enableLazyLoadRows,
    totalRowCount,
    pageSizeLimit,
    pageCount,
    showSelected,
    rowsSelectedCount,
  })

  const scrollToTopOfTable = () => {
    scrollableBoxRef.current.scrollTop = 0
  }

  const handleNextPage = () => {
    if (enableLazyLoadRows) {
      handleLazyLoadRowsAndNext({ nextPage, pageIndex, rowsLength: rows.length, pageSize })
    } else {
      if (!globalFilter) {
        pageIndexRef.current = pageIndex + 1
      }
      manualPagination?.onNextPage?.()
      nextPage()
    }

    scrollToTopOfTable()
  }

  const handlePreviousPage = () => {
    if (!globalFilter) {
      pageIndexRef.current = pageIndex - 1
    }
    manualPagination?.onPreviousPage?.()
    previousPage()
    scrollToTopOfTable()
  }

  const renderPrimaryActionButtons = () => {
    return actions.primary
      .filter(({ hideMultiAction }) => !hideMultiAction?.(selected))
      .map(action => {
        return action.options?.length ? renderDropdownButton(action) : renderSimpleActionButton(action)
      })
  }

  const renderDropdownButton = ({
    labelToken,
    variant = "primary",
    Icon,
    options,
    disabled,
    tooltip,
    useDropdownIcon,
  }) => {
    const customOptions = options.map(option => {
      return {
        ...option,
        action: () => option.action(selected, resetData),
      }
    })
    return (
      <Dropdown key={labelToken} variant="compact" options={customOptions}>
        <Button
          compact
          renderAsDiv
          iconPlacement={Icon && useDropdownIcon ? "left" : "right"}
          {...{ disabled, labelToken, Icon, tooltip, useDropdownIcon, variant }}
        />
      </Dropdown>
    )
  }

  const renderSimpleActionButton = ({ labelToken, action, variant = "primary", Icon, disabled, tooltip }) => {
    const disabledRows =
      getCustomRowProps &&
      omitDisabledInSelection &&
      rows.filter(({ original }) => getCustomRowProps(original)?.disabled)
    return (
      <Button
        compact
        onClick={() =>
          action({
            selected,
            stateSelectedRowIds,
            resetData,
            unselectedRowIds,
            isInSelectAllMode,
            totalCount,
            disabledRows,
          })
        }
        key={labelToken}
        {...{ disabled, labelToken, Icon, tooltip, variant }}
      />
    )
  }

  function renderSkeletonRow(key) {
    return (
      <tr key={key} data-testid={`data-table-skeleton-row-${key}`}>
        {tableVisibleColumns.map(({ id, minWidth, loadingWidth }, index) => {
          const isStatusIndicatorColumn = id === "status-indicator"
          const isActionsColumn = id === "actions"

          const statusIndicatorWidth = index === 0 && hasStatusIndicators ? 6 : 0
          const previousRenderColumnWidth = tableColumnWidths[id] + statusIndicatorWidth
          const skeletonMinWidth = previousRenderColumnWidth || loadingWidth || minWidth
          return (
            <td
              key={id}
              className={isStatusIndicatorColumn ? "status-indicator-column" : ""}
              style={{
                ...(skeletonMinWidth && { minWidth: skeletonMinWidth }),
              }}
            >
              <div className={isStatusIndicatorColumn || isActionsColumn ? "" : "data-table-cell"}></div>
            </td>
          )
        })}
      </tr>
    )
  }

  const renderEmptyState = () => {
    const errorText = typeof error === "string" && error

    return (
      <tr className="no-hover">
        <td colSpan={tableVisibleColumns.length}>
          <StyledEmptyTable>
            {isError ? (
              <>
                <ExclamationCircle color="colorAlertError" />
                <Text
                  size="sm"
                  fontWeight={400}
                  color="colorTextWeakest"
                  token={errorText || localizationKey("Unable to fetch data. Try again by refreshing your page.")}
                />
              </>
            ) : (
              <>
                <MagnifyingGlass fontSize={tokens.typography.fontSize["headingM"]} color="colorTextWeakest" />
                <Text
                  size="sm"
                  fontWeight={400}
                  color="colorTextWeakest"
                  token={noRowsToken || localizationKey("No data found")}
                />
              </>
            )}
          </StyledEmptyTable>
        </td>
      </tr>
    )
  }

  if (enableLazyLoadRows && isLoading) {
    return (
      <StyledContainer className="data-table skeleton-rows-only is-loading">
        <div className="pagination-skeleton data-table-cell"></div>
        <StyledDataTable>
          <table>
            <tbody>
              {skeletonRows.map((row, index) => (
                <tr className="no-hover" data-testid={`data-table-skeleton-row-${index}`} key={index}>
                  <td>
                    <div className="data-table-cell"></div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </StyledDataTable>
      </StyledContainer>
    )
  }

  const hasStatusIndicators = !!getStatusIndicatorColor
  const hideLastUpdatedLabel = (!autoRefreshEnabled && !onRefresh) || autoRefresh.hideLabel
  const showLastUpdatedLabelAfter5Minutes = onRefresh && !autoRefreshEnabled

  const results = showSelected ? rowsSelectedCount : page.length

  const setupGlobalFilter = (filter = "") => {
    const newGlobalFilter = filter.trim()
    setGlobalFilter(newGlobalFilter)
    onGlobalFilterChange?.(newGlobalFilter)
    if (newGlobalFilter || (usingDragAndDrop && page !== 0)) return gotoPage(0)
    if (!autoResetPage) {
      gotoPage(pageIndexRef.current)
    }
  }

  const displayShowSelectedButton = isShowSelectedEnabled && !(isInSelectAllMode && hasPendingLazyLoadData)
  const showSelectedButton =
    showSelectedTableData && !!Object.keys(showSelectedRowIds).length ? (
      <ShowSelectedButton
        {...{ showSelected, toggleShowSelected, showSelectedCount: showSelectedTableData.length, gotoPage }}
      />
    ) : null

  const selectedLabel = !!rowsSelectedCount ? (
    <Text
      size="sm"
      color="colorTextWeak"
      token={localizationKey("{{count}} Selected")}
      tokenArgs={{ count: rowsSelectedCount }}
    />
  ) : null

  return (
    <StyledContainer
      className={`data-table ${
        isLoading && !isReactive ? "is-loading" : isLoading && isReactive ? "is-reactive-loading" : ""
      }`}
    >
      <StyledAccessoryHeader>
        <StyledFlexColumn>
          {titleGroup && <TitleGroup {...titleGroup} />}
          <StyledFlexRowWrap data-testid="data-table-filters-container">
            {showSearchBar && (
              <SearchComponent
                clearButtonId={globalFilterClearButtonId}
                globalFilter={globalFilter}
                globalFilterCharLimit={globalFilterCharLimit}
                gotoPage={gotoPage}
                isManualPaginationEnabled={manualPagination?.enable}
                searchPlaceholderText={searchPlaceholderText || localized("Search")}
                setGlobalFilter={setupGlobalFilter}
                shouldTrimGlobalFilter={shouldTrimGlobalFilter}
                disabled={showSelected}
                searchBarFullWidth={searchBarFullWidth}
                searchBarAutoFocus={searchBarAutoFocus}
              />
            )}
            {!hideFilters && (filters.primary || filters.secondary) && (
              <CustomFilters
                {...{
                  customFilters,
                  updateFilter: stableFilterUpdate,
                  clearFilters: () => {
                    gotoPage(0)
                    clearFilters()
                  },
                  clearFiltersOnReset,
                  disabled: showSelected,
                }}
              />
            )}
          </StyledFlexRowWrap>
          <StyledFlexRow gap={sizer(4)} flex={1}>
            <>
              <Box
                ref={paginationContainerRef}
                {...(showSelected &&
                  paginationContainerWidth && {
                    minWidth: paginationContainerWidth,
                  })}
              >
                {hasMultiplePages ? (
                  <StyledFlexRow className="data-table-pagination">
                    {!hidePaginationButtons && (
                      <>
                        <IconButton
                          size="sm"
                          handleClick={handlePreviousPage}
                          disabled={!canPreviousPage}
                          tooltip={localized("dataTable.previousPage")}
                        >
                          <OpenArrowIcon
                            direction="left"
                            color={canPreviousPage ? "colorTextStrong" : "colorTextDisabled"}
                          />
                        </IconButton>
                        <IconButton
                          size="sm"
                          handleClick={handleNextPage}
                          disabled={!enableNextBtn}
                          tooltip={localized("Next page")}
                        >
                          <OpenArrowIcon
                            direction="right"
                            color={enableNextBtn ? "colorTextStrong" : "colorTextDisabled"}
                          />
                        </IconButton>
                      </>
                    )}
                    {unknownTotalCount ? (
                      <Text
                        size="sm"
                        color="colorTextWeak"
                        token={localizationKey("{{start}} - {{end}}")}
                        tokenArgs={{ start: currentPageCounts.start, end: currentPageCounts.end }}
                      />
                    ) : (
                      <Text
                        size="sm"
                        color="colorTextWeak"
                        token={multiplePagesPaginationToken}
                        tokenArgs={{
                          start: currentPageCounts.start,
                          end: currentPageCounts.end,
                          total: getTotalRowCount(),
                        }}
                        data-testid="data-table-results-count"
                      />
                    )}
                  </StyledFlexRow>
                ) : (
                  !isLoading &&
                  !hideResultsCount && (
                    <Flex alignItems="center" height="32px" className="data-table-pagination">
                      <Text
                        size="sm"
                        color="colorTextWeak"
                        token={
                          resultsCountLabel?.token ||
                          (results > 1 || results === 0 ? pluralResultsLabelToken : localizationKey("1 result"))
                        }
                        tokenArgs={{
                          results,
                          ...resultsCountLabel,
                        }}
                        italic={resultsCountLabel?.italic}
                        data-testid="data-table-results-count"
                      />
                    </Flex>
                  )
                )}
              </Box>
              {displayShowSelectedButton ? showSelectedButton : selectedLabel}
              {selected?.length > 0 && actionsRenderer?.({ isInSelectAllMode, hasPendingLazyLoadData })}
              {hasActions && (
                <StyledFlexRow className="data-table-select-actions">
                  <StyledActionsContainer
                    showActions={
                      selected?.length > 0 || (usesStateSelectedRows && fetchData && isNotEmpty(stateSelectedRowIds))
                    }
                  >
                    {renderPrimaryActionButtons()}
                    {showSecondaryMultiActions(actions.secondary, selected) && (
                      <StyledMoreActionsDropdownContainer>
                        <Dropdown
                          options={actions.secondary
                            .filter(({ hideMultiAction }) => !hideMultiAction?.(selected))
                            .map(compose(assoc("resetData", resetData), assoc("selected", selected)))}
                          variant="compact"
                        >
                          <IconButton size="sm" renderAsDiv variant="blue" tooltip={localized("More actions")}>
                            <EllipsisHIcon />
                          </IconButton>
                        </Dropdown>
                      </StyledMoreActionsDropdownContainer>
                    )}
                  </StyledActionsContainer>
                </StyledFlexRow>
              )}
            </>
          </StyledFlexRow>
        </StyledFlexColumn>
        <StyledFlexColumn>
          {(externalLinkUrl ||
            secondaryGlobalActionsButtons?.length > 0 ||
            globalActionsButton?.buttonProps?.action ||
            globalActionsButton?.actions?.length > 0) && (
            <Flex gap={sizer(2)} justifyContent="flex-end" alignItems="center">
              {externalLinkUrl && (
                <ExternalLink url={externalLinkUrl} styles={{ fontSize: "14px", textDecoration: "none" }}>
                  {localized(externalLinkLabelToken)} <ExternalLinkIconLight size="sm" />
                </ExternalLink>
              )}
              {renderSecondaryGlobalActions()}
              {renderSecondaryGlobalActionsButtons()}
              {renderGlobalActionsButton()}
            </Flex>
          )}
          <StyledFlexRow className="data-table-settings">
            {lastUpdated && !hideLastUpdatedLabel && (
              <LastUpdatedLabel lastUpdated={lastUpdated} onlyShowAfter5Minutes={showLastUpdatedLabelAfter5Minutes} />
            )}
            {onRefresh && (
              <IconButton
                size="sm"
                handleClick={() => {
                  !fetchData && storeTableColumnsWidths()
                  onRefresh({ resetData, setIsLoading })
                }}
                tooltip={
                  autoRefreshEnabled
                    ? localized("This table has Auto-refresh enabled and will update in the background")
                    : localized("Refresh")
                }
                tooltipAlignRight
              >
                <RefreshLightIcon size="sm" color="colorTextStrong" />
              </IconButton>
            )}
            {renderExportButton()}
            {!hideSettingsButton && (
              <IconButton
                size="sm"
                tooltip={localized("Table settings")}
                tooltipAlignRight
                handleClick={() => {
                  showModal(
                    <DataTableSettingsModal
                      canAutoRefresh={canAutoRefresh}
                      canResetDefaults={shouldUseUserSettings}
                      autoRefreshEnabledTooltipToken={autoRefreshEnabledTooltipToken}
                      initialAutoRefreshEnabled={autoRefreshEnabled}
                      defaultAutoRefreshEnabled={defaultAutoRefreshEnabled}
                      initialSortBy={initialSortBy}
                      defaultSortBy={_initialSortBy}
                      initialColumns={columns}
                      defaultVisibleColumns={defaultVisibleColumns}
                      visibleColumns={visibleColumns}
                      additionalColumnSettings={additionalColumnSettings}
                      onSubmit={handleTableSettingsChange}
                      saveButtonToken={settingsSaveButtonToken}
                      maxColumnCount={maxColumnCount}
                      displayedSettingsFields={displayedSettingsFields}
                      onMount={onSettingsModalMount}
                      onUnmount={onSettingsModalUnmount}
                    />,
                  )
                }}
              >
                <SettingsIcon size="sm" color="colorTextStrong" />
              </IconButton>
            )}
          </StyledFlexRow>
        </StyledFlexColumn>
      </StyledAccessoryHeader>

      <StyledDataTable {...{ priorityWidthColumnIdx, hasActions, hasCheckboxes, hasStatusIndicators }}>
        <AutoSizer {...(enableAutoHeight && { disableHeight: true })}>
          {({ width, height }) => (
            <ScrollableBox {...{ width, height }} ref={scrollableBoxRef}>
              <table {...getTableProps()} {...(hideHeaders && { className: "hidden-headers" })}>
                <thead ref={tableHeaderRef}>
                  {headerGroups.map(headerGroup => (
                    <tr className="data-table-row" {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => {
                        const getType = column?.getCellTypeProps

                        const cellSizeProps = column?.getCellSizeProps?.({ tableContainerWidth: width }) || {}
                        const maxWidth = cellSizeProps.maxWidth ?? column?.maxWidth
                        const minWidth = cellSizeProps.minWidth ?? column?.minWidth

                        const { type } = !getType?.length ? getType?.() || {} : {}

                        const isRightAligned =
                          type === cellTypes.DATE || type === cellTypes.CURRENCY || type === cellTypes.NUMBER

                        const handleOnChangeSortBy = () => {
                          const { id, isSortedDesc } = column
                          const desc = getIsSortedDescFromPreviousValue(isSortedDesc)
                          const isThirdConsecutiveClick =
                            sortByRef.current?.id === id && sortByRef.current?.desc && !desc

                          toggleSortBy(id)

                          if (isThirdConsecutiveClick) {
                            onSort?.([])
                          } else {
                            onSort?.([{ id, desc }])
                          }

                          sortByRef.current = { id, desc }
                        }

                        const headerId = `${TABLE_HEADER_ID_PREFIX}${column.id}`
                        return column.id === "actions" ? (
                          <th id={headerId} key="actions">
                            <VisuallyHidden>{localized("Actions")}</VisuallyHidden>
                          </th>
                        ) : column.id === "status-indicator" ? (
                          <th id={headerId} tabIndex={0} key="status-indicator" className="status-indicator-column" />
                        ) : (
                          <th
                            id={headerId}
                            tabIndex={0}
                            {...column.getHeaderProps(
                              column.canSort
                                ? column.getSortByToggleProps({
                                    title: "",
                                    ...(onSort && {
                                      onClick: handleOnChangeSortBy,
                                    }),
                                    onKeyDown: e => {
                                      if (e.key === "Enter") {
                                        handleOnChangeSortBy()
                                      }
                                    },
                                  })
                                : {
                                    ...(column.id === "selection" && {
                                      className: "data-table-header-cell-selection",
                                      onKeyDown: e => {
                                        if (e.key === "Enter") {
                                          const selectDropdownEl = e.target.querySelector(
                                            "[data-ninja-dropdown-trigger]",
                                          )

                                          if (selectDropdownEl) {
                                            // The requestAnimationFram is needed to open the Radix dropdown.
                                            window.requestAnimationFrame(() =>
                                              selectDropdownEl.dispatchEvent(
                                                new MouseEvent("pointerdown", { bubbles: true }),
                                              ),
                                            )
                                          } else {
                                            const sortingCheckboxButtonEl = e.target.querySelector("button")
                                            sortingCheckboxButtonEl.click()
                                          }
                                        }
                                      },
                                    }),
                                  },
                            )}
                          >
                            <StyledTableHeader {...{ isRightAligned, id: column.id, maxWidth, minWidth }}>
                              <Flex gridColumnGap={sizer(1)}>
                                {column.render("Header")}
                                {column.tooltipToken && (
                                  <Tooltip label={localized(column.tooltipToken)}>
                                    <RegularInfoCircleIcon size="sm" color="colorTextWeak" />
                                  </Tooltip>
                                )}
                              </Flex>
                              {column.canSort && (
                                <StyledSort visible={column.isSorted}>
                                  {column.isSortedDesc ? (
                                    <SortDownIcon size="sm" label={localized("Sort Ascending")} />
                                  ) : (
                                    <SortUpIcon size="sm" label={localized("Sort Descending")} />
                                  )}
                                </StyledSort>
                              )}
                            </StyledTableHeader>
                          </th>
                        )
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} ref={tbodyRef}>
                  {isLoading && !isReactive
                    ? skeletonRows.map((row, index) => renderSkeletonRow(index))
                    : page.length && !isError
                    ? page.map(row => {
                        prepareRow(row)
                        const { checked, title } = row.getToggleRowSelectedProps() ?? {}
                        const id = `${tableId}-${row.id}`
                        const rowChecked = enableLazyLoadRows ? getRowChecked({ checked, id: row.id }) : checked
                        return (
                          <Row
                            {...{
                              fetchData,
                              row,
                              id,
                              lastCellFocused,
                              enableLazyLoadRows,
                              handleToggleRow,
                              checked: !!rowChecked,
                              title,
                              key: id,
                              actions,
                              handleKeyDown,
                              handleOnClick,
                              actionButtons,
                              multipleRowsSelected: hasActions ? selected.length > 1 : false,
                              selectMode: selected?.length > 0,
                              hideCheckboxes,
                              rowLabelColumn,
                              className: `data-table-row${hasStatusIndicators ? " status-indicator-row" : ""}`,
                              resetData,
                              dragAndDrop,
                              scrollableArea: scrollableBoxRef.current?.getBoundingClientRect(),
                              getCustomRowProps,
                              showSelectedToggleRowSelected,
                              tableContainerWidth: width,
                            }}
                          />
                        )
                      })
                    : renderEmptyState(height)}
                </tbody>
              </table>
            </ScrollableBox>
          )}
        </AutoSizer>
      </StyledDataTable>
    </StyledContainer>
  )
}

DataTable.propTypes = {
  resultsCountLabel: PropTypes.shape({
    token: PropTypes.string,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      id: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      disableGlobalFilter: PropTypes.bool,
      disableSortBy: PropTypes.bool,
      sortType: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      maxWidth: PropTypes.string,
      minWidth: PropTypes.string,
      loadingWidth: PropTypes.string,
      textWrap: PropTypes.bool,
      textWrapLineLimit: PropTypes.number,
      priorityWidth: PropTypes.bool,
      getCellTypeProps: PropTypes.func,
      getCellSizeProps: PropTypes.func,
      getCellInlineTags: PropTypes.func,
      getIconName: PropTypes.func,
      getIconProps: PropTypes.func,
      getDescription: PropTypes.func,
      getImg: PropTypes.func, // returns an object with src(required) and optional size
      bold: PropTypes.bool,
      tooltipToken: PropTypes.string,
      isCopyable: PropTypes.bool,
      getCopyableDropdownProps: PropTypes.func,
      isRemovable: PropTypes.bool,
      secondaryIconProps: PropTypes.shape({
        getIconName: PropTypes.func.isRequired,
        getIconTooltipLabel: PropTypes.func.isRequired,
        getIconColor: PropTypes.func,
      }),
      tableSettingsTag: PropTypes.string,
      tableSettingsColumnsGroup: PropTypes.string,
      tooltipAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      hideFromTableSettingsAvailableColumns: PropTypes.bool,
      generateColumns: PropTypes.func,
      tableSettingsHeader: PropTypes.string,
    }),
  ).isRequired,
  visibleColumnsIds: PropTypes.arrayOf(PropTypes.string),
  shouldUseUserSettings: PropTypes.bool,
  onSettingsChange: PropTypes.func,
  /**
   * The array of rows that will be rendered, you must provide it if `fetchData` is undefined
   */
  rows: isRequiredIf(
    PropTypes.array,
    props => !props.hasOwnProperty("fetchData"),
    "`rows` is required if not passing a value for `fetchData`",
  ),
  onRefresh: PropTypes.func,
  getExportButtonProps: PropTypes.func,
  loading: PropTypes.bool,
  hideSettingsButton: PropTypes.bool,
  settingsSaveButtonToken: PropTypes.string,
  searchPlaceholderText: PropTypes.string,
  globalFilterClearButtonId: PropTypes.string,
  onGlobalFilterChange: PropTypes.func,
  globalFilterCharLimit: PropTypes.number,
  enableManualGlobalFilter: PropTypes.bool,
  shouldTrimGlobalFilter: PropTypes.bool,
  initialGlobalFilter: PropTypes.string,
  noRowsToken: PropTypes.string,
  pageSizeLimit: PropTypes.number,
  tableId: PropTypes.string.isRequired,
  rowLabelColumn: PropTypes.string,
  titleGroup: PropTypes.object,
  hideCheckboxes: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  searchBarFullWidth: PropTypes.bool,
  searchBarAutoFocus: PropTypes.bool,
  externalLinkUrl: PropTypes.string,
  externalLinkLabelToken: PropTypes.string,
  actions: PropTypes.shape({
    primary: PropTypes.arrayOf(
      PropTypes.shape({
        labelToken: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
        variant: PropTypes.string,
        Icon: PropTypes.elementType,
        hideRowAction: PropTypes.func,
        hideMultiAction: PropTypes.func,
        isRed: PropTypes.bool,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            labelToken: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
            show: PropTypes.func,
          }),
        ),
        disabled: PropTypes.bool,
        tooltip: PropTypes.string,
      }),
    ),
    secondary: PropTypes.arrayOf(
      PropTypes.shape({
        labelToken: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
        variant: PropTypes.string,
        Icon: PropTypes.elementType,
        hideRowAction: PropTypes.func,
        hideMultiAction: PropTypes.func,
      }),
    ),
    row: PropTypes.shape({
      action: PropTypes.func.isRequired,
      disable: PropTypes.func,
    }),
  }),
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      labelToken: isRequiredIf(
        PropTypes.string,
        props => !props.hasOwnProperty("getLabel"),
        "`labelToken` is required if not passing a value for `getLabel",
      ),
      getLabel: isRequiredIf(
        PropTypes.func,
        props => !props.hasOwnProperty("labelToken"),
        "`getLabel` is required if not passing a value for `labelToken",
      ),
      action: isRequiredIf(
        PropTypes.func,
        props => !props.hasOwnProperty("options"),
        "`action` is required if not passing a value for `options",
      ),
      options: isRequiredIf(
        PropTypes.arrayOf(
          PropTypes.shape({
            labelToken: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
            show: PropTypes.func,
          }),
        ),
        props => !props.hasOwnProperty("action"),
        "`options` is required if not passing a value for `action",
      ),
      variant: PropTypes.string,
      Icon: PropTypes.elementType,
      showTooltipWhenDisabled: PropTypes.bool,
      getDisabled: PropTypes.func,
    }),
  ),
  filters: PropTypes.shape({
    primary: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        labelToken: PropTypes.string.isRequired,
        initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        componentProps: PropTypes.object,
        filter: PropTypes.func,
        onFilterChange: PropTypes.func,
        shouldResetValue: PropTypes.bool,
      }),
    ),
    secondary: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        labelToken: PropTypes.string.isRequired,
        initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        componentProps: PropTypes.object,
        filter: PropTypes.func,
        onFilterChange: PropTypes.func,
        shouldResetValue: PropTypes.bool,
      }),
    ),
  }),
  onClearFilters: PropTypes.func,
  /**
   * Shows reset button for filters, even when initial values are passed to the component props
   * Use this prop when you want to clear filters instead of resetting to initial values
   * Use onClearFilters to set cleared state for filters
   */
  clearFiltersOnReset: PropTypes.bool,
  /**
   * The function for custom fetching data, if you provide this it enables manual pagination, it has to return an object with: <br/>
   * `data`: *required*, array of data to be rendered <br/>
   * `pageCount`: *optional*, number of total pages <br/>
   * `totalCount`: *optional*, number of total rows <br/>
   */
  fetchData: isRequiredIf(
    PropTypes.func,
    props => !props.hasOwnProperty("rows"),
    "`fetchData` is required if not passing a value for `rows`",
  ),
  hidePaginationButtons: PropTypes.bool,
  /**
   * Hides the results count at the top of the table when there's no pagination
   */
  hideResultsCount: PropTypes.bool,
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool,
    }),
  ),
  globalActionsButton: PropTypes.shape({
    buttonProps: PropTypes.shape({
      Icon: PropTypes.elementType,
      variant: PropTypes.string,
      labelToken: PropTypes.string,
      action: PropTypes.func,
      disabled: PropTypes.bool,
      tooltipToken: PropTypes.string,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        titleToken: PropTypes.string,
        labelToken: isRequiredIf(
          PropTypes.string,
          props => !props.hasOwnProperty("titleToken"),
          "`labelToken` is required if not passing a value for `titleToken`",
        ),
        action: PropTypes.func,
        splitAfter: PropTypes.bool,
      }),
    ),
  }),
  secondaryGlobalActions: PropTypes.arrayOf(
    PropTypes.shape({
      titleToken: PropTypes.string,
      labelToken: isRequiredIf(
        PropTypes.string,
        props => !props.hasOwnProperty("titleToken"),
        "`labelToken` is required if not passing a value for `titleToken`",
      ),
      action: PropTypes.func,
      splitBefore: PropTypes.bool,
      splitAfter: PropTypes.bool,
    }),
  ),
  secondaryGlobalActionsButtons: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      Icon: PropTypes.elementType,
      labelToken: PropTypes.string,
      variant: PropTypes.string,
      disabled: PropTypes.bool,
      tooltipToken: PropTypes.string,
    }),
  ),
  additionalColumnSettings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      initialValue: PropTypes.any.isRequired,
      labelToken: PropTypes.string,
      tooltipToken: PropTypes.string,
    }),
  ),
  actionsRenderer: PropTypes.func,
  onSelectRows: PropTypes.func,
  getStatusIndicatorColor: PropTypes.func,
  getStatusIndicatorBorderColor: PropTypes.func,
  lazyLoadRows: PropTypes.shape({
    onLoadMore: PropTypes.func.isRequired,
    totalRowCount: PropTypes.number.isRequired,
    requestPageSize: PropTypes.number.isRequired,
  }),
  autoResetSelectedRows: PropTypes.bool,
  autoResetSortBy: PropTypes.bool,
  getRowId: isRequiredIf(
    PropTypes.func,
    props =>
      (props.hasOwnProperty("actions") ||
        props.hasOwnProperty("actionsRenderer") ||
        props.hasOwnProperty("onSelectRows")) &&
      props.rows?.length &&
      !props.rows?.[0]?.hasOwnProperty("id") &&
      !props.hasOwnProperty("dataKey") &&
      !props.hideCheckboxes &&
      props.isShowSelectedEnabled,
    "`getRowId` is required if passing a value for `actions` or `actionsRenderer` or `onSelectRows` and `dataKey` doesn't have a value and `rows` don't have an `id` attribute",
  ),
  isShowSelectedEnabled: (props, propName, componentName) => {
    const value = props[propName]
    if (!value) {
      return null
    }

    if (props.fetchData) {
      return new Error(`Can not pass 'isShowSelectedEnabled' and 'fetchData' props together to '${componentName}'`)
    }

    if (props.autoResetSelectedRows) {
      return new Error(
        `Can not pass 'isShowSelectedEnabled' and 'autoResetSelectedRows' props together to '${componentName}'`,
      )
    }

    if (props.autoResetSortBy) {
      return new Error(
        `Can not pass 'isShowSelectedEnabled' and 'autoResetSortBy' props together to '${componentName}'`,
      )
    }

    if (props.enableManualGlobalFilter) {
      return new Error(
        `Can not pass 'isShowSelectedEnabled' and 'enableManualGlobalFilter' props together to '${componentName}'`,
      )
    }

    if (typeof value !== "boolean") {
      return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected a bool.`)
    }
    return null
  },
  dataKey: PropTypes.string,
  onSort: PropTypes.func,
  manualSortBy: PropTypes.bool,
  manualPagination: PropTypes.shape({
    enable: PropTypes.bool.isRequired,
    total: PropTypes.number,
    pageCount: PropTypes.number,
    onNextPage: PropTypes.func,
    onPreviousPage: PropTypes.func,
    currentPageCounts: PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
    }),
  }),
  scrollToTopIfDataChanges: PropTypes.bool,
  maxColumnCount: PropTypes.number,
  hideSelectAllDropdownOption: PropTypes.bool,
  hideSelectPageDropdownOption: PropTypes.bool,
  isReactive: PropTypes.bool,
  selectedRowIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  autoRefresh: PropTypes.shape({
    pubsubChannel: PropTypes.string,
    autoRefreshEnabledTooltipLabelToken: PropTypes.string,
    localStorageKey: PropTypes.string,
    isOnByDefault: PropTypes.bool,
    pollingTime: PropTypes.number,
    hideLabel: PropTypes.bool,
  }),
  displayedSettingsFields: PropTypes.shape({
    sort: PropTypes.bool,
    columns: PropTypes.bool,
  }),
  dragAndDrop: PropTypes.shape({
    onDrop: PropTypes.func,
    accessorKey: PropTypes.string,
    columnHeader: PropTypes.string,
  }),
  hideFilters: PropTypes.bool,
  getCustomRowProps: isRequiredIf(
    PropTypes.func,
    props => props.hasOwnProperty("omitDisabledInSelection"),
    "`getCustomRowProps` is required if not passing a value for `omitDisabledInSelection`",
  ),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  SearchComponent: PropTypes.func,
  onSettingsModalMount: PropTypes.func,
  onSettingsModalUnmount: PropTypes.func,
  omitDisabledInSelection: PropTypes.bool,
  enableAutoHeight: PropTypes.bool,
  hideHeaders: PropTypes.bool,
  usesStateSelectedRows: PropTypes.bool,
}
