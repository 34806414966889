import { _localized } from "./localization"

// localizationKey in comments is used for automation to create translations
// prettier-ignore
const activityStatusMapper = {
  ACKNOWLEDGED: "Acknowledged", // localizationKey("Acknowledged")
  ACTIVE_DIRECTORY_ADD_USER_TO_GROUP_AND_SET_PRIMARY_GROUP_FAILED: "Active Directory add user to group and set primary group failed", // localizationKey("Active Directory add user to group and set primary group failed")
  ACTIVE_DIRECTORY_ADD_USER_TO_GROUP_AND_SET_PRIMARY_GROUP_SUCCESS: "Active Directory add user to group and set primary group success", // localizationKey("Active Directory add user to group and set primary group success")
  ACTIVE_DIRECTORY_ADD_USER_TO_GROUP_FAILED: "Active Directory add user to group failed", // localizationKey("Active Directory add user to group failed")
  ACTIVE_DIRECTORY_ADD_USER_TO_GROUP_SUCCESS: "Active Directory add user to group success", // localizationKey("Active Directory add user to group success")
  ACTIVE_DIRECTORY_ALLOW_USER_PASSWORD_CHANGE_FAILED: "Active Directory allow user password change failed", // localizationKey("Active Directory allow user password change failed")
  ACTIVE_DIRECTORY_ALLOW_USER_PASSWORD_CHANGE_SUCCESS: "Active Directory allow user password change success", // localizationKey("Active Directory allow user password change success")
  ACTIVE_DIRECTORY_AUTO_DISCOVERY_CHANGE_CANDIDATE_NODE_STATUS_FAILED: "Active Directory failed to change approval status of device", // localizationKey("Active Directory failed to change approval status of device")
  ACTIVE_DIRECTORY_AUTO_DISCOVERY_CHANGE_CANDIDATE_NODE_STATUS_SUCCESS: "Active Directory changed approval status of device", // localizationKey("Active Directory changed approval status of device")
  ACTIVE_DIRECTORY_AUTO_DISCOVERY_CLEAR_CANDIDATE_NODES_FAILED: "Active Directory failed to delete device", // localizationKey("Active Directory failed to delete device")
  ACTIVE_DIRECTORY_AUTO_DISCOVERY_CLEAR_CANDIDATE_NODES_SUCCESS: "Active Directory deleted device successfully", // localizationKey("Active Directory deleted device successfully")
  ACTIVE_DIRECTORY_AUTO_DISCOVERY_DEPLOYMENT_RESULT: "Active Directory completed the deployment process of discovered devices", // localizationKey("Active Directory completed the deployment process of discovered devices")
  ACTIVE_DIRECTORY_AUTO_DISCOVERY_DISCOVERY_JOB_CREATED: "Active Directory created discovery job successfully", // localizationKey("Active Directory created discovery job successfully")
  ACTIVE_DIRECTORY_AUTO_DISCOVERY_DISCOVERY_JOB_DELETED: "Active Directory deleted discovery job successfully", // localizationKey("Active Directory deleted discovery job successfully")
  ACTIVE_DIRECTORY_AUTO_DISCOVERY_DISCOVERY_JOB_UPDATED: "Active Directory updated discovery job successfully", // localizationKey("Active Directory updated discovery job successfully")
  ACTIVE_DIRECTORY_AUTO_DISCOVERY_JOB_RESULT_FAILED: "Active Directory failed to configure the deployment process for discovery job", // localizationKey("Active Directory failed to configure the deployment process for discovery job")
  ACTIVE_DIRECTORY_AUTO_DISCOVERY_JOB_RESULT_SUCCESS: "Active Directory successfully configured the deployment process for discovery job", // localizationKey("Active Directory successfully configured the deployment process for discovery job")
  ACTIVE_DIRECTORY_DISABLE_USER_FAILED: "Active Directory disable user failed", // localizationKey("Active Directory disable user failed")
  ACTIVE_DIRECTORY_DISABLE_USER_SUCCESS: "Active Directory disable user success", // localizationKey("Active Directory disable user success")
  ACTIVE_DIRECTORY_DISABLED_ACCOUNT_EXPIRATION_FAILED: "Active Directory disable account expiration failed", // localizationKey("Active Directory disable account expiration failed")
  ACTIVE_DIRECTORY_DISABLED_ACCOUNT_EXPIRATION_SUCCESS: "Active Directory disable account expiration success", // localizationKey("Active Directory disable account expiration success")
  ACTIVE_DIRECTORY_DISABLED_PASSWORD_EXPIRATION_FAILED: "Active Directory disable password expiration failed", // localizationKey("Active Directory disable password expiration failed")
  ACTIVE_DIRECTORY_DISABLED_PASSWORD_EXPIRATION_SUCCESS: "Active Directory disable password expiration success", // localizationKey("Active Directory disable password expiration success")
  ACTIVE_DIRECTORY_DISALLOW_USER_PASSWORD_CHANGE_FAILED: "Active Directory disallow user password change failed", // localizationKey("Active Directory disallow user password change failed")
  ACTIVE_DIRECTORY_DISALLOW_USER_PASSWORD_CHANGE_SUCCESS: "Active Directory disallow user password change success", // localizationKey("Active Directory disallow user password change success")
  ACTIVE_DIRECTORY_ENABLE_USER_FAILED: "Active Directory enable user failed", // localizationKey("Active Directory enable user failed")
  ACTIVE_DIRECTORY_ENABLE_USER_SUCCESS: "Active Directory enable user success", // localizationKey("Active Directory enable user success")
  ACTIVE_DIRECTORY_ENABLED_PASSWORD_EXPIRATION_FAILED: "Active Directory enable password expiration failed", // localizationKey("Active Directory enable password expiration failed")
  ACTIVE_DIRECTORY_ENABLED_PASSWORD_EXPIRATION_SUCCESS: "Active Directory enable password expiration success", // localizationKey("Active Directory enable password expiration success")
  ACTIVE_DIRECTORY_NOT_REQUIRE_PASSWORD_CHANGE_FAILED: "Active Directory do not require password change failed", // localizationKey("Active Directory do not require password change failed")
  ACTIVE_DIRECTORY_NOT_REQUIRE_PASSWORD_CHANGE_SUCCESS: "Active Directory do not require password change success", // localizationKey("Active Directory do not require password change success")
  ACTIVE_DIRECTORY_REMOVE_USER_FROM_GROUP_FAILED: "Active Directory remove user from group failed", // localizationKey("Active Directory remove user from group failed")
  ACTIVE_DIRECTORY_REMOVE_USER_FROM_GROUP_SUCCESS: "Active Directory remove user from group success", // localizationKey("Active Directory remove user from group success")
  ACTIVE_DIRECTORY_REQUIRE_PASSWORD_CHANGE_FAILED: "Active Directory require password change failed", // localizationKey("Active Directory require password change failed")
  ACTIVE_DIRECTORY_REQUIRE_PASSWORD_CHANGE_SUCCESS: "Active Directory require password change success", // localizationKey("Active Directory require password change success")
  ACTIVE_DIRECTORY_RESET_PASSWORD_FAILED: "Active Directory reset password failed", // localizationKey("Active Directory reset password failed")
  ACTIVE_DIRECTORY_RESET_PASSWORD_SUCCESS: "Active Directory reset password success", // localizationKey("Active Directory reset password success")
  ACTIVE_DIRECTORY_SET_ACCOUNT_EXPIRATION_FAILED: "Active Directory set account expiration failed", // localizationKey("Active Directory set account expiration failed")
  ACTIVE_DIRECTORY_SET_ACCOUNT_EXPIRATION_SUCCESS: "Active Directory set account expiration success", // localizationKey("Active Directory set account expiration success")
  ACTIVE_DIRECTORY_SET_PRIMARY_GROUP_FAILED: "Active Directory set primary group failed", // localizationKey("Active Directory set primary group failed")
  ACTIVE_DIRECTORY_SET_PRIMARY_GROUP_SUCCESS: "Active Directory set primary group success", // localizationKey("Active Directory set primary group success")
  ACTIVE_DIRECTORY_UNLOCK_USER_FAILED: "Active Directory unlock user failed", // localizationKey("Active Directory unlock user failed")
  ACTIVE_DIRECTORY_UNLOCK_USER_SUCCESS: "Active Directory unlock user success", // localizationKey("Active Directory unlock user success")
  ADAPTER_ADDED: "Adapter added", // localizationKey("Adapter added")
  ADAPTER_CONFIG_CHANGED: "Adapter config changed", // localizationKey("Adapter config changed")
  ADAPTER_CONFIGURATION: "Adapter CConfiguration", // localizationKey("Adapter CConfiguration")
  ADAPTER_REMOVED: "Adapter removed", // localizationKey("Adapter removed")
  ADAPTER_STATUS_CHANGED: "Adapter status changed", // localizationKey("Adapter status changed")
  ADAPTER_STATUS: "Adapter Status", // localizationKey("Adapter Status")
  AGENT_MESSAGE: "Agent Message", // localizationKey("Agent Message")
  ANTIVIRUS: "Antivirus", // localizationKey("Antivirus")
  API_ACCESS_DENIED: "API Access denied", // localizationKey("API Access denied")
  API_ACCESS_GRANTED: "API Access granted", // localizationKey("API Access granted")
  API_ACCESS_REVOKED: "API Access revoked", // localizationKey("API Access revoked")
  API_WEBHOOK_AUTOMATICALLY_DISABLED: "Webhook automatically disabled", // localizationKey("API Webhook automatically disabled")
  API_WEBHOOK_CONFIGURATION_UPDATED: "API Webhook configuration updated", // localizationKey("API Webhook configuration updated")
  API_WEBHOOK_FAILURE: "Webhook failure", // localizationKey("API Webhook failure")
  API: "API",
  APP_USER_AUDIT_FAILED_LOGIN: "Technician Login failed", // localizationKey("Technician Login failed")
  APP_USER_CREATED: "Technician Created", // localizationKey("Technician Created")
  APP_USER_CRITICAL_ACTION: "Technician Performed Critical Action", // localizationKey("Technician Performed Critical Action")
  APP_USER_DELETED: "Technician Deleted", // localizationKey("Technician Deleted")
  APP_USER_LOGGED_IN: "Technician Logged In", // localizationKey("Technician Logged In")
  APP_USER_LOGGED_OUT: "Technician Logged Out", // localizationKey("Technician Logged Out")
  APP_USER_MFA_DELETED: "Technician MFA Deleted", // localizationKey("Technician MFA Deleted")
  APP_USER_MFA_SETUP: "Technician MFA Configured", // localizationKey("Technician MFA Configured")
  APP_USER_PERMISSIONS_CHANGED: "Technician Permissions Changed", // localizationKey("Technician Permissions Changed")
  APP_USER_ROLE_ASSIGNMENT_CHANGED: "Technician Role Assignment Changed", // localizationKey("Technician Role Assignment Changed")
  APP_USER_UPDATED: "Technician Updated", // localizationKey("Technician Updated")
  ATTACHMENT_FILE_SUSPICIOUS: "Attachment File Suspicious", // localizationKey("Attachment File Suspicious")
  AUTOTASK_ATTACHMENT_FAILURE: "An error occurred while adding an attachment", // localizationKey("An error occurred while adding an attachment")
  AUTOTASK_NODE_SYNC_COMPLETED: "Autotask node sync completed", // localizationKey("Autotask node sync completed")
  AUTOTASK_NODE_SYNC_NODE_CREATED: "Autotask node sync node created", // localizationKey("Autotask node sync node created")
  AUTOTASK_NODE_SYNC_NODE_DELETED: "Autotask node sync node deleted", // localizationKey("Autotask node sync node deleted")
  AUTOTASK_NODE_SYNC_NODE_UPDATED: "Autotask node sync node updated", // localizationKey("Autotask node sync node updated")
  AUTOTASK_NODE_SYNC_STARTED: "Autotask node sync started", // localizationKey("Autotask node sync started")
  AUTOTASK_UPDATED: "Autotask updated", // localizationKey("Autotask updated")
  AV_VIPREAV_ACTIVEPROTECTION_THREAT_QUARANTINED: "Antivirus Active protection(Vipre) threat quarantined", // localizationKey("Antivirus Active protection(Vipre) threat quarantined")
  AV_VIPREAV_QUARANTINED_THREAT_REMOVED: "Antivirus (Vipre) threat in quarantine removed", // localizationKey("Antivirus (Vipre) threat in quarantine removed")
  AV_VIPREAV_SCAN_ABORTED: "Antivirus (Vipre) Scan aborted", // localizationKey("Antivirus (Vipre) Scan aborted")
  AV_VIPREAV_SCAN_FAILED: "Antivirus (Vipre) Scan failed", // localizationKey("Antivirus (Vipre) Scan failed")
  AV_VIPREAV_SCAN_THREAT_QUARANTINED: "Antivirus Scan (Vipre) threat quarantined", // localizationKey("Antivirus Scan (Vipre) threat quarantined")
  AV_VIPREAV_USERINITIATED_THREAT_QUARANTINED: "Antivirus (Vipre) User initiated threat quarantined", // localizationKey("Antivirus (Vipre) User initiated threat quarantined")
  AV_WEBROOT_THREAT_DETECTED: "Antivirus (Webroot) threat detected", // localizationKey("Antivirus (Webroot) threat detected")
  BDAS_BITDEFENDER_PURGE_QUARANTINE_FAILED: "Bitdefender purge quarantine failed", // localizationKey("Bitdefender purge quarantine failed")
  BDAS_BITDEFENDER_PURGE_QUARANTINE_SUCCESS: "Bitdefender purge quarantine success", // localizationKey("Bitdefender purge quarantine success")
  BDAS_BITDEFENDER_THREAT_BLOCKED: "Bitdefender threat detected, action taken, blocked", // localizationKey("Bitdefender threat detected, action taken, blocked")
  BDAS_BITDEFENDER_THREAT_CLEANED: "Bitdefender threat detected, action taken, cleaned", // localizationKey("Bitdefender threat detected, action taken, cleaned")
  BDAS_BITDEFENDER_THREAT_DELETED: "Bitdefender threat detected, action taken, deleted", // localizationKey("Bitdefender threat detected, action taken, deleted")
  BDAS_BITDEFENDER_THREAT_IGNORED: "Bitdefender threat detected, action taken, ignored", // localizationKey("Bitdefender threat detected, action taken, ignored")
  BDAS_BITDEFENDER_THREAT_PRESENT: "Bitdefender threat detected, still present", // localizationKey("Bitdefender threat detected, still present")
  BDAS_BITDEFENDER_THREAT_QUARANTINE_DELETED: "Bitdefender user deleted a threat from quarantine", // localizationKey("Bitdefender user deleted a threat from quarantine")
  BDAS_BITDEFENDER_THREAT_QUARANTINE_RESTORED_CUSTOMPATH: "Bitdefender user restored a custom path", // localizationKey("Bitdefender user restored a custom path")
  BDAS_BITDEFENDER_THREAT_QUARANTINE_RESTORED: "Bitdefender user restored a threat from quarantine", // localizationKey("Bitdefender user restored a threat from quarantine")
  BDAS_BITDEFENDER_THREAT_QUARANTINED: "Bitdefender threat detected, action taken, quarantined", // localizationKey("Bitdefender threat detected, action taken, quarantined")
  BITDEFENDER_BDRC_REBOOT_DISINFECT_MESSAGE: "this device needs a reboot to perform an AV action and disinfect", // localizationKey("this device needs a reboot to perform an AV action and disinfect")
  BITDEFENDER_BDRC_REBOOT_DISINFECT: "REBOOT DISINFECT", // localizationKey("REBOOT DISINFECT")
  BITDEFENDER_BDRC_REBOOT_INSTALL_FAIL_MESSAGE: "this node needs a reboot to continue the installation process", // localizationKey("this node needs a reboot to continue the installation process")
  BITDEFENDER_BDRC_REBOOT_INSTALL_FAIL: "INSTALL FAIL", // localizationKey("INSTALL FAIL")
  BITDEFENDER_BDRC_REBOOT_PROD_UPDATE_MESSAGE: "this device needs a reboot to upgrade AV engine", // localizationKey("this device needs a reboot to upgrade AV engine")
  BITDEFENDER_BDRC_REBOOT_PROD_UPDATE: "REBOOT UPDATE", // localizationKey("REBOOT UPDATE")
  BITDEFENDER_DISABLED: "Bitdefender disabled", // localizationKey("Bitdefender disabled")
  BITDEFENDER_DOWNLOAD_FAILED_MESSAGE: "DOWNLOAD_FAILED - Failed to download the BitDefender installer", // localizationKey("DOWNLOAD_FAILED - Failed to download the BitDefender installer")
  BITDEFENDER_DOWNLOAD_FAILED: "Bitdefender download failed", // localizationKey("Bitdefender download failed")
  BITDEFENDER_DOWNLOAD_STARTED_MESSAGE: "DOWNLOAD_STARTED - BitDefender installer download has started", // localizationKey("DOWNLOAD_STARTED - BitDefender installer download has started")
  BITDEFENDER_DOWNLOAD_STARTED: "Bitdefender download started", // localizationKey("Bitdefender download started")
  BITDEFENDER_DOWNLOAD_SUCCEEDED_MESSAGE: "DOWNLOADED - Successfully downloaded the BitDefender installer", // localizationKey("DOWNLOADED - Successfully downloaded the BitDefender installer")
  BITDEFENDER_DOWNLOAD_SUCCEEDED: "Bitdefender download succeeded", // localizationKey("Bitdefender download succeeded")
  BITDEFENDER_EXISTING_PRODUCT_UNINSTALL: "Previous versions of Bitdefender will be uninstalled during the Bitdefender installation", // localizationKey("Previous versions of Bitdefender will be uninstalled during the Bitdefender installation")
  BITDEFENDER_INSTALLATION_FAILED_MESSAGE: "INSTALL_FAILED - Failed to install BitDefender", // localizationKey("INSTALL_FAILED - Failed to install BitDefender")
  BITDEFENDER_INSTALLATION_FAILED: "Bitdefender install failed", // localizationKey("Bitdefender install failed")
  BITDEFENDER_INSTALLATION_STARTED_MESSAGE: "INSTALL_STARTED - BitDefender installation has started", // localizationKey("INSTALL_STARTED - BitDefender installation has started")
  BITDEFENDER_INSTALLATION_STARTED: "Bitdefender installation started", // localizationKey("Bitdefender installation started")
  BITDEFENDER_INSTALLATION_SUCCEEDED: "Bitdefender install success", // localizationKey("Bitdefender install success")
  BITDEFENDER_RETRY_INSTALL_COMPLETED: "Bitdefender retry Installation completed", // localizationKey("Bitdefender retry Installation completed")
  BITDEFENDER_SCAN_COMPLETED: "Bitdefender scan completed", // localizationKey("Bitdefender scan completed")
  BITDEFENDER_SCAN_FAILED: "Bitdefender scan failed", // localizationKey("Bitdefender scan failed")
  BITDEFENDER_SCAN_STARTED: "Bitdefender scan started", // localizationKey("Bitdefender scan started")
  BITDEFENDER_THREAT_DELETE_FROM_QUARANTINE_FAILED: "Bitdefender delete threat from quarantine fail", // localizationKey("Bitdefender delete threat from quarantine fail")
  BITDEFENDER_THREAT_DELETE_FROM_QUARANTINE: "Bitdefender delete threat from quarantine", // localizationKey("Bitdefender delete threat from quarantine")
  BITDEFENDER_THREAT_RESTORE_FROM_QUARANTINE_FAILED: "Bitdefender restore threat from quarantine fail", // localizationKey("Bitdefender restore threat from quarantine fail")
  BITDEFENDER_THREAT_RESTORE_FROM_QUARANTINE: "Bitdefender restore threat from quarantine", // localizationKey("Bitdefender restore threat from quarantine")
  BITDEFENDER_UNINSTALLATION_FAILED_MESSAGE: "UNINSTALL_FAILED - Failed to uninstall BitDefender", // localizationKey("UNINSTALL_FAILED - Failed to uninstall BitDefender")
  BITDEFENDER_UNINSTALLATION_FAILED: "Bitdefender uninstall failed", // localizationKey("Bitdefender uninstall failed")
  BITDEFENDER_UNINSTALLATION_STARTED: "Bitdefender uninstallation started", // localizationKey("Bitdefender uninstallation started")
  BITDEFENDER_UNINSTALLATION_SUCCEEDED: "Bitdefender uninstall success", // localizationKey("Bitdefender uninstall success")
  BITDEFENDER_UNPACKING_FAILED: "Bitdefender unpacking failed", // localizationKey("Bitdefender unpacking failed")
  BITLOCKER_DISABLED: "Bitlocker turned off", // localizationKey("Bitlocker turned off")
  BITLOCKER_ENABLED: "Bitlocker turned on", // localizationKey("Bitlocker turned on")
  BLOCKED: "Blocked", // localizationKey("Blocked")
  CANCEL_REQUESTED: "Cancel requested", // localizationKey("Cancel requested")
  CANCELLED: "Canceled", // localizationKey("Canceled")
  CHECKLIST_TEMPLATE_ARCHIVED: "Checklist Template Archived", // localizationKey("Checklist Template Archived")
  CHECKLIST_TEMPLATE_CREATED: "Checklist Template Created", // localizationKey("Checklist Template Created")
  CHECKLIST_TEMPLATE_DELETED: "Checklist Template Deleted", // localizationKey("Checklist Template Deleted")
  CHECKLIST_TEMPLATE_RESTORED: "Checklist Template Restored", // localizationKey("Checklist Template Restored")
  CHECKLIST_TEMPLATE_UPDATED: "Checklist Template Updated", // localizationKey("Checklist Template Updated")
  CLEARED: "Cleared", // localizationKey("Cleared")
  CLIENT_CHECKLIST_ARCHIVED: "Checklist Archived", // localizationKey("Checklist Archived")
  CLIENT_CHECKLIST_CREATED: "Organization Checklist Created", // localizationKey("Organization Checklist Created")
  CLIENT_CHECKLIST_DELETED: "Organization Checklist Deleted", // localizationKey("Organization Checklist Deleted")
  CLIENT_CHECKLIST_PROMOTED: "Organization Checklist Promoted", // localizationKey("Organization Checklist Promoted")
  CLIENT_CHECKLIST_RESTORED: "Checklist Restored", // localizationKey("Checklist Restored")
  CLIENT_CHECKLIST_TEMPORARY_PUBLIC_TOKEN_CREATED: "Checklist Public Temporary Token Created", //localizationKey("Checklist Public Temporary Token Created")
  CLIENT_CHECKLIST_UPDATED: "Organization Checklist Updated", // localizationKey("Organization Checklist Updated")
  CLIENT_CREATED: "Organization Created", // localizationKey("Organization Created")
  CLIENT_DELETED: "Organization Deleted", // localizationKey("Organization Deleted")
  CLIENT_DOCUMENT_ARCHIVED: "Organization Document Archived", // localizationKey("Organization Document Archived")
  CLIENT_DOCUMENT_ATTRIBUTE_TEMPORARY_PUBLIC_TOKEN_CREATED: "Organization Document Temporary Public Token Created", //localizationKey("Organization Document Temporary Public Token Created")
  CLIENT_DOCUMENT_ATTRIBUTE_VALUE_DECRYPTED: "Organization Document Field Value Decrypted", // localizationKey("Organization Document Field Value Decrypted")
  CLIENT_DOCUMENT_CREATED: "Organization Document Created", // localizationKey("Organization Document Created")
  CLIENT_DOCUMENT_DELETED: "Organization Document Deleted", // localizationKey("Organization Document Deleted")
  CLIENT_DOCUMENT_RESTORED: "Organization Document Restored", // localizationKey("Organization Document Restored")
  CLIENT_DOCUMENT_REVISION_DELETED: "Organization Revision Deleted", // localizationKey("Organization Revision Deleted")
  CLIENT_DOCUMENT_UPDATED: "Organization Document Updated", // localizationKey("Organization Document Updated")
  CLIENT_DOCUMENTATION_DELEGATE_NODE_RESPONSIBILITY_ADDED: "Documentation Delegate Responsibility Added", // localizationKey("Documentation Delegate Responsibility Added")
  CLIENT_DOCUMENTATION_DELEGATE_NODE_RESPONSIBILITY_REMOVED: "Documentation Delegate Responsibility Removed", // localizationKey("Documentation Delegate Responsibility Removed")
  CLIENT_UPDATED: "Organization Updated", // localizationKey("Organization Updated")
  CLOUDBERRY_BACKUP: "CloudBerry Backup",
  CLOUDBERRY_BACKUPJOB_COMPLETED_WITH_WARNING: "CloudBerry backup job completed with warning", // localizationKey("CloudBerry backup job completed with warning")
  CLOUDBERRY_BACKUPJOB_FAILED: "CloudBerry backup job failed", // localizationKey("CloudBerry backup job failed")
  CLOUDBERRY_BACKUPJOB_STARTED: "CloudBerry backup job started", // localizationKey("CloudBerry backup job started")
  CLOUDBERRY_BACKUPJOB_SUCCEEDED: "CloudBerry backup job succeeded", // localizationKey("CloudBerry backup job succeeded")
  CLOUDBERRY_BACKUPPLAN_CREATED: "CloudBerry backup plan created", // localizationKey("CloudBerry backup plan created")
  CLOUDBERRY_BACKUPPLAN_CREATION_FAILED: "CloudBerry backup plan creation failed", // localizationKey("CloudBerry backup plan creation failed")
  CLOUDBERRY_BACKUPPLAN_DELETED: "CloudBerry backup plan deleted", // localizationKey("CloudBerry backup plan deleted")
  CLOUDBERRY_BACKUPPLAN_EDITED: "CloudBerry backup plan edited", // localizationKey("CloudBerry backup plan edited")
  CLOUDBERRY_INSTALL_FAILED: "CloudBerry install failed", // localizationKey("CloudBerry install failed")
  CLOUDBERRY_INSTALLED: "CloudBerry installed", // localizationKey("CloudBerry installed")
  CLOUDBERRY_NETWORK_CREDENTIAL_CREATED: "CloudBerry network credential created", // localizationKey("CloudBerry network credential created")
  CLOUDBERRY_NETWORK_CREDENTIAL_CREATION_FAILED: "CloudBerry network credential creation failed", // localizationKey("CloudBerry network credential creation failed")
  CLOUDBERRY_UNINSTALL_FAILED: "CloudBerry uninstall failed", // localizationKey("CloudBerry uninstall failed")
  CLOUDBERRY_UNINSTALLED: "CloudBerry uninstalled", // localizationKey("CloudBerry uninstalled")
  CLOUDBERRY_USER_CREATED: "CloudBerry enabled for organization", // localizationKey("CloudBerry enabled for organization")
  CLOUDBERRY: "Cloudberry",
  COMMENT: "Login", // localizationKey("Login")
  COMPETITOR_EXISTING_PRODUCT_UNINSTALL: "Bitdefender installation settings when non-Bitdefender AV are present", // localizationKey("Bitdefender installation settings when non-Bitdefender AV are present")
  COMPLETED: "Completed", // localizationKey("Completed")
  CONDITION_ACTION: "Condition action", // localizationKey("Condition action")
  CONDITION: "Condition", // localizationKey("Condition")
  CONNECTWISE_AGREEMENTS_SYNC_COMPLETED: "ConnectWise agreements sync completed", // localizationKey("ConnectWise agreements sync completed")
  CONNECTWISE_AGREEMENTS_SYNC_STARTED: "ConnectWise agreements sync started", // localizationKey("ConnectWise agreements sync started")
  CONNECTWISE_NODE_SYNC_COMPLETED: "ConnectWise node sync completed", // localizationKey("ConnectWise node sync completed")
  CONNECTWISE_NODE_SYNC_NODE_CREATED: "ConnectWise node sync node created", // localizationKey("ConnectWise node sync node created")
  CONNECTWISE_NODE_SYNC_NODE_DELETED: "ConnectWise node sync node deleted", // localizationKey("ConnectWise node sync node deleted")
  CONNECTWISE_NODE_SYNC_NODE_UPDATED: "ConnectWise node sync node updated", // localizationKey("ConnectWise node sync node updated")
  CONNECTWISE_NODE_SYNC_STARTED: "ConnectWise node sync started", // localizationKey("ConnectWise node sync started")
  CONNECTWISE_UPDATED: "ConnectWise updated", // localizationKey("ConnectWise updated")
  CONNECTWISECONTROL_ATTEMPT: "ConnectWiseControl connection attempted", // localizationKey("ConnectWiseControl connection attempted")
  CONTACT_CREATED: "Contact Created", // localizationKey("Contact Created")
  CONTACT_DELETED: "Contact Deleted", // localizationKey("Contact Deleted")
  CONTACT_UPDATED: "Contact Updated", // localizationKey("Contact Updated")
  CPU_ADDED: "CPU added", // localizationKey("CPU added")
  CPU_REMOVED: "CPU removed", // localizationKey("CPU removed")
  CREDENTIAL_CREATED: "Organization Credential Created", // localizationKey("Organization Credential Created")
  CREDENTIAL_DELETED: "Organization Credential Deleted", // localizationKey("Organization Credential Deleted")
  CREDENTIAL_UPDATED: "Organization Credential Updated", // localizationKey("Organization Credential Updated")
  CREDENTIALS_CHANGED: "Credentials changed", // localizationKey("Credentials changed")
  CROWDSTRIKE_CREDENTIAL_INVALID: "Invalid CrowdStrike Credential", //localizationKey("Invalid CrowdStrike Credential")
  CROWDSTRIKE_CREDENTIALS_UPDATED: "CrowdStrike Integration Credentials Updated", //localizationKey("CrowdStrike Integration Credentials Updated")
  CROWDSTRIKE_DISABLED: "CrowdStrike Integration Disabled", //localizationKey("CrowdStrike Integration Disabled")
  CROWDSTRIKE_ENABLED: "CrowdStrike Integration Enabled", //localizationKey("CrowdStrike Integration Enabled")
  CROWDSTRIKE_EXISTING_INSTALLATION_DETECTED: "CrowdStrike Existing Installation Detected", //localizationKey("CrowdStrike Existing Installation Detected")
  CROWDSTRIKE_INSTALLATION_ANOTHER_AV_DETECTED: "CrowdStrike Installation Detected Another AV", //localizationKey("CrowdStrike Installation Detected Another AV")
  CROWDSTRIKE_MISSING_API_CLIENT_SCOPE: "CrowdStrike Integration Missing API Client Scope", //localizationKey("CrowdStrike Integration Missing API Client Scope")
  CROWDSTRIKE_MISSING_PERMISSIONS: "CrowdStrike Sensor Installation Pending. Permissions Required", //localizationKey("CrowdStrike Sensor Installation Pending. Permissions Required")
  CROWDSTRIKE_MITIGATION_FAILED: "CrowdStrike Mitigation Failed", // localizationKey("CrowdStrike Mitigation Failed")
  CROWDSTRIKE_MITIGATION_SUCCEEDED: "CrowdStrike Mitigation Succeeded", // localizationKey("CrowdStrike Mitigation Succeeded")
  CROWDSTRIKE_QUARANTINE_FAILED: "CrowdStrike Quarantine Failed", // localizationKey("CrowdStrike Quarantine Failed")
  CROWDSTRIKE_QUARANTINE_SUCCEEDED: "CrowdStrike Quarantine Succeeded", // localizationKey("CrowdStrike Quarantine Succeeded")
  CROWDSTRIKE_REGISTRATION_FAILED: "CrowdStrike Machine Registration Failed", //localizationKey("CrowdStrike Machine Registration Failed")
  CROWDSTRIKE_REGISTRATION_SUCCEEDED: "CrowdStrike Machine Registration Succeeded", //localizationKey("CrowdStrike Machine Registration Succeeded")
  CROWDSTRIKE_REMEDIATION_FAILED: "CrowdStrike Remediation Failed", // localizationKey("CrowdStrike Remediation Failed")
  CROWDSTRIKE_REMEDIATION_SUCCEEDED: "CrowdStrike Remediation Succeeded", // localizationKey("CrowdStrike Remediation Succeeded")
  CROWDSTRIKE_RETRY_INSTALL_COMPLETED: "CrowdStrike Retry Install Completed", //localizationKey("CrowdStrike Retry Install Completed")
  CROWDSTRIKE_SENSOR_DISABLED: "CrowdStrike Sensor Disabled", // localizationKey("CrowdStrike Sensor Disabled")
  CROWDSTRIKE_SENSOR_ENABLED: "CrowdStrike Sensor Enabled", // localizationKey("CrowdStrike Sensor Enabled")
  CROWDSTRIKE_SENSOR_INSTALLATION_FAILED: "CrowdStrike Sensor Installation Failed", //localizationKey("CrowdStrike Sensor Installation Failed")
  CROWDSTRIKE_SENSOR_INSTALLATION_SUCCEEDED: "CrowdStrike Sensor Installation Succeeded", //localizationKey("CrowdStrike Sensor Installation Succeeded")
  CROWDSTRIKE_SENSOR_SHUT_DOWN: "CrowdStrike Sensor Shut Down", //localizationKey("CrowdStrike Sensor Shut Down")
  CROWDSTRIKE_SENSOR_STARTED: "CrowdStrike Sensor Started", // localizationKey("CrowdStrike Sensor Started")
  CROWDSTRIKE_SENSOR_UPGRADED: "CrowdStrike Sensor Upgraded", // localizationKey("CrowdStrike Sensor Upgraded")
  CROWDSTRIKE_THREAT_DETECTED: "CrowdStrike Threat Detected", // localizationKey("CrowdStrike Threat Detected")
  CUSTOM_HEALTH_STATUS_CHANGED: "Custom Health Status Changed", // localizationKey("Custom Health Status Changed")
  CUSTOM_HEALTH_STATUS_RESET: "Custom Health Status Reset to Defaults", // localizationKey("Custom Health Status Reset to Defaults")
  DEVICE_GROUP_CREATED: "Device Group Created", // localizationKey("Device Group Created")
  DEVICE_GROUP_DELETED: "Device Group Deleted", // localizationKey("Device Group Deleted")
  DEVICE_GROUP_UPDATED: "Device Group Updated", // localizationKey("Device Group Updated")
  DISABLED: "Disabled", // localizationKey("Disabled")
  DISK_DRIVE_ADDED: "Disk drive added", // localizationKey("Disk drive added")
  DISK_DRIVE_REMOVED: "Disk drive removed", // localizationKey("Disk drive removed")
  DISK_PARTITION_ADDED: "Disk partition added", // localizationKey("Disk partition added")
  DISK_PARTITION_REMOVED: "Disk partition removed", // localizationKey("Disk partition removed")
  DISK_VOLUME_ADDED: "Disk volume added", // localizationKey("Disk volume added")
  DISK_VOLUME_REMOVED: "Disk volume removed", // localizationKey("Disk volume removed")
  DIVISION_FEATURE_DISABLED: "Division Feature Disabled", //localizationKey("Division Feature Disabled")
  DIVISION_FEATURE_ENABLED: "Division Feature Enabled", //localizationKey("Division Feature Enabled")
  DOCUMENT_TEMPLATE_ARCHIVED: "Document Template Archived", // localizationKey("Document Template Archived")
  DOCUMENT_TEMPLATE_CREATED: "Document Template Created", // localizationKey("Document Template Created")
  DOCUMENT_TEMPLATE_DELETED: "Document Template Deleted", // localizationKey("Document Template Deleted")
  DOCUMENT_TEMPLATE_RESTORED: "Document Template Restored", // localizationKey("Document Template Restored")
  DOCUMENT_TEMPLATE_UPDATED: "Document Template Updated", // localizationKey("Document Template Updated")
  DRIVE_ADDED: "Drive Added", // localizationKey("Drive Added")
  DRIVE_REMOVED: "Drive Removed", // localizationKey("Drive Removed")
  END_USER_AUDIT_FAILED_LOGIN: "End user failed to login", // localizationKey("End user failed to login")
  END_USER_CREATED: "End User Created", // localizationKey("End User Created")
  END_USER_CRITICAL_ACTION: "End User Performed Critical Action", // localizationKey("End User Performed Critical Action")
  END_USER_DELETED: "End User Deleted", // localizationKey("End User Deleted")
  END_USER_LOGGED_IN_OR_OUT: "End User Logged In or Out", // localizationKey("End User Logged In or Out")
  END_USER_LOGGED_IN: "End User Logged In", // localizationKey("End User Logged In")
  END_USER_LOGGED_OUT: "End User Logged Out", // localizationKey("End User Logged Out")
  END_USER_MFA_DELETED: "End User MFA Settings Deleted", // localizationKey("End User MFA Settings Deleted")
  END_USER_MFA_SETUP: "End User MFA Settings Completed", // localizationKey("End User MFA Settings Completed")
  END_USER_PERMISSIONS_CHANGED: "End User Permissions Changed", //localizationKey("End User Permissions Changed")
  END_USER_ROLE_ASSIGNMENT_CHANGED: "End User Role Assignment Changed", //localizationKey("End User Role Assignment Changed")
  END_USER_ROLE_CREATED: "End User Role Created", //localizationKey("End User Role Created")
  END_USER_ROLE_DELETED: "End User Role Deleted", //localizationKey("End User Role Deleted")
  END_USER_ROLE_UPDATED: "End User Role Updated", //localizationKey("End User Role Updated")
  END_USER_UPDATED: "End User Updated", // localizationKey("End User Updated")
  EVALUATION_FAILURE: "Evaluation failure", // localizationKey("Evaluation failure")
  FILEVAULT_DISABLED: "FileVault turned off", // localizationKey("FileVault turned off")
  FILEVAULT_ENABLED: "FileVault turned on", // localizationKey("FileVault turned on")
  GRAVITYZONE_COMPANY_CANNOT_BE_ACCESSED: "GravityZone company associated with this device cannot be accessed", // localizationKey("GravityZone company associated with this device cannot be accessed")
  GRAVITYZONE_COMPANY_REPROVISIONED: "GravityZone Company was re-provisioned", // localizationKey("GravityZone Company was re-provisioned")
  GRAVITYZONE_DEVICE_MANAGED: "GravityZone Device changed status to managed", // localizationKey("GravityZone Device changed status to managed")
  GRAVITYZONE_DEVICE_UNMANAGED: "GravityZone Device changed status to unmanaged", // localizationKey("GravityZone Device changed status to unmanaged")
  GRAVITYZONE_DISABLED: "GravityZone Disabled", // localizationKey("GravityZone Disabled")
  GRAVITYZONE_DISABLING_FAILED: "GravityZone Disabling Failed", //localizationKey("GravityZone Disabling Failed")
  GRAVITYZONE_DOWNLOAD_FAILED_MESSAGE: "GRAVITYZONE_DOWNLOAD_FAILED - Failed to download the GravityZone installer", // localizationKey("GRAVITYZONE_DOWNLOAD_FAILED - Failed to download the GravityZone installer")
  GRAVITYZONE_DOWNLOAD_FAILED: "GravityZone download failed", // localizationKey("GravityZone download failed")
  GRAVITYZONE_DOWNLOAD_STARTED_MESSAGE: "GRAVITYZONE_DOWNLOAD_STARTED - GravityZone installer download has started", // localizationKey("GRAVITYZONE_DOWNLOAD_STARTED - GravityZone installer download has started")
  GRAVITYZONE_DOWNLOAD_STARTED: "GravityZone download started", // localizationKey("GravityZone download started")
  GRAVITYZONE_DOWNLOAD_SUCCEEDED_MESSAGE: "GRAVITYZONE_DOWNLOADED - Successfully downloaded the GravityZone installer", // localizationKey("GRAVITYZONE_DOWNLOADED - Successfully downloaded the GravityZone installer")
  GRAVITYZONE_DOWNLOAD_SUCCEEDED: "GravityZone download succeeded", // localizationKey("GravityZone download succeeded")
  GRAVITYZONE_ENABLED: "GravityZone Enabled", // localizationKey("GravityZone Enabled")
  GRAVITYZONE_INSTALLATION_EXISTS_DETECTED: "GravityZone Installation Exists Detected", //localizationKey("GravityZone Installation Exists Detected")
  GRAVITYZONE_INSTALLATION_FAILED_MESSAGE: "GRAVITYZONE_INSTALL_FAILED - Failed to install GravityZone", // localizationKey("GRAVITYZONE_INSTALL_FAILED - Failed to install GravityZone")
  GRAVITYZONE_INSTALLATION_FAILED: "GravityZone Installation Failed", // localizationKey("GravityZone Installation Failed")
  GRAVITYZONE_INSTALLATION_RETRY_STARTED: "GravityZone installation retry attempt started", //localizationKey("GravityZone installation retry attempt started")
  GRAVITYZONE_INSTALLATION_STARTED_MESSAGE: "GRAVITYZONE_INSTALL_STARTED - GravityZone installation has started", // localizationKey("GRAVITYZONE_INSTALL_STARTED - GravityZone installation has started")
  GRAVITYZONE_INSTALLATION_STARTED: "GravityZone installation started", // localizationKey("GravityZone installation started")
  GRAVITYZONE_INSTALLATION_SUCCEEDED: "GravityZone Installation Completed", // localizationKey("GravityZone Installation Completed")
  GRAVITYZONE_PARTNER_ASSIST_DISABLED: "GravityZone Partner Assist was Disabled", // localizationKey("GravityZone Partner Assist was Disabled")
  GRAVITYZONE_PROVISION_COMPLETED: "GravityZone Integration was successfully provisioned", // localizationKey("GravityZone Integration was successfully provisioned")
  GRAVITYZONE_PROVISION_FAILED: "GravityZone Integration failed during provisioning", // localizationKey("GravityZone Integration failed during provisioning")
  GRAVITYZONE_PURGE_QUARANTINE_FAILED: "GravityZone Purge of Quarantined Files Failed", // localizationKey("GravityZone Purge of Quarantined Files Failed")
  GRAVITYZONE_PURGE_QUARANTINE_SUCCESS: "GravityZone Quarantined Threats Purged", // localizationKey("GravityZone Quarantined Threats Purged")
  GRAVITYZONE_RESET_INACCESSIBLE_LICENSE_COMPLETED: "GravityZone License Alert Reset Completed", // localizationKey("GravityZone License Alert Reset Completed")
  GRAVITYZONE_RETRY_INSTALL_COMPLETED: "GravityZone Retry Installation Completed", // localizationKey("GravityZone Retry Installation Completed")
  GRAVITYZONE_SCAN_ATTEMPT_COMPLETED: "GravityZone Scan Attempt Completed", // localizationKey("GravityZone Scan Attempt Completed")
  GRAVITYZONE_SCAN_CANCELLED: "GravityZone Scan Cancelled", //localizationKey("GravityZone Scan Cancelled")
  GRAVITYZONE_SCAN_COMPLETED: "GravityZone Scan Completed", // localizationKey("GravityZone Scan Completed")
  GRAVITYZONE_SCAN_FAILED: "GravityZone Scan Failed", // localizationKey("GravityZone Scan Failed")
  GRAVITYZONE_SCAN_STARTED: "GravityZone Scan Started", // localizationKey("GravityZone Scan Started")
  GRAVITYZONE_THREAT_BLOCKED_FROM_ACTIVE_COMPLETED: "GravityZone Block Threat From Active Completed", //localizationKey("GravityZone Block Threat From Active Completed")
  GRAVITYZONE_THREAT_BLOCKED_FROM_ACTIVE_FAILED: "GravityZone Block Threat From Active Failed", //localizationKey("GravityZone Block Threat From Active Failed")
  GRAVITYZONE_THREAT_BLOCKED_FROM_ACTIVE_REQUESTED: "GravityZone Blocked Threat From Active Requested", //localizationKey("GravityZone Blocked Threat From Active Requested")
  GRAVITYZONE_THREAT_BLOCKED: "GravityZone Threat Blocked", // localizationKey("GravityZone Threat Blocked")
  GRAVITYZONE_THREAT_CLEANED: "GravityZone Threat Cleaned", // localizationKey("GravityZone Threat Cleaned")
  GRAVITYZONE_THREAT_DELETE_FROM_QUARANTINE_COMPLETED: "GravityZone Remove Threat From Quarantine Completed", // localizationKey("GravityZone Remove Threat From Quarantine Completed")
  GRAVITYZONE_THREAT_DELETE_FROM_QUARANTINE_FAILED: "GravityZone Remove Threat From Quarantine Failed", // localizationKey("GravityZone Remove Threat From Quarantine Failed")
  GRAVITYZONE_THREAT_DELETE_FROM_QUARANTINE_REQUESTED: "GravityZone Remove Threat From Quarantine Requested", //localizationKey("GravityZone Remove Threat From Quarantine Requested")
  GRAVITYZONE_THREAT_DELETE_FROM_QUARANTINE: "GravityZone Remove Threat From Quarantine Initiated", // localizationKey("GravityZone Remove Threat From Quarantine Initiated")
  GRAVITYZONE_THREAT_DELETED: "GravityZone Threat Deleted", // localizationKey("GravityZone Threat Deleted")
  GRAVITYZONE_THREAT_IGNORED: "GravityZone Threat Ignored", // localizationKey("GravityZone Threat Ignored")
  GRAVITYZONE_THREAT_PRESENT: "GravityZone Threat Detected", // localizationKey("GravityZone Threat Detected")
  GRAVITYZONE_THREAT_QUARANTINE_DELETED: "GravityZone threat quarantine deleted", // localizationKey("GravityZone threat quarantine deleted")
  GRAVITYZONE_THREAT_QUARANTINE_RESTORED_CUSTOMPATH: "GravityZone threat quarantine restored custompath", // localizationKey("GravityZone threat quarantine restored custompath")
  GRAVITYZONE_THREAT_QUARANTINE_RESTORED: "GravityZone threat quarantine restored", // localizationKey("GravityZone threat quarantine restored")
  GRAVITYZONE_THREAT_QUARANTINED: "GravityZone Threat Quarantined", // localizationKey("GravityZone Threat Quarantined")
  GRAVITYZONE_THREAT_RESTORE_AND_EXCLUDED_FROM_QUARANTINE_FAILED: "GravityZone Restore and Exclude Threat From Quarantine Failed", // localizationKey("GravityZone Restore and Exclude Threat From Quarantine Failed")
  GRAVITYZONE_THREAT_RESTORE_AND_EXCLUDED_FROM_QUARANTINE_REQUESTED: "GravityZone Restore and Exclude Threat From Quarantine Requested", //localizationKey("GravityZone Restore and Exclude Threat From Quarantine Requested")
  GRAVITYZONE_THREAT_RESTORE_AND_EXCLUDED_FROM_QUARANTINE: "GravityZone Restore and Exclude Threat From Quarantine Initiated", // localizationKey("GravityZone Restore and Exclude Threat From Quarantine Initiated")
  GRAVITYZONE_THREAT_RESTORE_FROM_QUARANTINE_COMPLETED: "GravityZone Restore Threat From Quarantine Completed", // localizationKey("GravityZone Restore Threat From Quarantine Completed")
  GRAVITYZONE_THREAT_RESTORE_FROM_QUARANTINE_FAILED: "GravityZone Restore Threat From Quarantine Failed", // localizationKey("GravityZone Restore Threat From Quarantine Failed")
  GRAVITYZONE_THREAT_RESTORE_FROM_QUARANTINE_REQUESTED: "GravityZone Restore Threat From Quarantine Requested", //localizationKey("GravityZone Restore Threat From Quarantine Requested")
  GRAVITYZONE_THREAT_RESTORE_FROM_QUARANTINE: "GravityZone Restore Threat From Quarantine Initiated", // localizationKey("GravityZone Restore Threat From Quarantine Initiated")
  GRAVITYZONE_UNINSTALLATION_FAILED_MESSAGE: "GRAVITYZONE_UNINSTALL_FAILED - Failed to uninstall GravityZone", // localizationKey("GRAVITYZONE_UNINSTALL_FAILED - Failed to uninstall GravityZone")
  GRAVITYZONE_UNINSTALLATION_FAILED: "GravityZone uninstall failed", // localizationKey("GravityZone uninstall failed")
  GRAVITYZONE_UNPACKING_FAILED: "GravityZone unpacking failed", // localizationKey("GravityZone unpacking failed")
  HELP_REQUEST_SUBMITTED: "Help request submitted", // localizationKey("Help request submitted")
  HELP_REQUEST: "Help Request", // localizationKey("Help Request")
  IMAGEMANAGER_CONSOLIDATION_FAILED: "ImageManager consolidation failed", // localizationKey("ImageManager consolidation failed")
  IMAGEMANAGER_INSTALL_FAILED: "ImageManager install failed", // localizationKey("ImageManager install failed")
  IMAGEMANAGER_INSTALLED: "ImageManager installed", // localizationKey("ImageManager installed")
  IMAGEMANAGER_LICENSE_ACTIVATED: "ImageManager license activated", // localizationKey("ImageManager license activated")
  IMAGEMANAGER_LICENSE_ACTIVATION_FAILED: "ImageManager license activation failed", // localizationKey("ImageManager license activation failed")
  IMAGEMANAGER_LICENSE_DEACTIVATED: "ImageManager license deactivated", // localizationKey("ImageManager license deactivated")
  IMAGEMANAGER_LICENSE_DEACTIVATION_FAILED: "ImageManager license deactivation failed", // localizationKey("ImageManager license deactivation failed")
  IMAGEMANAGER_LICENSE_PROVISION_FAILED: "ImageManager license provision failed", // localizationKey("ImageManager license provision failed")
  IMAGEMANAGER_LICENSE_PROVISIONED: "ImageManager license provisioned", // localizationKey("ImageManager license provisioned")
  IMAGEMANAGER_UNINSTALL_FAILED: "ImageManager uninstall failed", // localizationKey("ImageManager uninstall failed")
  IMAGEMANAGER_UNINSTALLED: "ImageManager uninstalled", // localizationKey("ImageManager uninstalled")
  IMAGEMANAGER_VERIFICATION_FAILED: "ImageManager verification failed", // localizationKey("ImageManager verification failed")
  IMAGEMANAGER: "ImageManager",
  IN_PROCESS: "In process", // localizationKey("In process")
  INTERNAL: "Internal", // localizationKey("Internal")
  KNOWLEDGE_BASE_DOCUMENT_ARCHIVED: "Knowledge Base Document Archived", // localizationKey("Knowledge Base Document Archived")
  KNOWLEDGE_BASE_DOCUMENT_CREATED: "Knowledge Base Document Created", // localizationKey("Knowledge Base Document Created")
  KNOWLEDGE_BASE_DOCUMENT_DELETED: "Knowledge Base Document Deleted", // localizationKey("Knowledge Base Document Deleted")
  KNOWLEDGE_BASE_DOCUMENT_MOVED: "Knowledge Base Document Moved", //localizationKey("Knowledge Base Document Moved")
  KNOWLEDGE_BASE_DOCUMENT_RESTORED: "Knowledge Base Document Restored", // localizationKey("Knowledge Base Document Restored")
  KNOWLEDGE_BASE_DOCUMENT_UPDATED: "Knowledge Base Document Updated", // localizationKey("Knowledge Base Document Updated")
  KNOWLEDGE_BASE_FOLDER_ACCESS_RESTRICTED: "Knowledge Base Folder Access Restricted", // localizationKey("Knowledge Base Folder Access Restricted")
  KNOWLEDGE_BASE_FOLDER_ARCHIVED: "Knowledge Base Folder Archived", // localizationKey("Knowledge Base Folder Archived")
  KNOWLEDGE_BASE_FOLDER_CREATED: "Knowledge Base Folder Created", // localizationKey("Knowledge Base Folder Created")
  KNOWLEDGE_BASE_FOLDER_DELETED: "Knowledge Base Folder Deleted", // localizationKey("Knowledge Base Folder Deleted")
  KNOWLEDGE_BASE_FOLDER_MOVED: "Knowledge Base Folder Moved", //localizationKey("Knowledge Base Folder Moved")
  KNOWLEDGE_BASE_FOLDER_RESTORED: "Knowledge Base Folder Restored", // localizationKey("Knowledge Base Folder Restored")
  KNOWLEDGE_BASE_FOLDER_SHARED: "Knowledge Base Folder Shared", //localizationKey("Knowledge Base Folder Shared")
  KNOWLEDGE_BASE_FOLDER_UPDATED: "Knowledge Base Folder Updated", // localizationKey("Knowledge Base Folder Updated")
  KNOWLEDGE_BASE_PUBLIC_LINK_CREATED: "Knowledge Base Public Link Created", //localizationKey("Knowledge Base Public Link Created")
  KNOWLEDGE_BASE_PUBLIC_LINK_DELETED: "Knowledge Base Public Link Deleted", //localizationKey("Knowledge Base Public Link Deleted")
  KNOWLEDGE_BASE_PUBLIC_LINK_UPDATED: "Knowledge Base Public Link Updated", //localizationKey("Knowledge Base Public Link Updated")
  KNOWLEDGE_BASE_PUBLIC_TEMPORARY_TOKEN_CREATED: "Knowledge Base Public Temporary Token Created", // localizationKey("Knowledge Base Public Temporary Token Created")
  LANGUAGE_TAG_UPDATED: "Language Updated", // localizationKey("Language Updated")
  LOCATION_CREATED: "Organization Location Created", // localizationKey("Organization Location Created")
  LOCATION_DELETED: "Organization Location Deleted", // localizationKey("Organization Location Deleted")
  LOCATION_UPDATED: "Organization Location Updated", // localizationKey("Organization Location Updated")
  LOCKHART_BACKUP_CONFIGURE_FAILED: "Plan configuration failed", // localizationKey("Plan configuration failed")
  LOCKHART_BACKUP_DOWNLOADED: "Backup downloaded", // localizationKey("Backup downloaded")
  LOCKHART_BACKUP_NAS_ACCESS_FAILED: "Network location access test failed", // localizationKey("Network location access test failed")
  LOCKHART_BACKUP: "Backup",
  LOCKHART_BACKUPJOB_CANCELLED: "Backup job canceled", // localizationKey("Backup job canceled")
  LOCKHART_BACKUPJOB_COMPLETED_WITH_WARNING: "Backup job completed with warning", // localizationKey("Backup job completed with warning")
  LOCKHART_BACKUPJOB_COMPLETED: "Backup job completed", // localizationKey("Backup job completed")
  LOCKHART_BACKUPJOB_FAILED: "Backup job failed", // localizationKey("Backup job failed")
  LOCKHART_BACKUPJOB_IN_PROCESS: "Backup job in process", // localizationKey("Backup job in process")
  LOCKHART_BACKUPJOB_PROCESSING: "Backup job processing", // localizationKey("Backup job processing")
  LOCKHART_BACKUPJOB_START_REQUESTED: "Backup job start requested", // localizationKey("Backup job start requested")
  LOCKHART_BACKUPJOB_STARTED: "Backup job started", // localizationKey("Backup job started")
  LOCKHART_BACKUPPLAN_ADDED: "Backup plan added", // localizationKey("Backup plan added")
  LOCKHART_BACKUPPLAN_CREATION_FAILED: "Backup plan creation failed", // localizationKey("Backup plan creation failed")
  LOCKHART_BACKUPPLAN_DELETED: "Backup plan deleted", // localizationKey("Backup plan deleted")
  LOCKHART_BACKUPPLAN_EDITED: "Backup plan edited", // localizationKey("Backup plan edited")
  LOCKHART_FILE_DOWNLOAD: "File downloaded", // localizationKey("File downloaded")
  LOCKHART_FILES_AND_FOLDERS_DELETED: "Backup file(s) and folder(s) deleted", // localizationKey("Backup file(s) and folder(s) deleted")
  LOCKHART_FILES_DELETED: "Backup file(s) deleted", // localizationKey("Backup file(s) deleted")
  LOCKHART_FOLDER_DOWNLOAD: "Folder downloaded", // localizationKey("Folder downloaded")
  LOCKHART_FOLDERS_DELETED: "Backup folder(s) deleted", // localizationKey("Backup folder(s) deleted")
  LOCKHART_IMAGE_DOWNLOAD_CANCELED: "Image Download Canceled", //localizationKey("Image Download Canceled")
  LOCKHART_IMAGE_DOWNLOAD_COMPLETED: "Image download completed", // localizationKey("Image download completed")
  LOCKHART_IMAGE_DOWNLOAD_FAILED: "Image download failed", // localizationKey("Image download failed")
  LOCKHART_IMAGE_DOWNLOAD_FILE_COMPLETED: "File download completed", // localizationKey("File download completed")
  LOCKHART_IMAGE_DOWNLOAD_FILE_FAILED: "File download failed", // localizationKey("File download failed")
  LOCKHART_IMAGE_DOWNLOAD_FILE_STARTED: "File download started", // localizationKey("File download started")
  LOCKHART_IMAGE_DOWNLOAD_FOLDER_COMPLETED: "Folder download completed", // localizationKey("Folder download completed")
  LOCKHART_IMAGE_DOWNLOAD_FOLDER_FAILED: "Folder download failed", // localizationKey("Folder download failed")
  LOCKHART_IMAGE_DOWNLOAD_FOLDER_STARTED: "Folder download started", // localizationKey("Folder download started")
  LOCKHART_IMAGE_DOWNLOAD_RESUMED: "Image download resumed", // localizationKey("Image download resumed")
  LOCKHART_IMAGE_DOWNLOAD_STARTED: "Image download started", // localizationKey("Image download started")
  LOCKHART_IMAGE_MOUNT_COMPLETED: "Image mount completed", // localizationKey("Image mount completed")
  LOCKHART_IMAGE_MOUNT_FAILED: "Image mount failed", // localizationKey("Image mount failed")
  LOCKHART_IMAGE_RESTORE_CANCELED: "Image Restore Canceled", //localizationKey("Image Restore Canceled")
  LOCKHART_IMAGE_RESTORE_COMPLETED: "Image restore completed", // localizationKey("Image restore completed")
  LOCKHART_IMAGE_RESTORE_FAILED: "Image restore failed", // localizationKey("Image restore failed")
  LOCKHART_IMAGE_RESTORE_KEY_CREATED: "Image Restore Key Created", // localizationKey("Image Restore Key Created")
  LOCKHART_IMAGE_RESTORE_KEY_INVALIDATED: "Image Restore Key Invalidated", // localizationKey("Image Restore Key Invalidated")
  LOCKHART_IMAGE_RESTORE_RESUMED: "Image restore resumed", // localizationKey("Image restore resumed")
  LOCKHART_IMAGE_RESTORE_STARTED: "Image restore started", // localizationKey("Image restore started")
  LOCKHART_INSTALL_FAILED: "Backup install failed", // localizationKey("Backup install failed")
  LOCKHART_INSTALLED: "Backup installed", // localizationKey("Backup installed")
  LOCKHART_INTEGRITY_CHECK_CANCELLED: "Integrity Check Canceled", // localizationKey("Integrity Check Canceled")
  LOCKHART_INTEGRITY_CHECK_COMPLETED: "Integrity Check Completed", // localizationKey("Integrity Check Completed")
  LOCKHART_INTEGRITY_CHECK_FAILED: "Integrity Check Failed", // localizationKey("Integrity Check Failed")
  LOCKHART_INTEGRITY_CHECK_IN_PROCESS: "Integrity Check In Process", // localizationKey("Integrity Check In Process")
  LOCKHART_INTEGRITY_CHECK_START_REQUESTED: "Integrity Check Requested", // localizationKey("Integrity Check Requested")
  LOCKHART_INTEGRITY_CHECK_STARTED: "Integrity Check Started", // localizationKey("Integrity Check Started")
  LOCKHART_INTEGRITYCHECKJOB_FAILED: "Integrity check job failed", // localizationKey("Integrity check job failed")
  LOCKHART_RESTORE: "Restore", // localizationKey("Restore")
  LOCKHART_RESTOREJOB_CANCELLED: "Restore job canceled", // localizationKey("Restore job canceled")
  LOCKHART_RESTOREJOB_COMPLETED: "Restore job completed", // localizationKey("Restore job completed")
  LOCKHART_RESTOREJOB_FAILED: "Restore job failed", // localizationKey("Restore job failed")
  LOCKHART_RESTOREJOB_IN_PROCESS: "Restore job in process", // localizationKey("Restore job in process")
  LOCKHART_RESTOREJOB_START_REQUESTED: "Restore job start requested", // localizationKey("Restore job start requested")
  LOCKHART_RESTOREJOB_STARTED: "Restore job started", // localizationKey("Restore job started")
  LOCKHART_REVISIONS_DELETE_FAILED: "Backup revision delete failed", // localizationKey("Backup revision delete failed")
  LOCKHART_REVISIONS_DELETE_STARTED: "Backup revision delete started", // localizationKey("Backup revision delete started")
  LOCKHART_REVISIONS_DELETED: "Backup revisions deleted", // localizationKey("Backup revisions deleted")
  LOCKHART_REVISIONS_DELETION_CANCELLED: "Backup Data Deletion Cancelled", //localizationKey("Backup Data Deletion Cancelled"),
  LOCKHART_REVISIONS_DELETION_COMPLETED: "Backup Data Deletion Completed", //localizationKey("Backup Data Deletion Completed"),
  LOCKHART_REVISIONS_DELETION_FORCED: "Backup Data Deletion Forced", //localizationKey("Backup Data Deletion Forced"),
  LOCKHART_REVISIONS_DELETION_SCHEDULED: "Backup Data Deletion Scheduled", //localizationKey("Backup Data Deletion Scheduled"),
  LOCKHART_UNINSTALL_FAILED: "Backup uninstall failed", // localizationKey("Backup uninstall failed")
  LOCKHART_UNINSTALLED: "Backup uninstalled", // localizationKey("Backup uninstalled")
  LOCKHART_UPSYNCJOB_CANCELLED: "Sync to cloud canceled", // localizationKey("Sync to cloud canceled")
  LOCKHART_UPSYNCJOB_COMPLETED: "Sync to cloud completed", // localizationKey("Sync to cloud completed")
  LOCKHART_UPSYNCJOB_FAILED: "Sync to cloud failed", // localizationKey("Sync to cloud failed")
  LOCKHART_UPSYNCJOB_IN_PROCESS: "Sync to cloud in process", // localizationKey("Sync to cloud in process")
  LOCKHART_UPSYNCJOB_PROCESSING: "Preparing files to sync to cloud", // localizationKey("Preparing files to sync to cloud")
  LOCKHART_UPSYNCJOB_STARTED: "Sync to cloud started", // localizationKey("Sync to cloud started")
  LOCKHART: "Backup",
  MAC_DAEMON_STARTED: "Mac daemon started", // localizationKey("Mac daemon started")
  MAC_DAEMON_STOPPED: "Mac daemon stopped", // localizationKey("Mac daemon stopped")
  MAINTENANCE_MODE_CONFIGURED: "Maintenance mode configured", // localizationKey("Maintenance mode configured")
  MAINTENANCE_MODE_DELETED: "Maintenance mode deleted", // localizationKey("Maintenance mode deleted")
  MAINTENANCE_MODE_FAILED: "Maintenance mode failed", // localizationKey("Maintenance mode failed")
  MAINTENANCE_MODE_MODIFIED: "Maintenance mode modified", // localizationKey("Maintenance mode modified")
  MDM_ACTION_DEVICE_STATUS_FAILED: "MDM Action Device Status Failed", // localizationKey("MDM Action Device Status Failed")
  MDM_ADE_TOKEN_RENEWED: "MDM ADE Token Renewed", //localizationKey("MDM ADE Token Renewed")
  MDM_ADE_TOKEN_UPLOADED: "MDM ADE Token Uploaded", //localizationKey("MDM ADE Token Uploaded")
  MDM_APN_CERTIFICATE_RENEWED: "MDM APN Certificate Renewed", //localizationKey("MDM APN Certificate Renewed")
  MDM_APN_CERTIFICATE_UPLOADED: "APN Certificate Uploaded", // localizationKey("APN Certificate Uploaded")
  MDM_ASSIGN_POLICY_STATUS_CREATED: "Assign Policy status created", // localizationKey("Assign Policy status created")
  MDM_ASSIGN_POLICY_STATUS_UPDATED: "Assign Policy status updated", // localizationKey("Assign Policy status updated")
  MDM_CLEAR_PASSCODE_STATUS_CREATED: "Clear Passcode status created", // localizationKey("Clear Passcode status created")
  MDM_CLEAR_PASSCODE_STATUS_UPDATED: "Clear Passcode status updated", // localizationKey("Clear Passcode status updated")
  MDM_CONNECTION_ANDROID_ENTERPRISE_RENAME_SUCCESS: "Renaming Android MDM Business Name", //localizationKey("Renaming Android MDM Business Name")
  MDM_CONNECTION_ANDROID_ENTERPRISE_RESET_PROCESS_STARTED: "Request to Reset the Android MDM Integration", //localizationKey("Request to Reset the Android MDM Integration")
  MDM_CONNECTION_APPLE_ADE_RESET_PROCESS_STARTED: "Request to Reset the Apple Automated Device Enrollment (ADE) MDM Integration", //localizationKey("Request to Reset the Apple Automated Device Enrollment (ADE) MDM Integration")
  MDM_CONNECTION_APPLE_APN_RESET_PROCESS_STARTED: "Request to Reset the Apple Push Notification service (APNs) MDM Integration", //localizationKey("Request to Reset the Apple Push Notification service (APNs) MDM Integration")
  MDM_CONNECTION_APPLE_APPS_AND_BOOKS_CONTENT_TOKEN_RENAME_SUCCESS: "MDM Apps and Books Content Token renamed", //localizationKey("MDM Apps and Books Content Token renamed")
  MDM_CONNECTION_APPLE_APPS_AND_BOOKS_CONTENT_TOKEN_RESET: "MDM Apps and Books Content Token deleted", //localizationKey("MDM Apps and Books Content Token deleted")
  MDM_CONNECTION_RESET_ANDROID_ENTERPRISE_PROCESS_UPDATED: "Resetting the Android MDM Integration Completed", //localizationKey("Resetting the Android MDM Integration Completed")
  MDM_CONNECTION_RESET_APPLE_ADE_PROCESS_UPDATED: "Resetting the Apple ADE Integration Completed", //localizationKey("Resetting the Apple ADE Integration Completed")
  MDM_CONNECTION_RESET_APPLE_APN_PROCESS_UPDATED: "Resetting the Apple MDM Integration Completed", //localizationKey("Resetting the Apple MDM Integration Completed")
  MDM_DEVICE_CLEAR_LOCATION_DATA_COMPLETED: "MDM Clear Location Data of Device Completed", //localizationKey("MDM Clear Location Data of Device Completed")
  MDM_DEVICE_LATEST_LOCATION_REQUESTED: "MDM Latest Location of Device Requested", //localizationKey("MDM Latest Location of Device Requested")
  MDM_DISOWN_STATUS_COMPLETED: "MDM Disown Status Completed", //localizationKey("MDM Disown Status Completed")
  MDM_DISOWN_STATUS_FORBIDDEN: "MDM Disown Status Forbidden", //localizationKey("MDM Disown Status Forbidden")
  MDM_DIVISION_CONFIG_DISABLED: "Division Config disabled", // localizationKey("Division Config disabled")
  MDM_DIVISION_CONFIG_ENABLED: "Division Config enabled", // localizationKey("Division Config enabled")
  MDM_DIVISION_CONFIG_UPDATED: "Division Config updated", // localizationKey("Division Config updated")
  MDM_ERASE_STATUS_CREATED: "Erase status created", // localizationKey("Erase status created")
  MDM_ERASE_STATUS_UPDATED: "Erase status updated", // localizationKey("Erase status updated")
  MDM_INSTALL_APP_STATUS_CREATED: "MDM Install Application Action", //localizationKey("MDM Install Application Action")
  MDM_LOCK_DEVICE_STATUS_CREATED: "Lock device status created", // localizationKey("Lock device status created")
  MDM_LOCK_DEVICE_STATUS_UPDATED: "Lock device status updated", // localizationKey("Lock device status updated")
  MDM_PATCH_POLICY_UPDATE: "MDM OS Update Enforcement", // localizationKey("MDM OS Update Enforcement")
  MDM_REBOOT_DEVICE_STATUS_CREATED: "Reboot device status created", // localizationKey("Reboot device status created")
  MDM_REBOOT_DEVICE_STATUS_UPDATED: "Reboot device status updated", // localizationKey("Reboot device status updated")
  MDM_RELINQUISH_OWNERSHIP_DEVICE_STATUS_CREATED: "Relinquish Ownership device status created", // localizationKey("Relinquish Ownership device status created")
  MDM_RELINQUISH_OWNERSHIP_DEVICE_STATUS_UPDATED: "Relinquish Ownership device status updated", // localizationKey("Relinquish Ownership device status updated")
  MDM_RENEW_ENROLLMENT_PROFILE_STATUS_CREATED: "MDM Renew Enrollment Profile Action Created", //localizationKey("MDM Renew Enrollment Profile Action Created")
  MDM_RENEW_ENROLLMENT_PROFILE_STATUS_UPDATED: "MDM Renew Enrollment Profile Action Updated", //localizationKey("MDM Renew Enrollment Profile Action Updated")
  MDM_RESET_PASSCODE_DEVICE_STATUS_CREATED: "Reset Passcode device status created", // localizationKey("Reset Passcode device status created")
  MDM_RESET_PASSCODE_DEVICE_STATUS_UPDATED: "Reset Passcode device status updated", // localizationKey("Reset Passcode device status updated")
  MDM_SYNC_POLICY_STATUS_CREATED: "MDM Sync Policy On Device Event Sent", //localizationKey("MDM Sync Policy On Device Event Sent")
  MDM_UPDATE_POLICY_STATUS_CREATED: "Update Policy status created", // localizationKey("Update Policy status created")
  MDM_UPDATE_POLICY_STATUS_UPDATED: "Update Policy status updated", // localizationKey("Update Policy status updated")
  MDM_VPP_CONTENT_TOKEN_FAILURE_NOTIFICATION: "Unable to Install app", //localizationKey("Unable to Install app")
  MDM_VPP_CONTENT_TOKEN_RENEWED: "MDM Apps and Books Content Token Renewed", // localizationKey("MDM Apps and Books Content Token Renewed")
  MDM_VPP_CONTENT_TOKEN_UPLOADED: "MDM Apps and Books Content Token Uploaded", //localizationKey("MDM Apps and Books Content Token Uploaded")
  MEMORY_ADDED: "Memory added", // localizationKey("Memory added")
  MEMORY_COMPONENT_ADDED: "Memory Component Added", // localizationKey("Memory Component Added")
  MEMORY_COMPONENT_REMOVED: "Memory Component Removed", // localizationKey("Memory Component Removed")
  MEMORY_REMOVED: "Memory removed", // localizationKey("Memory removed")
  MOBILE_DEVICE_UNREGISTERED: "Mobile Device Unregistered", // localizationKey("Mobile Device Unregistered")
  MONITOR: "Monitor", // localizationKey("Monitor")
  NC_CONFIG_CHANGED: "Ninja Remote Global Settings changed.", //localizationKey("Ninja Remote Global Settings changed.")
  NC_DISABLED: "NC disabled", // localizationKey("NC disabled")
  NC_SESSION_FAILED_TO_START: "NC session failed to start", // localizationKey("NC session failed to start")
  NC_SESSION_REQUESTED: "NC session requested", // localizationKey("NC session requested")
  NC_SESSION_SESSION_TERMINATION_REQUESTED: "NC session termination requested", // localizationKey("NC session termination requested")
  NC_SESSION_STARTED: "NC session started", // localizationKey("NC session started")
  NC_SESSION_TERMINATED: "NC session terminated", // localizationKey("NC session terminated")
  NINJA_PSA_AGREEMENT_ACTIVATED: "NinjaOne PSA Agreement activated", // localizationKey("NinjaOne PSA Agreement activated")
  NINJA_PSA_AGREEMENT_CREATED: "NinjaOne PSA Agreement created", // localizationKey("NinjaOne PSA Agreement created")
  NINJA_PSA_AGREEMENT_DEACTIVATED: "NinjaOne PSA Agreement deactivated", // localizationKey("NinjaOne PSA Agreement deactivated")
  NINJA_PSA_AGREEMENT_DELETED: "Agreement Deleted", // localizationKey("Agreement Deleted")
  NINJA_PSA_AGREEMENT_SET_TIME_ENTRY_DEFAULT_TO_NONE: "NinjaOne PSA Set time entry default agreement to none", // localizationKey("NinjaOne PSA Set time entry default agreement to none")
  NINJA_PSA_AGREEMENT_SET_TIME_ENTRY_DEFAULT: "NinjaOne PSA Set time entry default agreement", // localizationKey("NinjaOne PSA Set time entry default agreement")
  NINJA_PSA_AGREEMENT_TEMPLATE_ACTIVATED: "NinjaOne PSA Agreement template activated", // localizationKey("NinjaOne PSA Agreement template activated")
  NINJA_PSA_AGREEMENT_TEMPLATE_CREATED: "NinjaOne PSA Agreement template created", // localizationKey("NinjaOne PSA Agreement template created")
  NINJA_PSA_AGREEMENT_TEMPLATE_DEACTIVATED: "NinjaOne PSA Agreement template deactivated", // localizationKey("NinjaOne PSA Agreement template deactivated")
  NINJA_PSA_AGREEMENT_TEMPLATE_UPDATED: "NinjaOne PSA Agreement template updated", // localizationKey("NinjaOne PSA Agreement template updated")
  NINJA_PSA_AGREEMENT_TEMPLATES_DELETED: "NinjaOne PSA Agreement templates deleted", //localizationKey("NinjaOne PSA Agreement templates deleted")
  NINJA_PSA_AGREEMENT_UPDATED: "NinjaOne PSA Agreement updated", // localizationKey("NinjaOne PSA Agreement updated")
  NINJA_PSA_CLIENT_MAPPING_CREATED: "NinjaOne PSA Client mapping created", // localizationKey("NinjaOne PSA Client mapping created")
  NINJA_PSA_CLIENT_MAPPING_DELETED: "NinjaOne PSA Client mapping deleted", // localizationKey("NinjaOne PSA Client mapping deleted")
  NINJA_PSA_CLIENT_MAPPING_UPDATED: "NinjaOne PSA Client mapping updated", // localizationKey("NinjaOne PSA Client mapping updated")
  NINJA_PSA_DISABLED: "NinjaOne PSA Disabled", // localizationKey("NinjaOne PSA Disabled")
  NINJA_PSA_ENABLED: "NinjaOne PSA Enabled", // localizationKey("NinjaOne PSA Enabled")
  NINJA_PSA_INTEGRATION_CREATED: "NinjaOne PSA Integration created", // localizationKey("NinjaOne PSA Integration created")
  NINJA_PSA_INTEGRATION_DEACTIVATED: "NinjaOne PSA Integration deactivated by the user", // localizationKey("NinjaOne PSA Integration deactivated by the user")
  NINJA_PSA_INTEGRATION_DELETED: "NinjaOne PSA Integration deleted", // localizationKey("NinjaOne PSA Integration deleted")
  NINJA_PSA_INTEGRATION_UPDATED: "NinjaOne PSA Integration updated by the user", // localizationKey("NinjaOne PSA Integration updated by the user")
  NINJA_PSA_INVOICE_APPROVED: "NinjaOne PSA Invoice approved", // localizationKey("NinjaOne PSA Invoice approved")
  NINJA_PSA_INVOICE_ARCHIVED: "Invoices archived", // localizationKey("Invoices archived")
  NINJA_PSA_INVOICE_CREATED_BY_TRIGGER: "NinjaOne PSA Invoice automatically generated", // localizationKey("NinjaOne PSA Invoice automatically generated")
  NINJA_PSA_INVOICE_CREATED: "NinjaOne PSA Invoice created by user", // localizationKey("NinjaOne PSA Invoice created by user")
  NINJA_PSA_INVOICE_ERROR_EXPORTING_TO_QB: "NinjaOne PSA Error exporting invoice to QuickBooks by the user", // localizationKey("Error exporting invoice to QuickBooks by the user")
  NINJA_PSA_INVOICE_EXPORTED_TO_QB: "NinjaOne PSA Invoice exported to QuickBooks by user", // localizationKey("NinjaOne PSA Invoice exported to QuickBooks by user")
  NINJA_PSA_INVOICE_STATUS_RESET: "NinjaOne PSA Invoices reset", //localizationKey("NinjaOne PSA Invoices reset")
  NINJA_PSA_INVOICE_TEMPLATE_UPDATED: "NinjaOne PSA Invoice template updated", //localizationKey("NinjaOne PSA Invoice template updated")
  NINJA_PSA_INVOICE_UPDATED: "NinjaOne PSA Invoice updated", // localizationKey("NinjaOne PSA Invoice updated")
  NINJA_PSA_PRODUCT_ACTIVATED: "NinjaOne PSA Product activated", // localizationKey("NinjaOne PSA Product activated")
  NINJA_PSA_INVOICE_UNARCHIVED: "Invoices unarchived", // localizationKey("Invoices unarchived")
  NINJA_PSA_PRODUCT_CREATED: "NinjaOne PSA Product created", // localizationKey("NinjaOne PSA Product created")
  NINJA_PSA_PRODUCT_DEACTIVATED: "NinjaOne PSA Product deactivated", // localizationKey("NinjaOne PSA Product deactivated")
  NINJA_PSA_PRODUCT_MAPPING_CREATED: "NinjaOne PSA Product mapping created", // localizationKey("NinjaOne PSA Product mapping created")
  NINJA_PSA_PRODUCT_MAPPING_DELETED: "NinjaOne PSA Product mapping deleted", // localizationKey("NinjaOne PSA Product mapping deleted")
  NINJA_PSA_PRODUCT_MAPPING_UPDATED: "NinjaOne PSA Product mapping updated", // localizationKey("NinjaOne PSA Product mapping updated")
  NINJA_PSA_PRODUCT_TYPE_MAPPING_CREATED: "NinjaOne PSA Product type mapping created", // localizationKey("NinjaOne PSA Product type mapping created")
  NINJA_PSA_PRODUCT_TYPE_MAPPING_DELETED: "NinjaOne PSA Product type mapping deleted", // localizationKey("NinjaOne PSA Product type mapping deleted")
  NINJA_PSA_PRODUCT_TYPE_MAPPING_UPDATED: "NinjaOne PSA Product type mapping updated", // localizationKey("NinjaOne PSA Product type mapping updated")
  NINJA_PSA_PRODUCT_UPDATED: "NinjaOne PSA Product updated", // localizationKey("NinjaOne PSA Product updated")
  NINJA_PSA_TAX_RATE_CREATED: "NinjaOne PSA Tax rate created", // localizationKey("NinjaOne PSA Tax rate created")
  NINJA_PSA_TAX_RATE_DELETED: "NinjaOne PSA Tax rate deleted", // localizationKey("NinjaOne PSA Tax rate deleted")
  NINJA_PSA_TAX_RATE_MAPPING_CREATED: "NinjaOne PSA Tax rate mapping created", // localizationKey("NinjaOne PSA Tax rate mapping created")
  NINJA_PSA_TAX_RATE_MAPPING_DELETED: "NinjaOne PSA Tax rate mapping deleted", // localizationKey("NinjaOne PSA Tax rate mapping deleted")
  NINJA_PSA_TAX_RATE_MAPPING_UPDATED: "NinjaOne PSA Tax rate mapping updated", // localizationKey("NinjaOne PSA Tax rate mapping updated")
  NINJA_PSA_TAX_RATE_UPDATED: "NinjaOne PSA Tax rate updated", // localizationKey("NinjaOne PSA Tax rate updated")
  NINJA_PSA_TICKET_TIME_ENTRIES_PRICES_RECALCULATED: "NinjaOne PSA Ticket time entries prices were recalculated", //localizationKey("NinjaOne PSA Ticket time entries prices were recalculated")
  NINJA_PSA_TICKET_TIME_ENTRIES_UPDATED: "NinjaOne PSA Ticket time entries updated", //localizationKey("NinjaOne PSA Ticket time entries updated")
  NINJA_TICKETING_ACTIVITY_RULE_MAKE_DEFAULT: "Ticketing Activity Automation Rule Default Changed", // localizationKey("Ticketing Activity Automation Rule Default Changed")
  NINJA_TICKETING_AGREEMENT_BULK_UPDATED: "Agreement updated for selected tickets", //localizationKey("Agreement updated for selected tickets")
  NINJA_TICKETING_ATTRIBUTE_ACTIVED: "Ticket Field Activated", // localizationKey("Ticket Field Activated")
  NINJA_TICKETING_ATTRIBUTE_CREATED: "Ticket Field Created", // localizationKey("Ticket Field Created")
  NINJA_TICKETING_ATTRIBUTE_DEACTIVATED: "Ticket Field Deactivated", // localizationKey("Ticket Field Deactivated")
  NINJA_TICKETING_ATTRIBUTE_UPDATED: "Ticket Field Updated", // localizationKey("Ticket Field Updated")
  NINJA_TICKETING_CONDITION_RULE_MAKE_DEFAULT: "Ticketing Condition Automation Rule Default Changed", // localizationKey("Ticketing Condition Automation Rule Default Changed")
  NINJA_TICKETING_CREATION_FAILED: "Ninja ticketing creation failed", // localizationKey("Ninja ticketing creation failed")
  NINJA_TICKETING_DISABLED: "Ticketing Disabled", // localizationKey("Ticketing Disabled")
  NINJA_TICKETING_EMAIL_ADDRESS_CONFIG_CREATED: "Ticketing Email Address Created", // localizationKey("Ticketing Email Address Created")
  NINJA_TICKETING_EMAIL_ADDRESS_CONFIG_DELETED: "Ticketing Email Address Deleted", // localizationKey("Ticketing Email Address Deleted")
  NINJA_TICKETING_EMAIL_ADDRESS_CONFIG_MAKE_DEFAULT: "Ticketing Email Address Default", // localizationKey("Ticketing Email Address Default")
  NINJA_TICKETING_EMAIL_ADDRESS_CONFIG_UPDATED: "Ticketing Email Address Updated", // localizationKey("Ticketing Email Address Updated")
  NINJA_TICKETING_EMAIL_ALLOWLIST_ITEM_CREATED: "Ninja Ticketing Allowlist Item Created", // localizationKey("Ninja Ticketing Allowlist Item Created")
  NINJA_TICKETING_EMAIL_ALLOWLIST_ITEM_DELETED: "Ninja Ticketing Allowlist Item Deleted", // localizationKey("Ninja Ticketing Allowlist Item Deleted")
  NINJA_TICKETING_EMAIL_ALLOWLIST_ITEM_UPDATED: "Ninja Ticketing Allowlist Item Updated", // localizationKey("Ninja Ticketing Allowlist Item Updated")
  NINJA_TICKETING_EMAIL_BLOCKLIST_ITEM_CREATED: "Ninja Ticketing Blocklist Item Created", // localizationKey("Ninja Ticketing Blocklist Item Created")
  NINJA_TICKETING_EMAIL_BLOCKLIST_ITEM_DELETED: "Ninja Ticketing Blocklist Item Deleted", // localizationKey("Ninja Ticketing Blocklist Item Deleted")
  NINJA_TICKETING_EMAIL_BLOCKLIST_ITEM_UPDATED: "Ninja Ticketing Blocklist Item Updated", // localizationKey("Ninja Ticketing Blocklist Item Updated")
  NINJA_TICKETING_ENABLED: "Ticketing Enabled", // localizationKey("Ticketing Enabled")
  NINJA_TICKETING_FORM_ACTIVED: "Ticket Form Activated", // localizationKey("Ticket Form Activated")
  NINJA_TICKETING_FORM_CREATED: "Ticket Form Created", // localizationKey("Ticket Form Created")
  NINJA_TICKETING_FORM_DEACTIVED: "Ticket Form Deactivated", // localizationKey("Ticket Form Deactivated")
  NINJA_TICKETING_FORM_MAKE_DEFAULT: "Ticketing Form Default Changed", // localizationKey("Ticketing Form Default Changed")
  NINJA_TICKETING_FORM_UPDATED: "Ticket Form Updated", // localizationKey("Ticket Form Updated")
  NINJA_TICKETING_PENDING_EMAIL_CREATION_APPROVED: "Pending Email Creation Approved", // localizationKey("Pending Email Creation Approved")
  NINJA_TICKETING_PENDING_EMAIL_RECEIVED: "Ninja Ticketing Pending Email Received", // localizationKey("Ninja Ticketing Pending Email Received")
  NINJA_TICKETING_PENDING_EMAIL_REJECTED: "Pending Email Rejection", // localizationKey("Pending Email Rejection")
  NINJA_TICKETING_PENDING_EMAIL_UPDATE_APPROVED: "Pending Email Update Approved", // localizationKey("Pending Email Update Approved")
  NINJA_TICKETING_RULESET_CREATED: "Ticket Condition Automation Rule Created", // localizationKey("Ticket Condition Automation Rule Created")
  NINJA_TICKETING_RULESET_DELETED: "Ticket Condition Automation Rule Deleted", // localizationKey("Ticket Condition Automation Rule Deleted")
  NINJA_TICKETING_RULESET_UPDATED: "Ticket Condition Automation Rule Updated", // localizationKey("Ticket Condition Automation Rule Updated")
  NINJA_TICKETING_SCRIPT_RULE_MAKE_DEFAULT: "Ticketing Script Automation Rule Default Changed", // localizationKey("Ticketing Script Automation Rule Default Changed")
  NINJA_TICKETING_STATUS_CREATED: "Ticket status created", // localizationKey("Ticket status created")
  NINJA_TICKETING_STATUS_DELETED: "Ticket status deleted", // localizationKey("Ticket status deleted")
  NINJA_TICKETING_STATUS_UPDATED: "Ticket status updated", // localizationKey("Ticket status updated")
  NINJA_TICKETING_TICKET_CREATED: "Ticket Created", // localizationKey("Ticket Created")
  NINJA_TICKETING_TICKET_DELETED: "Ticket Deleted", // localizationKey("Ticket Deleted")
  NINJA_TICKETING_TICKET_REINSTATED: "Ninja Ticketing Ticket Reinstated", // localizationKey("Ninja Ticketing Ticket Reinstated")
  NINJA_TICKETING_TRIGGER_CREATED: "Ticketing Automation Created", // localizationKey("Ticketing Automation Created")
  NINJA_TICKETING_TRIGGER_DELETED: "Ticketing Automation Deleted", // localizationKey("Ticketing Automation Deleted")
  NINJA_TICKETING_TRIGGER_UPDATED: "Ticketing Automation Updated", // localizationKey("Ticketing Automation Updated")
  NINJA_TICKETING_UPDATED: "Ticketing Config Updated", // localizationKey("Ticketing Config Updated")
  NINJACONNECT_SESSION_ALLOWED: "Ninja Remote Session Allowed", // localizationKey("Ninja Remote Session Allowed")
  NINJACONNECT_SESSION_CANCELED: "Ninja Remote Session Canceled", // localizationKey("Ninja Remote Session Canceled")
  NINJACONNECT_SESSION_DENIED: "Ninja Remote Session Denied", // localizationKey("Ninja Remote Session Denied")
  NINJACONNECT_SESSION_ESTABLISHED: "Ninja Remote Session Established", // localizationKey("Ninja Remote Session Established")
  NINJACONNECT_SESSION_TERMINATED: "Ninja Remote Session Terminated", // localizationKey("Ninja Remote Session Terminated")
  NODE_ACCESS_DENIED: "Access Denied", // localizationKey("Access Denied")
  NODE_ACCESS_GRANTED: "Access Granted", // localizationKey("Access Granted")
  NODE_ATTRIBUTE_CREATED: "Device Custom Field Created", // localizationKey("Device Custom Field Created")
  NODE_ATTRIBUTE_DELETED: "Device Custom Field Deleted", // localizationKey("Device Custom Field Deleted")
  NODE_ATTRIBUTE_TEMPORARY_PUBLIC_TOKEN_CREATED: "Device Custom Field Temporary Public Token Created", //localizationKey("Device Custom Field Temporary Public Token Created")
  NODE_ATTRIBUTE_UPDATED: "Device Custom Field Updated", // localizationKey("Device Custom Field Updated")
  NODE_ATTRIBUTE_VALUE_DECRYPTED: "Device Custom Field Value Decrypted", // localizationKey("Device Custom Field Value Decrypted")
  NODE_ATTRIBUTE_VALUE_UPDATED: "Device Custom Field Value Updated", // localizationKey("Device Custom Field Value Updated")
  NODE_AUTOMATICALLY_APPROVED: "Device Automatically Approved", // localizationKey("Device Automatically Approved")
  NODE_AUTOMATICALLY_REJECTED: "Device Automatically Rejected", // localizationKey("Device Automatically Rejected")
  NODE_CACHE_SERVER_CONFIG_ERROR: "Node Cache Server Config Error", // localizationKey("Node Cache Server Config Error")
  NODE_CACHE_SERVER_CREATED: "Node Cache Server Created", // localizationKey("Node Cache Server Created")
  NODE_CACHE_SERVER_DELETED: "Node Cache Server Deleted", // localizationKey("Node Cache Server Deleted")
  NODE_CACHE_SERVER_DEPLOYED_SUCCESSFULLY: "Node Cache Server Deployed Successfully", // localizationKey("Node Cache Server Deployed Successfully")
  NODE_CACHE_SERVER_DEPLOYMENT_FAILED: "Node Cache Server Deployment Failed", // localizationKey("Node Cache Server Deployment Failed")
  NODE_CACHE_SERVER_REMOVAL_FAILED: "Node Cache Server Removal Failed", // localizationKey("Node Cache Server Removal Failed")
  NODE_CACHE_SERVER_REMOVED_SUCCESSFULLY: "Node Cache Server Removed Successfully", // localizationKey("Node Cache Server Removed Successfully")
  NODE_CACHE_SERVER_SETTINGS_CHANGED: "Node Cache Server Settings Changed", // localizationKey("Node Cache Server Settings Changed")
  NODE_CACHE_SERVER_UNRESPONSIVE: "Node Cache Server Unresponsive", // localizationKey("Node Cache Server Unresponsive")
  NODE_CACHE_SETTINGS_AT_DEVICE_LEVEL_EDITED: "Node Cache Settings Edited at Device Level", // localizationKey("Node Cache Settings Edited at Device Level")
  NODE_CACHE_SETTINGS_AT_DEVICE_LEVEL_REVERTED: "Caching Node Overrides at Device Level Reverted", // localizationKey("Caching Node Overrides at Device Level Reverted")
  NODE_CLONE_ADVISED_TO_REGISTER: "Clone identified", // localizationKey("Clone identified")
  NODE_CLONING_DETECTED: "Cloning detected", // localizationKey("Cloning detected")
  NODE_CREATED: "Device Created", // localizationKey("Device Created")
  NODE_DELETED: "Device Deleted", // localizationKey("Device Deleted")
  NODE_IDENTIFICATION_UPDATED: "Device identification updated", // localizationKey("Device identification updated")
  NODE_MANUALLY_APPROVED: "Device Manually Approved", // localizationKey("Device Manually Approved")
  NODE_MANUALLY_REJECTED: "Device Manually Rejected", // localizationKey("Device Manually Rejected")
  NODE_RE_ENROLLED: "Device re-enrolled", // localizationKey("Device re-enrolled")
  NODE_REGISTRATION_REJECTED: "Device Auto Rejected", // localizationKey("Device Auto Rejected")
  NODE_ROLE_CREATED: "Device Type Created", // localizationKey("Device Type Created")
  NODE_ROLE_DELETED: "Device Type Deleted", // localizationKey("Device Type Deleted")
  NODE_ROLE_UPDATED: "Device Type Updated", // localizationKey("Device Type Updated")
  NODE_TERMINAL_ACCESS_REQUESTED: "Device Terminal Access Requested", //localizationKey("Device Terminal Access Requested")
  NODE_UPDATED: "Device Updated", // localizationKey("Device Updated")
  PARTITION_ADDED: "Partition Added", // localizationKey("Partition Added")
  PARTITION_REMOVED: "Partition Removed", // localizationKey("Partition Removed")
  PATCH_MANAGEMENT_APPLY_PATCH_COMPLETED_LINUX: "Linux Patching Apply Patch Complete", // localizationKey("Linux Patching Apply Patch Complete")
  PATCH_MANAGEMENT_APPLY_PATCH_COMPLETED_MAC: "macOS Patching Apply Patch Complete", // localizationKey("macOS Patching Apply Patch Complete")
  PATCH_MANAGEMENT_APPLY_PATCH_COMPLETED_WINDOWS: "Windows Patching Apply Patch Complete", // localizationKey("Windows Patching Apply Patch Complete")
  PATCH_MANAGEMENT_APPLY_PATCH_COMPLETED: "Patch management apply patch completed", // localizationKey("Patch management apply patch completed")
  PATCH_MANAGEMENT_APPLY_PATCH_STARTED_LINUX: "Linux Patching Apply Patch Started", // localizationKey("Linux Patching Apply Patch Started")
  PATCH_MANAGEMENT_APPLY_PATCH_STARTED_MAC: "macOS Patching Apply Patch Started", // localizationKey("macOS Patching Apply Patch Started")
  PATCH_MANAGEMENT_APPLY_PATCH_STARTED_WINDOWS: "Windows Patching Apply Patch Started", // localizationKey("Windows Patching Apply Patch Started")
  PATCH_MANAGEMENT_APPLY_PATCH_STARTED: "Patch management apply patch started", // localizationKey("Patch management apply patch started")
  PATCH_MANAGEMENT_FAILURE_LINUX: "Linux Patching Failure", // localizationKey("Linux Patching Failure")
  PATCH_MANAGEMENT_FAILURE_MAC: "macOS Patching Failure", // localizationKey("macOS Patching Failure")
  PATCH_MANAGEMENT_FAILURE_WINDOWS: "Windows Patching Failure", // localizationKey("Windows Patching Failure")
  PATCH_MANAGEMENT_FAILURE: "Patch Management Failure", // localizationKey("Patch Management Failure")
  PATCH_MANAGEMENT_INSTALL_FAILED_LINUX: "Linux Patching Install Failed", // localizationKey("Linux Patching Install Failed")
  PATCH_MANAGEMENT_INSTALL_FAILED_MAC: "macOS Patching Install Failed", // localizationKey("macOS Patching Install Failed")
  PATCH_MANAGEMENT_INSTALL_FAILED_WINDOWS: "Windows Patching Install Failed", // localizationKey("Windows Patching Install Failed")
  PATCH_MANAGEMENT_INSTALL_FAILED: "Patch management install failed", // localizationKey("Patch management install failed")
  PATCH_MANAGEMENT_INSTALLED_LINUX: "Linux Patching Installed", // localizationKey("Linux Patching Installed")
  PATCH_MANAGEMENT_INSTALLED_MAC: "macOS Patching Installed", // localizationKey("macOS Patching Installed")
  PATCH_MANAGEMENT_INSTALLED_WINDOWS: "Windows Patching Installed", // localizationKey("Windows Patching Installed")
  PATCH_MANAGEMENT_INSTALLED: "Patch management installed", // localizationKey("Patch management installed")
  PATCH_MANAGEMENT_MESSAGE_LINUX: "Linux Patching Warning", // localizationKey("Linux Patching Warning")
  PATCH_MANAGEMENT_MESSAGE_MAC: "macOS Patching Warning", // localizationKey("macOS Patching Warning")
  PATCH_MANAGEMENT_MESSAGE_WINDOWS: "Windows Patching Warning", // localizationKey("Windows Patching Warning")
  PATCH_MANAGEMENT_MESSAGE: "Patch management message", // localizationKey("Patch management message")
  PATCH_MANAGEMENT_PATCH_APPROVED: "Patch Override Approvals Changed", // localizationKey("Patch Override Approvals Changed")
  PATCH_MANAGEMENT_PATCH_REJECTED: "Patch Override Rejections Changed", // localizationKey("Patch Override Rejections Changed")
  PATCH_MANAGEMENT_ROLLBACK_PATCH_COMPLETED: "OS Patching Rollback Patch Completed", // localizationKey("OS Patching Rollback Patch Completed")
  PATCH_MANAGEMENT_ROLLBACK_PATCH_REQUESTED: "OS Patching Rollback Patch Requested", // localizationKey("OS Patching Rollback Patch Requested")
  PATCH_MANAGEMENT_ROLLBACK_PATCH_STARTED: "OS Patching Rollback Patch Started", // localizationKey("OS Patching Rollback Patch Started")
  PATCH_MANAGEMENT_SCAN_COMPLETED_LINUX: "Linux Patching Scan Complete", // localizationKey("Linux Patching Scan Complete")
  PATCH_MANAGEMENT_SCAN_COMPLETED_MAC: "macOS Patching Scan Complete", // localizationKey("macOS Patching Scan Complete")
  PATCH_MANAGEMENT_SCAN_COMPLETED_WINDOWS: "Windows Patching Scan Complete", // localizationKey("Windows Patching Scan Complete")
  PATCH_MANAGEMENT_SCAN_COMPLETED: "Patch management scan completed", // localizationKey("Patch management scan completed")
  PATCH_MANAGEMENT_SCAN_STARTED_LINUX: "Linux Patching Scan Started", // localizationKey("Linux Patching Scan Started")
  PATCH_MANAGEMENT_SCAN_STARTED_MAC: "macOS Patching Scan Started", // localizationKey("macOS Patching Scan Started")
  PATCH_MANAGEMENT_SCAN_STARTED_WINDOWS: "Windows Patching Scan Started", // localizationKey("Windows Patching Scan Started")
  PATCH_MANAGEMENT_SCAN_STARTED: "Patch management scan started", // localizationKey("Patch management scan started")
  PATCH_MANAGEMENT: "Patch Management", // localizationKey("Patch Management")
  POLICY_CREATED: "Policy Created", // localizationKey("Policy Created")
  POLICY_DELETED: "Policy Deleted", // localizationKey("Policy Deleted")
  POLICY_UPDATED: "Policy Updated", // localizationKey("Policy Updated")
  PORT_CLOSED: "Port closed", // localizationKey("Port closed")
  PORT_OPEN: "Port open", // localizationKey("Port open")
  PORT_OPENED: "Port opened", // localizationKey("Port opened")
  PROCESS_STARTED: "Process started", // localizationKey("Process started")
  PROCESS_STOPPED: "Process stopped", // localizationKey("Process stopped")
  PSA_CREDENTIALS_FAILED: "ITSM/PSA integration credentials failed", // localizationKey("ITSM/PSA integration credentials failed")
  PSA_DISABLED: "ITSM/PSA integration disabled", // localizationKey("ITSM/PSA integration disabled")
  PSA_ENABLED: "ITSM/PSA integration enabled", // localizationKey("ITSM/PSA integration enabled")
  PSA_STATUS_DEGRADED: "ITSM/PSA integration status is degraded", // localizationKey("ITSM/PSA integration status is degraded")
  PSA_STATUS_HEALTHY: "ITSM/PSA integration status is healthy", //localizationKey("ITSM/PSA integration status is healthy")
  PSA_STATUS_UNHEALTHY: "ITSM/PSA integration status is unhealthy", //localizationKey("ITSM/PSA integration status is unhealthy")
  PSA_TICKET_CREATION_FAILED: "ITSM/PSA integration ticket creation failed", // localizationKey("ITSM/PSA integration ticket creation failed")
  PSA_TICKET_CREATION_SUCCEEDED: "ITSM/PSA integration ticket creation succeeded", // localizationKey("ITSM/PSA integration ticket creation succeeded")
  PSA_TICKET_CREATION_TEST: "ITSM/PSA integration ticket creation tested", // localizationKey("ITSM/PSA integration ticket creation tested")
  PSA_TICKET_UPDATED_FAILED: "ITSM/PSA integration ticket update failed", //localizationKey("ITSM/PSA integration ticket update failed")
  PSA_TICKET_UPDATED_SUCCEEDED: "ITSM/PSA integration ticket update succeeded", //localizationKey("ITSM/PSA integration ticket update succeeded")
  QC_CONFIG_CHANGED: "Quick Connect config settings changed", // localizationKey("Quick Connect config settings changed")
  QC_DEVICE_AGENT_INSTALL_REQUESTED: "Ninja Quick Connect Agent Install Requested", // localizationKey("Ninja Quick Connect Agent Install Requested")
  QC_DEVICE_DELETED: "Ninja Quick Connect Device Deleted", // localizationKey("Ninja Quick Connect Device Deleted")
  QC_EXPIRED_INVITATION_BLOCKED: "Ninja Quick Connect Invitation Blocked", // localizationKey("Ninja Quick Connect Invitation Blocked")
  QC_INVITATION_CREATED: "Ninja Quick Connect Invitation Created", // localizationKey("Ninja Quick Connect Invitation Created")
  QC_INVITATION_DISABLED: "Ninja Quick Connect Invitation Disabled", // localizationKey("Ninja Quick Connect Invitation Disabled")
  QC_INVITATION_UPDATED: "Ninja Quick Connect Invitation Updated", // localizationKey("Ninja Quick Connect Invitation Updated")
  QC_SESSION_REPORT_SUBMITTED: "Ninja Quick Connect session report submitted", //localizationKey("Ninja Quick Connect session report submitted")
  QC_SESSION_STARTED: "Quick Connect Session Started", //localizationKey("Quick Connect Session Started")
  QC_SESSION_TERMINATED: "Quick Connect Session Terminated", // //localizationKey("Quick Connect Session Terminated")
  RAID_CONTROLLER_ADDED: "RAID controller added", // localizationKey("RAID controller added")
  RAID_CONTROLLER_REMOVED: "RAID controller removed", // localizationKey("RAID controller removed")
  RAID_LOGICAL_DISK_ADDED: "RAID logical disk added", // localizationKey("RAID logical disk added")
  RAID_LOGICAL_DISK_REMOVED: "RAID logical disk removed", // localizationKey("RAID logical disk removed")
  RAID_PHYSICAL_DRIVE_ADDED: "RAID physical drive added", // localizationKey("RAID physical drive added")
  RAID_PHYSICAL_DRIVE_REMOVED: "RAID physical drive removed", // localizationKey("RAID physical drive removed")
  RDP_AUTO_PROVISION: "Remote Desktop provisioned", // localizationKey("Remote Desktop provisioned")
  RDP_CONNECTION_ESTABLISHED: "Remote Desktop session started", // localizationKey("Remote Desktop session started")
  RDP_CONNECTION_INITIATED: "Remote Desktop tunnel requested", // localizationKey("Remote Desktop tunnel requested")
  RDP_CONNECTION_TERMINATED: "Remote Desktop ended", // localizationKey("Remote Desktop ended")
  RDP: "Remote Desktop", // localizationKey("Remote Desktop")
  REJECTED_NODE_CLEARED: "Rejected Device Cleared", // localizationKey("Rejected Device Cleared")
  REJECTED_NODE_DELETED: "Rejected Device Deleted", // localizationKey("Rejected Device Deleted")
  RELATED_ITEM_CREATED: "Related Item Created", // localizationKey("Related Item Created")
  RELATED_ITEM_DELETED: "Related Item Deleted", // localizationKey("Related Item Deleted")
  RELATED_ITEM_SECURE_VALUE_DECRYPTED: "Related Item Secure Value Decrypted", // localizationKey("Related Item Secure Value Decrypted")
  RELATED_ITEM_TEMPORARY_PUBLIC_TOKEN_CREATED: "Related Item Temporary Public Token Created", //localizationKey("Related Item Temporary Public Token Created")
  RELATED_ITEM_UPDATED: "Related Item Updated", // localizationKey("Related Item Updated")
  REMOTE_SUPPORT_CREATED: "Remote Support Created", // localizationKey("Remote Support Created")
  REMOTE_SUPPORT_DELETED: "Remote Support Deleted", // localizationKey("Remote Support Deleted")
  REMOTE_SUPPORT_UPDATED: "Remote Support Updated", // localizationKey("Remote Support Updated")
  REMOTE_TOOLS_ACTIVE_DIRECTORY_INITIATED: "Active Directory initiated", // localizationKey("Active Directory initiated")
  REMOTE_TOOLS_COMPRESS_FILE_FAILED: "Files Compressed Failed", // localizationKey("Files Compressed Failed")
  REMOTE_TOOLS_COMPRESS_FILE_INITIATED: "File Compression Initiated", // localizationKey("File Compression Initiated")
  REMOTE_TOOLS_COMPRESS_FILE_SUCCESS: "Files Compressed Successfully", // localizationKey("Files Compressed Successfully")
  REMOTE_TOOLS_COPY_OBJECT_FAILED: "Object copy failed", // localizationKey("Object copy failed")
  REMOTE_TOOLS_COPY_OBJECT_SUCCESS: "Object(s) copied successfully", // localizationKey("Object(s) copied successfully")
  REMOTE_TOOLS_CREATE_DIRECTORY_FAILED: "Directory creation failed", // localizationKey("Directory creation failed")
  REMOTE_TOOLS_CREATE_DIRECTORY_INITIATED: "Directory creation initiated", // localizationKey("Directory creation initiated")
  REMOTE_TOOLS_CREATE_DIRECTORY_SUCCESS: "Directory created successfully", // localizationKey("Directory created successfully")
  REMOTE_TOOLS_CREATE_KEY_FAILED: "Create key failed", // localizationKey("Create key failed")
  REMOTE_TOOLS_CREATE_KEY_SUCCESS: "Created key successfully", // localizationKey("Created key successfully")
  REMOTE_TOOLS_CREATE_PARAMETER_FAILED: "Create value failed", // localizationKey("Create value failed")
  REMOTE_TOOLS_CREATE_PARAMETER_SUCCESS: "Created value successfully", // localizationKey("Created value successfully")
  REMOTE_TOOLS_DELETE_FILE_INITIATED: "Delete file initiated", // localizationKey("Delete file initiated")
  REMOTE_TOOLS_DELETE_KEY_FAILED: "Delete key failed", // localizationKey("Delete key failed")
  REMOTE_TOOLS_DELETE_KEY_SUCCESS: "Deleted key successfully", // localizationKey("Deleted key successfully")
  REMOTE_TOOLS_DELETE_OBJECT_FAILED: "Object delete failed", // localizationKey("Object delete failed")
  REMOTE_TOOLS_DELETE_OBJECT_SUCCESS: "Object(s) deleted successfully", // localizationKey("Object(s) deleted successfully")
  REMOTE_TOOLS_DELETE_PARAMETER_FAILED: "Delete value failed", // localizationKey("Delete value failed")
  REMOTE_TOOLS_DELETE_PARAMETER_SUCCESS: "Deleted value(s) successfully", // localizationKey("Deleted value(s) successfully")
  REMOTE_TOOLS_DOWNLOAD_FILE_INITIATED: "File download initiated", // localizationKey("File download initiated")
  REMOTE_TOOLS_FILE_DOWNLOADED: "File Downloaded", // localizationKey("File Downloaded")
  REMOTE_TOOLS_FILE_TRANSFER_FAILED: "File transfer failed", // localizationKey("File transfer failed")
  REMOTE_TOOLS_FILE_TRANSFER_SUCCESS: "File(s) transferred successfully", // localizationKey("File(s) transferred successfully")
  REMOTE_TOOLS_FILE_UPLOADED: "File Uploaded", // localizationKey("File Uploaded")
  REMOTE_TOOLS_MODIFY_OBJECT_FAILED: "Object modify failed", // localizationKey("Object modify failed")
  REMOTE_TOOLS_MODIFY_OBJECT_SUCCESS: "Object(s) modified successfully", // localizationKey("Object(s) modified successfully")
  REMOTE_TOOLS_MODIFY_PARAMETER_FAILED: "Modified value failed", // localizationKey("Modified value failed")
  REMOTE_TOOLS_MODIFY_PARAMETER_SUCCESS: "Modified value(s) successfully", // localizationKey("Modified value(s) successfully")
  REMOTE_TOOLS_MOVE_OBJECT_FAILED: "Object move failed", // localizationKey("Object move failed")
  REMOTE_TOOLS_MOVE_OBJECT_SUCCESS: "Object(s) moved successfully", // localizationKey("Object(s) moved successfully")
  REMOTE_TOOLS_PROCESS_CONTROL_INITIATED: "Process control initiated", // localizationKey("Process control initiated")
  REMOTE_TOOLS_REGISTRY_CONTROL_INITIATED: "Registry control initiated", // localizationKey("Registry control initiated")
  REMOTE_TOOLS_RENAME_FILE_INITIATED: "Rename file initiated", // localizationKey("Rename file initiated")
  REMOTE_TOOLS_RENAME_KEY_FAILED: "Rename key failed", // localizationKey("Rename key failed")
  REMOTE_TOOLS_RENAME_KEY_SUCCESS: "Renamed key successfully", // localizationKey("Renamed key successfully")
  REMOTE_TOOLS_RENAME_PARAMETER_FAILED: "Rename value failed", // localizationKey("Rename value failed")
  REMOTE_TOOLS_RENAME_PARAMETER_SUCCESS: "Renamed value successfully", // localizationKey("Renamed value successfully")
  REMOTE_TOOLS_RESTART_SERVICE_FAILED: "Restart service failed", // localizationKey("Restart service failed")
  REMOTE_TOOLS_RESTART_SERVICE_SUCCESS: "Restarted service successfully", // localizationKey("Restarted service successfully")
  REMOTE_TOOLS_SERVICE_CONTROL_INITIATED: "Service control initiated", // localizationKey("Service control initiated")
  REMOTE_TOOLS_SET_PROCESS_PRIORITY_FAILED: "Set process priority failed", // localizationKey("Set process priority failed")
  REMOTE_TOOLS_SET_PROCESS_PRIORITY_SUCESS: "Set process priority successfully", // localizationKey("Set process priority successfully")
  REMOTE_TOOLS_START_SERVICE_FAILED: "Start service failed", // localizationKey("Start service failed")
  REMOTE_TOOLS_START_SERVICE_SUCCESS: "Started service successfully", // localizationKey("Started service successfully")
  REMOTE_TOOLS_START_TYPE_CHANGE_FAILED: "Startup type change failed", // localizationKey("Startup type change failed")
  REMOTE_TOOLS_START_TYPE_CHANGE_SUCCESS: "Startup type changed successfully", // localizationKey("Startup type changed successfully")
  REMOTE_TOOLS_STOP_SERVICE_FAILED: "Stop service failed", // localizationKey("Stop service failed")
  REMOTE_TOOLS_STOP_SERVICE_SUCCESS: "Stopped service successfully", // localizationKey("Stopped service successfully")
  REMOTE_TOOLS_TERMINATE_PROCESS_FAILED: "Terminate process failed", // localizationKey("Terminate process failed")
  REMOTE_TOOLS_TERMINATE_PROCESS_SUCCESS: "Terminated process successfully", // localizationKey("Terminated process successfully")
  REMOTE_TOOLS_TERMINATE_PROCESS_TREE_FAILED: "Terminate process tree failed", // localizationKey("Terminate process tree failed")
  REMOTE_TOOLS_TERMINATE_PROCESS_TREE_SUCCESS: "Terminated process tree successfully", // localizationKey("Terminated process tree successfully")
  REMOTE_TOOLS_UPLOAD_FILE_INITIATED: "File upload initiated", // localizationKey("File upload initiated")
  REMOTE_TOOLS: "Remote Tools", // localizationKey("Remote Tools")
  REPORT_CREATED: "Report Created", // localizationKey("Report Created")
  REPORT_DELETED: "Report Deleted", // localizationKey("Report Deleted")
  REPORT_SCHEDULE_CLAIMED: "Report Schedule Claimed", //localizationKey("Report Schedule Claimed")
  REPORT_SCHEDULE_CREATED: "Report Schedule Created", // localizationKey("Report Schedule Created")
  REPORT_SCHEDULE_DELETED: "Report Schedule Deleted", // localizationKey("Report Schedule Deleted")
  REPORT_SCHEDULE_DUPLICATED: "Report Schedule Duplicated", // localizationKey("Report Schedule Duplicated")
  REPORT_SCHEDULE_UPDATED: "Report Schedule Updated", // localizationKey("Report Schedule Updated")
  REPORT_TEMPLATE_CREATED: "Report Template Created", // localizationKey("Report Template Created")
  REPORT_TEMPLATE_DELETED: "Report Template Deleted", // localizationKey("Report Template Deleted")
  REPORT_TEMPLATE_DUPLICATED: "Report Template Duplicated", // localizationKey("Report Template Duplicated")
  REPORT_TEMPLATE_UPDATED: "Report Template Updated", // localizationKey("Report Template Updated")
  REPORT_UPDATED: "Report Updated", // localizationKey("Report Updated")
  RESET_AGENT_OFFLINE: "Device Down Condition Reset", // localizationKey("Device Down Condition Reset")
  RESET_BY_PSA_TICKET_CALLBACK: "Reset by ITSM/PSA integration ticket callback", // localizationKey("Reset by ITSM/PSA integration ticket callback")  
  RESET_CONDITION_AGENT_CPU: "CPU Condition Reset", // localizationKey("CPU Condition Reset")
  RESET_CONDITION_AGENT_CVSS_SCORE: "Patch CVSS Score Condition Reset", //localizationKey("Patch CVSS Score Condition Reset")
  RESET_CONDITION_AGENT_DISK_FREE_SPACE: "Disk Free Space Condition Reset", // localizationKey("Disk Free Space Condition Reset")
  RESET_CONDITION_AGENT_DISK_IO: "Disk Active Time Condition Reset", // localizationKey("Disk Active Time Condition Reset")
  RESET_CONDITION_AGENT_DISK_USAGE: "Disk Usage Condition Reset", // localizationKey("Disk Usage Condition Reset")
  RESET_CONDITION_AGENT_MEMORY: "Memory Condition Reset", // localizationKey("Memory Condition Reset")
  RESET_CONDITION_AGENT_NETWORK: "Network Utilization Condition Reset", // localizationKey("Network Utilization Condition Reset")
  RESET_CONDITION_AGENT_PATCH_LAST_INSTALLED: "Patch Last Installed Condition Reset", //localizationKey("Patch Last Installed Condition Reset")
  RESET_CONDITION_BITLOCKER_STATUS: "Bitlocker Status Condition Reset", // localizationKey("Bitlocker Status Condition Reset")
  RESET_CONDITION_COMPOUND: "Compound Condition Reset", //localizationKey("Compound Condition Reset")
  RESET_CONDITION_CONFIGURATION_FILE: "Configuration File Condition Triggered", // localizationKey("Configuration File Condition Triggered")
  RESET_CONDITION_CRITICAL_EVENT: "Critical Event Condition Reset", // localizationKey("Critical Event Condition Reset")
  RESET_CONDITION_CUSTOM_FIELD: "Custom Field Condition Reset", // localizationKey("Custom Field Condition Reset")
  RESET_CONDITION_CUSTOM_SNMP: "Custom SNMP Condition Reset", //localizationKey("Custom SNMP Condition Reset")
  RESET_CONDITION_DNS: "DNS Condition Reset", // localizationKey("DNS Condition Reset")
  RESET_CONDITION_EMAIL: "Email Condition Reset", // localizationKey("Email Condition Reset")
  RESET_CONDITION_FILEVAULT_STATUS: "Filevault Status Condition Reset", // localizationKey("Filevault Status Condition Reset")
  RESET_CONDITION_HTTP_RESPONSE: "HTTP Response Condition Reset", // localizationKey("HTTP Response Condition Reset")
  RESET_CONDITION_HTTP: "HTTP Condition Reset", // localizationKey("HTTP Condition Reset")
  RESET_CONDITION_HV_GUEST_CHECKPOINT_LIFESPAN: "HyperV Guest Checkpoint Lifespan Condition Reset", // localizationKey("HyperV Guest Checkpoint Lifespan Condition Reset")
  RESET_CONDITION_HV_GUEST_CHECKPOINT_SIZE: "HyperV Guest Checkpoint Size Condition Reset", // localizationKey("HyperV Guest Checkpoint Size Condition Reset")
  RESET_CONDITION_LINUX_Daemon: "Daemon Down Condition Reset", // localizationKey("Daemon Down Condition Reset")
  RESET_CONDITION_LINUX_DISK_FREE_SPACE: "Disk Free Space Condition Reset", // localizationKey("Disk Free Space Condition Reset")
  RESET_CONDITION_LINUX_DISK_USAGE: "Disk Usage Condition Reset", // localizationKey("Disk Usage Condition Reset")
  RESET_CONDITION_LINUX_PROCESS_RESOURCE_CPU: "Linux Process CPU Resource Reset", // localizationKey("Linux Process CPU Resource Reset")
  RESET_CONDITION_LINUX_PROCESS_RESOURCE_MEMORY: "Linux Process Memory Resource Reset", // localizationKey("Linux Process Memory Resource Reset")
  RESET_CONDITION_LINUX_PROCESS_RESOURCE: "Linux Process Resource Reset", // localizationKey("Linux Process Resource Reset")
  RESET_CONDITION_LINUX_PROCESS: "Linux Process Reset", // localizationKey("Linux Process Reset")
  RESET_CONDITION_MAC_DEAMON: "Daemon Down Condition Reset", // localizationKey("Daemon Down Condition Reset")
  RESET_CONDITION_MAC_PROCESS_RESOURCE_CPU: "MAC Process CPU Resource Reset", // localizationKey("MAC Process CPU Resource Reset")
  RESET_CONDITION_MAC_PROCESS_RESOURCE_MEMORY: "MAC Process Memory Resource Reset", // localizationKey("MAC Process Memory Resource Reset")
  RESET_CONDITION_MAC_PROCESS_STATE: "Mac Process Reset", // localizationKey("Mac Process Reset")
  RESET_CONDITION_NMS_CPU: "CPU Condition Reset", // localizationKey("CPU Condition Reset")
  RESET_CONDITION_NMS_MEMORY: "Memory Condition Reset", // localizationKey("Memory Condition Reset")
  RESET_CONDITION_NMS_NETWORK_STATUS_CHANGE: "Network Adapter Status Change Condition Reset", // localizationKey("Network Adapter Status Change Condition Reset")
  RESET_CONDITION_NMS_NETWORK_STATUS: "Network Adapter Status Condition Reset", // localizationKey("Network Adapter Status Condition Reset")
  RESET_CONDITION_NMS_NETWORK_TRAFFIC_BITS: "Network Adapter Traffic Condition Reset", // localizationKey("Network Adapter Traffic Condition Reset")
  RESET_CONDITION_NMS_NETWORK_TRAFFIC_PERCENT: "Network Adapter Traffic Percent Condition Reset", // localizationKey("Network Adapter Traffic Percent Condition Reset")
  RESET_CONDITION_NMS_SYSTEM_UPTIME: "System Uptime Condition Reset", // localizationKey("System Uptime Condition Reset")
  RESET_CONDITION_PENDING_REBOOT: "Pending Reboot Condition Reset", //localizationKey("Pending Reboot Condition Reset")
  RESET_CONDITION_PING_LATENCY: "Ping Latency Condition Reset", // localizationKey("Ping Latency Condition Reset")
  RESET_CONDITION_PING_PACKET_LOSS: "Ping PacketLoss Condition Reset", // localizationKey("Ping PacketLoss Condition Reset")
  RESET_CONDITION_PING_RESPONSE: "Ping Response Condition Reset", // localizationKey("Ping Response Condition Reset")
  RESET_CONDITION_PING: "Ping Status Condition Reset", // localizationKey("Ping Status Condition Reset")
  RESET_CONDITION_PORT_SCAN: "Port Scan Condition Reset", // localizationKey("Port Scan Condition Reset")
  RESET_CONDITION_PORT: "Port Condition Reset", // localizationKey("Port Condition Reset")
  RESET_CONDITION_RAID_HEALTH_STATUS: "RAID Health Status Condition Reset", // localizationKey("RAID Health Status Condition Reset")
  RESET_CONDITION_SCRIPT_RESULT: "Script Condition Reset", // localizationKey("Script Condition Reset")
  RESET_CONDITION_SMART_STATUS_DEGRATED: "SMART Status Degraded Condition Reset", // localizationKey("SMART Status Degraded Condition Reset")
  RESET_CONDITION_SNMPTRAP: "SNMP Trap Condition Reset", // localizationKey("SNMP Trap Condition Reset")
  RESET_CONDITION_SOFTWARE: "Software Added/Removed Condition Reset", // localizationKey("Software Added/Removed Condition Reset")
  RESET_CONDITION_SYSLOG: "Syslog Condition Reset", // localizationKey("Syslog Condition Reset")
  RESET_CONDITION_SYSTEM_UPTIME: "System Uptime Condition Reset", // localizationKey("System Uptime Condition Reset")
  RESET_CONDITION_VM_AGGREGATE_CPU_USAGE: "VM Aggregate CPU Usage Condition Reset", // localizationKey("VM Aggregate CPU Usage Condition Reset")
  RESET_CONDITION_VM_DISK_USAGE: "VM Disk Usage Condition Reset", // localizationKey("VM Disk Usage Condition Reset")
  RESET_CONDITION_VM_GUEST_GUEST_OPERATIONAL_MODE: "VM Guest Operation Mode Condition Reset", // localizationKey("VM Guest Operation Mode Condition Reset")
  RESET_CONDITION_VM_GUEST_SNAPSHOT_LIFESPAN: "Vmware Guest Snapshot Lifespan Condition Reset", // localizationKey("Vmware Guest Snapshot Lifespan Condition Reset")
  RESET_CONDITION_VM_GUEST_SNAPSHOT_SIZE: "Vmware Guest Snapshot Size Condition Reset", // localizationKey("Vmware Guest Snapshot Size Condition Reset")
  RESET_CONDITION_VM_GUEST_TOOLS_NOT_RUNNING: "VM Guest Tools not running Condition Reset", // localizationKey("VM Guest Tools not running Condition Reset")
  RESET_CONDITION_VM_HOST_BAD_SENSORS: "VM Host Bad Sensor Count Condition Reset", // localizationKey("VM Host Bad Sensor Count Condition Reset")
  RESET_CONDITION_VM_HOST_DATASTORE: "VM Host DataStore Free Space Condition Reset", // localizationKey("VM Host DataStore Free Space Condition Reset")
  RESET_CONDITION_VM_HOST_DEVICE_DOWN: "VM Host Device Down Condition Reset", // localizationKey("VM Host Device Down Condition Reset")
  RESET_CONDITION_VM_HOST_SENSOR_HEALTH: "Vmware Host Sensor Health Condition Reset", // localizationKey("Vmware Host Sensor Health Condition Reset")
  RESET_CONDITION_VM_HOST_UPTIME: "VM Host Uptime Condition Reset", // localizationKey("VM Host Uptime Condition Reset")
  RESET_CONDITION_WINDOWS_EVENT_LOG_TRIGGERED: "Windows Event Log Condition Reset", // localizationKey("Windows Event Log Condition Reset")
  RESET_CONDITION_WINDOWS_PROCESS_RESOURCE_CPU: "Windows Process CPU Resource Reset", // localizationKey("Windows Process CPU Resource Reset")
  RESET_CONDITION_WINDOWS_PROCESS_RESOURCE_MEMORY: "Windows Process Memory Resource Reset", // localizationKey("Windows Process Memory Resource Reset")
  RESET_CONDITION_WINDOWS_PROCESS_STATE: "Windows Process Reset", // localizationKey("Windows Process Reset")
  RESET_CONDITION_WINDOWS_SERVICE_STATE_CHANGED: "Windows Service Condition Reset", // localizationKey("Windows Service Condition Reset")
  RESET: "Reset", // localizationKey("Reset")
  RPD: "RPD",
  SCHEDULE_INSTALL_OPTION_CHANGED: "Bitdefender reinstall settings when install fails", // localizationKey("Bitdefender reinstall settings when install fails")
  SCHEDULED_TASK_CREATED: "Scheduled task created", // localizationKey("Scheduled task created")
  SCHEDULED_TASK_DELETED: "Scheduled task deleted", // localizationKey("Scheduled task deleted")
  SCHEDULED_TASK_UPDATED: "Scheduled task updated", // localizationKey("Scheduled task updated")
  SCHEDULED_TASK: "Scheduled Task", // localizationKey("Scheduled Task")
  SCIM_ACCESS_TOKEN_CREATED: "SCIM access token created", // localizationKey("SCIM access token created")
  SCIM_ACCESS_TOKEN_REMOVED: "SCIM access token removed", // localizationKey("SCIM access token removed")
  SCIM_APP_USER_CREATED: "SCIM app user created", // localizationKey("SCIM app user created")
  SCIM_APP_USER_DELETED: "SCIM app user deleted", // localizationKey("SCIM app user deleted")
  SCIM_APP_USER_UPDATED: "SCIM app user updated", // localizationKey("SCIM app user updated")
  SCIM_DISABLED: "SCIM disabled", // localizationKey("SCIM disabled")
  SCIM_ENABLED: "SCIM enabled", // localizationKey("SCIM enabled")
  SCIM_END_USER_CREATED: "SCIM end user created", // localizationKey("SCIM end user created")
  SCIM_END_USER_DELETED: "SCIM end user deleted", // localizationKey("SCIM end user deleted")
  SCIM_END_USER_UPDATED: "SCIM end user updated", // localizationKey("SCIM end user updated")
  SCIM_USER_GROUP_CREATED: "SCIM User Group Created", //localizationKey("SCIM User Group Created")
  SCIM_USER_GROUP_DELETED: "SCIM User Group Deleted", //localizationKey("SCIM User Group Deleted")
  SCIM_USER_GROUP_UPDATED: "SCIM User Group Updated", //localizationKey("SCIM User Group Updated")
  SCRIPT_CREATED: "Script created", // localizationKey("Script created")
  SCRIPT_DELETED: "Script deleted", // localizationKey("Script deleted")
  SCRIPT_UPDATED: "Script updated", // localizationKey("Script updated")
  SCRIPTING: "Scripting", // localizationKey("Scripting")
  SECURITY_CREDENTIAL_ACCESS_DENIED: "Security Credential Access Denied", // localizationKey("Security Credential Access Denied")
  SECURITY_CREDENTIAL_ACCESS_GRANTED: "Security Credential Access Granted", // localizationKey("Security Credential Access Granted")
  SENTINEL_ONE_AGENT_DISABLED: "SentinelOne Agent Disabled", // localizationKey("SentinelOne Agent Disabled")
  SENTINEL_ONE_AGENT_ENABLED: "SentinelOne Agent Enabled", // localizationKey("SentinelOne Agent Enabled")
  SENTINEL_ONE_AGENT_INSTALLATION_FAILED: "SentinelOne Agent Installation Failed", // localizationKey("SentinelOne Agent Installation Failed")
  SENTINEL_ONE_AGENT_INSTALLATION_SUCCEEDED: "SentinelOne Agent Installation Succeeded", // localizationKey("SentinelOne Agent Installation Succeeded")
  SENTINEL_ONE_AGENT_SHUT_DOWN: "SentinelOne Agent Shut Down", // localizationKey("SentinelOne Agent Shut Down")
  SENTINEL_ONE_AGENT_STARTED: "SentinelOne Agent Started", // localizationKey("SentinelOne Agent Started")
  SENTINEL_ONE_AGENT_SYNC_CANCELLED: "SentinelOne Agent Sync Cancelled", //localizationKey("SentinelOne Agent Sync Cancelled")
  SENTINEL_ONE_AGENT_SYNC_FAILED_RETRY: "SentinelOne Agent Failed To Sync: Retrying", //localizationKey("SentinelOne Agent Failed To Sync: Retrying")
  SENTINEL_ONE_AGENT_SYNC_FAILED: "SentinelOne Agent Failed To Sync", //localizationKey("SentinelOne Agent Failed To Sync")
  SENTINEL_ONE_AGENT_SYNC_SUCCESS: "SentinelOne Agent Successfully Synced", //localizationKey("SentinelOne Agent Successfully Synced")
  SENTINEL_ONE_AGENT_UPGRADED: "SentinelOne Agent Upgraded", // localizationKey("SentinelOne Agent Upgraded")
  SENTINEL_ONE_COMMAND_FAILED: "SentinelOne Command Failed", // localizationKey("SentinelOne Command Failed")
  SENTINEL_ONE_COMMAND_RECEIVED: "SentinelOne Command Received", // localizationKey("SentinelOne Command Received")
  SENTINEL_ONE_EXISTING_INSTALLATION_DETECTED: "SentinelOne Existing Installation Detected", //localizationKey("SentinelOne Existing Installation Detected")
  SENTINEL_ONE_INSTALLATION_ANOTHER_AV_DETECTED: "SentinelOne Installation Detected Another AV", // localizationKey("SentinelOne Installation Detected Another AV")
  SENTINEL_ONE_INTEGRATION_CREDENTIALS_UPDATED: "SentinelOne Integration Credentials Updated", // localizationKey("SentinelOne Integration Credentials Updated")
  SENTINEL_ONE_INTEGRATION_DISABLED: "SentinelOne Integration Disabled", // localizationKey("SentinelOne Integration Disabled")
  SENTINEL_ONE_INTEGRATION_ENABLED: "SentinelOne Integration Enabled", // localizationKey("SentinelOne Integration Enabled")
  SENTINEL_ONE_INTEGRATION_RESYNC_INITIATED: "SentinelOne Integration Re-sync Initiated", //localizationKey("SentinelOne Integration Re-Sync Initiated")
  SENTINEL_ONE_INTEGRATION_RESYNC_REQUIRED: "SentinelOne Integration Re-sync Required", //localizationKey("SentinelOne Integration Res-sync Required")
  SENTINEL_ONE_LOCAL_CONFIG_CHANGE_FAILED: "SentinelOne Local Configuration Change Failed", // localizationKey("SentinelOne Local Configuration Change Failed")
  SENTINEL_ONE_MITIGATION_CANNOT_KILL: "SentinelOne Mitigation Cannot Kill Process", // localizationKey("SentinelOne Mitigation Cannot Kill Process")
  SENTINEL_ONE_MITIGATION_FAILED_TO_KILL: "SentinelOne Mitigation Failed To Kill Process", // localizationKey("SentinelOne Mitigation Failed To Kill Process")
  SENTINEL_ONE_MITIGATION_FAILED: "SentinelOne Mitigation Failed", // localizationKey("SentinelOne Mitigation Failed")
  SENTINEL_ONE_MITIGATION_REQUIRE_REBOOT: "SentinelOne Mitigation Requires Reboot", // localizationKey("SentinelOne Mitigation Requires Reboot")
  SENTINEL_ONE_MITIGATION_SUCCEEDED: "SentinelOne Mitigation Succeeded", // localizationKey("SentinelOne Mitigation Succeeded")
  SENTINEL_ONE_ON_DEMAND_SCAN_ABORTED: "SentinelOne On-demand Scan Aborted", //localizationKey("SentinelOne On-demand Scan Aborted")
  SENTINEL_ONE_ON_DEMAND_SCAN_COMPLETED: "SentinelOne On-demand Scan Completed", //localizationKey("SentinelOne On-demand Scan Completed")
  SENTINEL_ONE_ON_DEMAND_SCAN_STARTED: "SentinelOne On-demand Scan Started", //localizationKey("SentinelOne On-demand Scan Started")
  SENTINEL_ONE_POLICY_CHANGED: "SentinelOne Policy Changed", // localizationKey("SentinelOne Policy Changed")
  SENTINEL_ONE_QUARANTINE_FAILED: "SentinelOne Quarantine Failed", // localizationKey("SentinelOne Quarantine Failed")
  SENTINEL_ONE_QUARANTINE_SUCCEEDED: "SentinelOne Quarantine Succeeded", // localizationKey("SentinelOne Quarantine Succeeded")
  SENTINEL_ONE_REFRESH_API_TOKEN_FAILED: "SentinelOne Refresh API Token Failed", //localizationKey("SentinelOne Refresh API Token Failed")
  SENTINEL_ONE_REFRESH_API_TOKEN_SUCCEEDED: "SentinelOne Refresh API Token Succeeded", //localizationKey("SentinelOne Refresh API Token Succeeded")
  SENTINEL_ONE_REGISTRATION_FAILED: "SentinelOne Machine Registration Failed", // localizationKey("SentinelOne Machine Registration Failed")
  SENTINEL_ONE_REGISTRATION_SUCCEEDED: "SentinelOne Machine Registration Succeeded", // localizationKey("SentinelOne Machine Registration Succeeded")
  SENTINEL_ONE_REMEDIATION_FAILED: "SentinelOne Remediation Failed", // localizationKey("SentinelOne Remediation Failed")
  SENTINEL_ONE_REMEDIATION_SUCCEEDED: "SentinelOne Remediation Succeeded", // localizationKey("SentinelOne Remediation Succeeded")
  SENTINEL_ONE_RETRY_INSTALL_COMPLETED: "SentinelOne Retry Install Completed", //localizationKey("SentinelOne Retry Install Completed"),
  SENTINEL_ONE_SCAN_ABORTED: "SentinelOne Scan Aborted", // localizationKey("SentinelOne Scan Aborted")
  SENTINEL_ONE_SCAN_COMPLETED: "SentinelOne Scan Completed", // localizationKey("SentinelOne Scan Completed")
  SENTINEL_ONE_SCAN_FAILED: "SentinelOne Scan Failed", // localizationKey("SentinelOne Scan Failed")
  SENTINEL_ONE_SCAN_STARTED: "SentinelOne Scan Started", // localizationKey("SentinelOne Scan Started")
  SENTINEL_ONE_THREAT_DETECTED: "SentinelOne Threat Detected", // localizationKey("SentinelOne Threat Detected")
  SENTINEL_ONE_UNQUARANTINE_FAILED: "SentinelOne Unquarantine Failed", // localizationKey("SentinelOne Unquarantine Failed")
  SENTINEL_ONE_UNQUARANTINE_SUCCEEDED: "SentinelOne Unquarantine Succeeded", // localizationKey("SentinelOne Unquarantine Succeeded")
  SERVER_MESSAGE: "Server Message", // localizationKey("Server Message")
  SERVICE_NOW_CLIENT_MAPPING_CREATED: "ServiceNow client mapping created", //localizationKey("ServiceNow client mapping created")
  SERVICE_NOW_CLIENT_MAPPING_DELETED: "ServiceNow client mapping deleted", //localizationKey("ServiceNow client mapping deleted")
  SERVICE_NOW_CLIENT_MAPPING_STATUS_UPDATED: "ServiceNow client mapping status was updated", //localizationKey("ServiceNow client mapping status was updated")
  SERVICE_NOW_CLIENT_MAPPING_UPDATED: "ServiceNow client mapping updated", //localizationKey("ServiceNow client mapping updated")
  SERVICE_NOW_CONFIGURATION_ITEM_CLASS_MAPPING_CREATED: "ServiceNow configuration item class mapping created", //localizationKey("ServiceNow configuration item class mapping created")
  SERVICE_NOW_CONFIGURATION_ITEM_CLASS_MAPPING_DELETED: "ServiceNow configuration item class mapping deleted", //localizationKey("ServiceNow configuration item class mapping deleted")
  SERVICE_NOW_CONFIGURATION_ITEM_CLASS_MAPPING_UPDATED: "ServiceNow configuration item class mapping updated", //localizationKey("ServiceNow configuration item class mapping updated")
  SERVICE_NOW_NODE_SYNC_BY_CLIENT_COMPLETED: "ServiceNow Node Sync was completed", //localizationKey("ServiceNow Node Sync was completed")
  SERVICE_NOW_NODE_SYNC_BY_CLIENT_STARTED: "ServiceNow Node Sync started", //localizationKey("ServiceNow Node Sync started")
  SERVICE_NOW_NODE_SYNC_NODE_CREATED: "ServiceNow device created", //localizationKey("ServiceNow device created")
  SERVICE_NOW_NODE_SYNC_NODE_ERROR: "ServiceNow device sync failed", //localizationKey("ServiceNow device sync failed")
  SERVICE_NOW_NODE_SYNC_NODE_UPDATED: "ServiceNow device updated", //localizationKey("ServiceNow device updated")
  SERVICE_NOW_RESET_AUTHENTICATION: "ServiceNow authentication reset", //localizationKey("ServiceNow authentication reset")
  SERVICE_NOW_SYNC_CLIENT_ON_DEMAND: "ServiceNow sync organizations on demand", //localizationKey("ServiceNow sync organizations on demand")
  SERVICE_NOW_UPDATED: "ServiceNow connection updated", //localizationKey("ServiceNow connection updated")
  SHADOWPROTECT_BACKUPJOB_ABORTED: "ShadowProtect backup job aborted", // localizationKey("ShadowProtect backup job aborted")
  SHADOWPROTECT_BACKUPJOB_FAILED: "ShadowProtect backup job failed", // localizationKey("ShadowProtect backup job failed")
  SHADOWPROTECT_INSTALL_FAILED: "ShadowProtect install failed", // localizationKey("ShadowProtect install failed")
  SHADOWPROTECT_INSTALLED: "ShadowProtect installed", // localizationKey("ShadowProtect installed")
  SHADOWPROTECT_LICENSE_ACTIVATED: "ShadowProtect license activated", // localizationKey("ShadowProtect license activated")
  SHADOWPROTECT_LICENSE_ACTIVATION_FAILED: "ShadowProtect license activation failed", // localizationKey("ShadowProtect license activation failed")
  SHADOWPROTECT_LICENSE_DEACTIVATED: "ShadowProtect license deactivated", // localizationKey("ShadowProtect license deactivated")
  SHADOWPROTECT_LICENSE_DEACTIVATION_FAILED: "ShadowProtect license deactivation failed", // localizationKey("ShadowProtect license deactivation failed")
  SHADOWPROTECT_LICENSE_PROVISION_FAILED: "ShadowProtect license provision failed", // localizationKey("ShadowProtect license provision failed")
  SHADOWPROTECT_LICENSE_PROVISIONED: "ShadowProtect license provisioned", // localizationKey("ShadowProtect license provisioned")
  SHADOWPROTECT_UNINSTALL_FAILED: "ShadowProtect uninstall failed", // localizationKey("ShadowProtect uninstall failed")
  SHADOWPROTECT_UNINSTALLED: "ShadowProtect uninstalled", // localizationKey("ShadowProtect uninstalled")
  SHADOWPROTECT: "ShadowProtect", // localizationKey("ShadowProtect")
  SKIP_LOGIN_MFA_DISABLED: "SSO SkipLoginMFA Disabled", //localizationKey("SSO SkipLoginMFA Disabled")
  SKIP_LOGIN_MFA_ENABLED: "SSO SkipLoginMFA Enabled", //localizationKey("SSO SkipLoginMFA Enabled")
  SMTP_SEND_ERROR: "Error sending email by SMTP", // localizationKey("Error sending email by SMTP")
  SOFTWARE_ADDED: "Software Added", // localizationKey("Software Added")
  SOFTWARE_PATCH_MANAGEMENT_APPLY_PATCH_COMPLETED: "Software patch management apply patch completed", // localizationKey("Software patch management apply patch completed")
  SOFTWARE_PATCH_MANAGEMENT_APPLY_PATCH_STARTED: "Software patch management apply patch started", // localizationKey("Software patch management apply patch started")
  SOFTWARE_PATCH_MANAGEMENT_INSTALL_FAILED: "Software patch management install failed", // localizationKey("Software patch management install failed")
  SOFTWARE_PATCH_MANAGEMENT_INSTALLED: "Software patch management installed", // localizationKey("Software patch management installed")
  SOFTWARE_PATCH_MANAGEMENT_MESSAGE: "Software patch management message", // localizationKey("Software patch management message")
  SOFTWARE_PATCH_MANAGEMENT_PATCH_APPROVED: "Software Patch Override Approvals Changed", // localizationKey("Software Patch Override Approvals Changed")
  SOFTWARE_PATCH_MANAGEMENT_PATCH_REJECTED: "Software Patch Override Rejections Changed", // localizationKey("Software Patch Override Rejections Changed")
  SOFTWARE_PATCH_MANAGEMENT_SCAN_COMPLETED: "Software patch management scan completed", // localizationKey("Software patch management scan completed")
  SOFTWARE_PATCH_MANAGEMENT_SCAN_STARTED: "Software patch management scan started", // localizationKey("Software patch management scan started")
  SOFTWARE_PATCH_MANAGEMENT: "Software Patch Management", // localizationKey("Software Patch Management")
  SOFTWARE_REMOVED: "Software Removed", // localizationKey("Software Removed")
  SOFTWARE_UPDATED: "Software Updated", //localizationKey("Software Updated")
  SPLASHTOP_CONNECTION_ESTABLISHED: "Connection established", // localizationKey("Connection established")
  SPLASHTOP_CONNECTION_INITIATED: "Connection initiated", // localizationKey("Connection initiated")
  SPLASHTOP_CONNECTION_TERMINATED: "Connection terminated", // localizationKey("Connection terminated")
  SPLASHTOP: "Splashtop",
  SSO_CREATED: "SSO created", // localizationKey("SSO created")
  SSO_DELETED: "SSO deleted", // localizationKey("SSO deleted")
  SSO_DISABLED: "SSO disabled", // localizationKey("SSO disabled")
  SSO_ENABLED: "SSO enabled", // localizationKey("SSO enabled")
  SSO_UPDATED: "SSO Updated", //localizationKey("SSO Updated")
  START_REQUESTED_BY_DEVICE_END_USER: "Start Requested by Device End User", //localizationKey("Start Requested by Device End User")
  START_REQUESTED_MESSAGE: "Start Requested - The process should begin in a few moments", // localizationKey("Start Requested - The process should begin in a few moments")
  START_REQUESTED: "Start requested", // localizationKey("Start requested")
  STARTED: "Started", // localizationKey("Started")
  SYSTEM_REBOOTED: "System rebooted", // localizationKey("System rebooted")
  SYSTEM: "System", // localizationKey("System")
  TEAMVIEWER_ACCOUNTNAME_ADDED: "TeamViewer account name added", // localizationKey("TeamViewer account name added")
  TEAMVIEWER_ACCOUNTNAME_CHANGED: "TeamViewer account name changed", // localizationKey("TeamViewer account name changed")
  TEAMVIEWER_ACCOUNTNAME_REMOVED: "TeamViewer account name removed", // localizationKey("TeamViewer account name removed")
  TEAMVIEWER_CONFIG_CHANGED: "TeamViewer Configuration Changed", // localizationKey("TeamViewer Configuration Changed")
  TEAMVIEWER_CONNECTION_CANCELLED: "TeamViewer connection canceled", // localizationKey("TeamViewer connection canceled")
  TEAMVIEWER_CONNECTION_ESTABLISHED: "TeamViewer connection established", // localizationKey("TeamViewer connection established")
  TEAMVIEWER_CONNECTION_TERMINATED: "TeamViewer connection terminated", // localizationKey("TeamViewer connection terminated")
  TEAMVIEWER_INSTALL_FAILED: "TeamViewer install failed", // localizationKey("TeamViewer install failed")
  TEAMVIEWER_INSTALLED: "TeamViewer installed", // localizationKey("TeamViewer installed")
  TEAMVIEWER_PASSWORD_CHANGED: "TeamViewer password changed", // localizationKey("TeamViewer password changed")
  TEAMVIEWER_PERMANENT_PASSWORD_CHANGED: "TeamViewer permanent password changed", // localizationKey("TeamViewer permanent password changed")
  TEAMVIEWER_UNINSTALL_FAILED: "TeamViewer uninstall failed", // localizationKey("TeamViewer uninstall failed")
  TEAMVIEWER_UNINSTALLED: "TeamViewer uninstalled", // localizationKey("TeamViewer uninstalled")
  TEAMVIEWER: "TeamViewer",
  TECHNICIAN_PERMISSIONS_CHANGED: "Technician Permissions Changed", //localizationKey("Technician Permissions Changed")
  TECHNICIAN_ROLE_ASSIGNMENT_CHANGED: "Technician Role Assignment Changed", //localizationKey("Technician Role Assignment Changed")
  TECHNICIAN_ROLE_CREATED: "Technician Role Created", //localizationKey("Technician Role Created")
  TECHNICIAN_ROLE_DELETED: "Technician Role Deleted", //localizationKey("Technician Role Deleted")
  TECHNICIAN_ROLE_UPDATED: "Technician Role Updated", //localizationKey("Technician Role Updated")
  TICKET_TEMPLATE_CREATED: "Ticket Template Created", // localizationKey("Ticket Template Created")
  TICKET_TEMPLATE_DELETED: "Ticket Template Deleted", // localizationKey("Ticket Template Deleted")
  TICKET_TEMPLATE_MAKE_DEFAULT: "Default PSA/ITSM integration ticket template was updated", //localizationKey("Default PSA/ITSM integration ticket template was updated")
  TICKET_TEMPLATE_UPDATED: "Ticket Template Updated", // localizationKey("Ticket Template Updated")
  TIME_ZONE_UPDATED: "Time Zone Updated", // localizationKey("Time Zone Updated")
  TOTP_SECRET_CREATED: "TOTP Secret Created", //localizationKey("TOTP Secret Created")
  TOTP_SECRET_DECRYPTED: "TOTP Secret Decrypted", //localizationKey("TOTP Secret Decrypted")
  TOTP_SECRET_DELETED: "TOTP Secret Deleted", //localizationKey("TOTP Secret Deleted")
  TOTP_SECRET_UPDATED: "TOTP Secret Updated", //localizationKey("TOTP Secret Updated")
  TRIGGERED_AGENT_OFFLINE: "Device Down Condition Triggered", // localizationKey("Device Down Condition Triggered")
  TRIGGERED_CONDITION_AGENT_CPU: "CPU Condition Triggered", // localizationKey("CPU Condition Triggered")
  TRIGGERED_CONDITION_AGENT_CVSS_SCORE: "Patch CVSS Score Condition Triggered", //localizationKey("Patch CVSS Score Condition Triggered")
  TRIGGERED_CONDITION_AGENT_DISK_FREE_SPACE: "Disk Free Space Condition Triggered", // localizationKey("Disk Free Space Condition Triggered")
  TRIGGERED_CONDITION_AGENT_DISK_IO: "Disk Active Time Condition Triggered", // localizationKey("Disk Active Time Condition Triggered")
  TRIGGERED_CONDITION_AGENT_DISK_USAGE: "Disk Usage Condition Triggered", // localizationKey("Disk Usage Condition Triggered")
  TRIGGERED_CONDITION_AGENT_MEMORY: "Memory Condition Triggered", // localizationKey("Memory Condition Triggered")
  TRIGGERED_CONDITION_AGENT_NETWORK: "Network Utilization Condition Triggered", // localizationKey("Network Utilization Condition Triggered")
  TRIGGERED_CONDITION_AGENT_PATCH_LAST_INSTALLED: "Patch Last Installed Condition Triggered", //localizationKey("Patch Last Installed Condition Triggered")
  TRIGGERED_CONDITION_BITLOCKER_STATUS: "Bitlocker Status Condition Triggered", // localizationKey("Bitlocker Status Condition Triggered")
  TRIGGERED_CONDITION_COMPOUND: "Compound Condition Triggered", //localizationKey("Compound Condition Triggered")
  TRIGGERED_CONDITION_CONFIGURATION_FILE: "Configuration File Condition Triggered", // localizationKey("Configuration File Condition Triggered")
  TRIGGERED_CONDITION_CRITICAL_EVENT: "Critical Event Condition Triggered", // localizationKey("Critical Event Condition Triggered")
  TRIGGERED_CONDITION_CUSTOM_FIELD: "Custom Field Condition Triggered", // localizationKey("Custom Field Condition Triggered")
  TRIGGERED_CONDITION_CUSTOM_SNMP: "Custom SNMP Condition Triggered", //localizationKey("Custom SNMP Condition Triggered")
  TRIGGERED_CONDITION_DNS: "DNS Condition Triggered", // localizationKey("DNS Condition Triggered")
  TRIGGERED_CONDITION_EMAIL: "Email Condition Triggered", // localizationKey("Email Condition Triggered")
  TRIGGERED_CONDITION_FILEVAULT_STATUS: "Filevault Status Condition Triggered", // localizationKey("Filevault Status Condition Triggered")
  TRIGGERED_CONDITION_HTTP_RESPONSE: "HTTP Response Condition Triggered", // localizationKey("HTTP Response Condition Triggered")
  TRIGGERED_CONDITION_HTTP: "HTTP Condition Triggered", // localizationKey("HTTP Condition Triggered")
  TRIGGERED_CONDITION_HV_GUEST_CHECKPOINT_LIFESPAN: "HyperV Guest Checkpoint Lifespan Condition Triggered", // localizationKey("HyperV Guest Checkpoint Lifespan Condition Triggered")
  TRIGGERED_CONDITION_HV_GUEST_CHECKPOINT_SIZE: "HyperV Guest Checkpoint Size Condition Triggered", // localizationKey("HyperV Guest Checkpoint Size Condition Triggered")
  TRIGGERED_CONDITION_LINUX_Daemon: "Daemon Down Condition Triggered", // localizationKey("Daemon Down Condition Triggered")
  TRIGGERED_CONDITION_LINUX_DISK_FREE_SPACE: "Disk Free Space Condition Triggered", // localizationKey("Disk Free Space Condition Triggered")
  TRIGGERED_CONDITION_LINUX_DISK_USAGE: "Disk Usage Condition Triggered", // localizationKey("Disk Usage Condition Triggered")
  TRIGGERED_CONDITION_LINUX_PROCESS_RESOURCE_CPU: "Linux Process CPU Resource Triggered", // localizationKey("Linux Process CPU Resource Triggered")
  TRIGGERED_CONDITION_LINUX_PROCESS_RESOURCE_MEMORY: "Linux Process Memory Resource Triggered", // localizationKey("Linux Process Memory Resource Triggered")
  TRIGGERED_CONDITION_LINUX_PROCESS_RESOURCE: "Linux Process Resource Triggered", // localizationKey("Linux Process Resource Triggered")
  TRIGGERED_CONDITION_LINUX_PROCESS: "Linux Process Triggered", // localizationKey("Linux Process Triggered")
  TRIGGERED_CONDITION_MAC_DEAMON: "Daemon Down Condition Triggered", // localizationKey("Daemon Down Condition Triggered")
  TRIGGERED_CONDITION_MAC_PROCESS_RESOURCE_CPU: "MAC Process CPU Resource Triggered", // localizationKey("MAC Process CPU Resource Triggered")
  TRIGGERED_CONDITION_MAC_PROCESS_RESOURCE_MEMORY: "MAC Process Memory Resource Triggered", // localizationKey("MAC Process Memory Resource Triggered")
  TRIGGERED_CONDITION_MAC_PROCESS_STATE: "Mac Process Triggered", // localizationKey("Mac Process Triggered")
  TRIGGERED_CONDITION_NMS_CPU: "CPU Condition Triggered", // localizationKey("CPU Condition Triggered")
  TRIGGERED_CONDITION_NMS_MEMORY: "Memory Condition Triggered", // localizationKey("Memory Condition Triggered")
  TRIGGERED_CONDITION_NMS_NETWORK_STATUS_CHANGE: "Network Adapter Status Change Condition Triggered", // localizationKey("Network Adapter Status Change Condition Triggered")
  TRIGGERED_CONDITION_NMS_NETWORK_STATUS: "Network Adapter Status Condition Triggered", // localizationKey("Network Adapter Status Condition Triggered")
  TRIGGERED_CONDITION_NMS_NETWORK_TRAFFIC_BITS: "Network Adapter Traffic Condition Triggered", // localizationKey("Network Adapter Traffic Condition Triggered")
  TRIGGERED_CONDITION_NMS_NETWORK_TRAFFIC_PERCENT: "Network Adapter Traffic Percent Condition Triggered", // localizationKey("Network Adapter Traffic Percent Condition Triggered")
  TRIGGERED_CONDITION_NMS_SYSTEM_UPTIME: "System Uptime Condition Triggered", // localizationKey("System Uptime Condition Triggered")
  TRIGGERED_CONDITION_PENDING_REBOOT: "Pending Reboot Condition Triggered", //localizationKey("Pending Reboot Condition Triggered")
  TRIGGERED_CONDITION_PING_LATENCY: "Ping Latency Condition Triggered", // localizationKey("Ping Latency Condition Triggered")
  TRIGGERED_CONDITION_PING_PACKET_LOSS: "Ping PacketLoss Condition Triggered", // localizationKey("Ping PacketLoss Condition Triggered")
  TRIGGERED_CONDITION_PING_RESPONSE: "Ping Response Condition Triggered", // localizationKey("Ping Response Condition Triggered")
  TRIGGERED_CONDITION_PING: "Ping Status Condition Triggered", // localizationKey("Ping Status Condition Triggered")
  TRIGGERED_CONDITION_PORT_SCAN: "Port Scan Condition Triggered", // localizationKey("Port Scan Condition Triggered")
  TRIGGERED_CONDITION_PORT: "Port Condition Triggered", // localizationKey("Port Condition Triggered")
  TRIGGERED_CONDITION_RAID_HEALTH_STATUS: "RAID Health Status Condition Triggered", // localizationKey("RAID Health Status Condition Triggered")
  TRIGGERED_CONDITION_SCRIPT_RESULT: "Script Condition Triggered", // localizationKey("Script Condition Triggered")
  TRIGGERED_CONDITION_SMART_STATUS_DEGRATED: "SMART Status Degraded Condition Triggered", // localizationKey("SMART Status Degraded Condition Triggered")
  TRIGGERED_CONDITION_SNMPTRAP: "SNMP Trap Condition Triggered", // localizationKey("SNMP Trap Condition Triggered")
  TRIGGERED_CONDITION_SOFTWARE: "Software Added/Removed Condition Triggered", // localizationKey("Software Added/Removed Condition Triggered")
  TRIGGERED_CONDITION_SYSLOG: "Syslog Condition Triggered", // localizationKey("Syslog Condition Triggered")
  TRIGGERED_CONDITION_SYSTEM_UPTIME: "System Uptime Condition Triggered", // localizationKey("System Uptime Condition Triggered")
  TRIGGERED_CONDITION_VM_AGGREGATE_CPU_USAGE: "VM Aggregate CPU Usage Condition Triggered", // localizationKey("VM Aggregate CPU Usage Condition Triggered")
  TRIGGERED_CONDITION_VM_DISK_USAGE: "VM Disk Usage Condition Triggered", // localizationKey("VM Disk Usage Condition Triggered")
  TRIGGERED_CONDITION_VM_GUEST_GUEST_OPERATIONAL_MODE: "VM Guest Operation Mode Condition Triggered", // localizationKey("VM Guest Operation Mode Condition Triggered")
  TRIGGERED_CONDITION_VM_GUEST_SNAPSHOT_LIFESPAN: "Vmware Guest Snapshot Lifespan Condition Triggered", // localizationKey("Vmware Guest Snapshot Lifespan Condition Triggered")
  TRIGGERED_CONDITION_VM_GUEST_SNAPSHOT_SIZE: "Vmware Guest Snapshot Size Condition Triggered", // localizationKey("Vmware Guest Snapshot Size Condition Triggered")
  TRIGGERED_CONDITION_VM_GUEST_TOOLS_NOT_RUNNING: "VM Guest Tools not running Condition Triggered", // localizationKey("VM Guest Tools not running Condition Triggered")
  TRIGGERED_CONDITION_VM_HOST_BAD_SENSORS: "VM Host Bad Sensor Count Condition Triggered", // localizationKey("VM Host Bad Sensor Count Condition Triggered")
  TRIGGERED_CONDITION_VM_HOST_DATASTORE: "VM Host DataStore Free Space Condition Triggered", // localizationKey("VM Host DataStore Free Space Condition Triggered")
  TRIGGERED_CONDITION_VM_HOST_DEVICE_DOWN: "VM Host Device Down Condition Triggered", // localizationKey("VM Host Device Down Condition Triggered")
  TRIGGERED_CONDITION_VM_HOST_SENSOR_HEALTH: "Vmware Host Sensor Health Condition Triggered", // localizationKey("Vmware Host Sensor Health Condition Triggered")
  TRIGGERED_CONDITION_VM_HOST_UPTIME: "VM Host Uptime Condition Triggered", // localizationKey("VM Host Uptime Condition Triggered")
  TRIGGERED_CONDITION_WINDOWS_EVENT_LOG_TRIGGERED: "Windows Event Log Condition Triggered", // localizationKey("Windows Event Log Condition Triggered")
  TRIGGERED_CONDITION_WINDOWS_PROCESS_RESOURCE_CPU: "Windows Process CPU Resource Triggered", // localizationKey("Windows Process CPU Resource Triggered")
  TRIGGERED_CONDITION_WINDOWS_PROCESS_RESOURCE_MEMORY: "Windows Process Memory Resource Triggered", // localizationKey("Windows Process Memory Resource Triggered")
  TRIGGERED_CONDITION_WINDOWS_PROCESS_STATE: "Windows Process Triggered", // localizationKey("Windows Process Triggered")
  TRIGGERED_CONDITION_WINDOWS_SERVICE_STATE_CHANGED: "Windows Service Condition Triggered", // localizationKey("Windows Service Condition Triggered")
  TRIGGERED: "Triggered", // localizationKey("Triggered")
  TRUSTED_PLATFORM_MODULE_DISABLED: "TPM disabled", // localizationKey("TPM disabled")
  TRUSTED_PLATFORM_MODULE_ENABLED: "TPM enabled", // localizationKey("TPM enabled")
  TRUSTED_PLATFORM_MODULE_INSTALLED: "TPM installed", // localizationKey("TPM installed")
  TRUSTED_PLATFORM_MODULE_UNINSTALLED: "TPM uninstalled", // localizationKey("TPM uninstalled")
  UNKNOWN: "Unknown activity", // localizationKey("Unknown activity")
  USER_ACCOUNT_ADDED: "User account added", // localizationKey("User account added")
  USER_ACCOUNT_REMOVED: "User account removed", // localizationKey("User account removed")
  USER_LOGGED_IN: "User logged in", // localizationKey("User logged in")
  USER_LOGGED_OUT: "User logged out", // localizationKey("User logged out")
  USER_ROLE_CREATED: "User Role Created", // localizationKey("User Role Created")
  USER_ROLE_DELETED: "User Role Deleted", // localizationKey("User Role Deleted")
  USER_ROLE_UPDATED: "User Role Updated", // localizationKey("User Role Updated")
  VIPREAV_ACTIVEPROTECTION_THREAT_QUARANTINED: "Vipre AV Active Protection Threat Quarantined", // localizationKey("Vipre AV Active Protection Threat Quarantined")
  VIPREAV_DISABLED: "Vipre AV Disabled", // localizationKey("Vipre AV Disabled")
  VIPREAV_INSTALL_FAILED: "Vipre AV Failed", // localizationKey("Vipre AV Failed")
  VIPREAV_INSTALLED: "Vipre AV Installed", // localizationKey("Vipre AV Installed")
  VIPREAV_QUARANTINED_THREAT_REMOVED: "Vipre AV Quarantined Threat Removed", // localizationKey("Vipre AV Quarantined Threat Removed")
  VIPREAV_REBOOT_REQUIRED: "Vipre AV Reboot Required", // localizationKey("Vipre AV Reboot Required")
  VIPREAV_SCAN_ABORTED: "Vipre AV Scan Aborted", // localizationKey("Vipre AV Scan Aborted")
  VIPREAV_SCAN_COMPLETED: "Vipre AV Scan Completed", // localizationKey("Vipre AV Scan Completed")
  VIPREAV_SCAN_FAILED: "Vipre AV Scan Failed", // localizationKey("Vipre AV Scan Failed")
  VIPREAV_SCAN_PAUSED: "Vipre AV Scan Paused", // localizationKey("Vipre AV Scan Paused")
  VIPREAV_SCAN_STARTED: "Vipre AV Scan Started", // localizationKey("Vipre AV Scan Started")
  VIPREAV_SCAN_THREAT_QUARANTINED: "Vipre AV Scan Threat Quarantined", // localizationKey("Vipre AV Scan Threat Quarantined")
  VIPREAV_UNINSTALL_FAILED: "Vipre AV Uninstall Failed", // localizationKey("Vipre AV Uninstall Failed")
  VIPREAV_UNINSTALLED: "Vipre AV Uninstalled", // localizationKey("Vipre AV Uninstalled")
  VIPREAV_USERINITIATED_THREAT_QUARANTINED: "Vipre AV User Initiated Threat Quarantined", // localizationKey("Vipre AV User Initiated Threat Quarantined")
  VIRTUALIZATION: "Virtualization", // localizationKey("Virtualization")
  VOLUME_ADDED: "Volume Added", // localizationKey("Volume Added")
  VOLUME_REMOVED: "Volume Removed", // localizationKey("Volume Removed")
  VULNERABILITY_MANAGEMENT_DEVICE_DETECTIONS_FOUND: "New vulnerability file imported detected one or more vulnerabilities on this device", //localizationKey("New vulnerability file imported detected one or more vulnerabilities on this device")
  VULNERABILITY_MANAGEMENT_DISABLED: "Vulnerability importer disabled", //localizationKey("Vulnerability importer disabled")
  VULNERABILITY_MANAGEMENT_ENABLED: "Vulnerability importer enabled", //localizationKey("Vulnerability importer enabled")
  VULNERABILITY_MANAGEMENT_PATCHING_DISABLED: "Vulnerability Importer Patching disabled", // localizationKey("Vulnerability Importer Patching disabled")
  VULNERABILITY_MANAGEMENT_PATCHING_ENABLED: "Vulnerability Importer Patching enabled", // localizationKey("Vulnerability Importer Patching enabled")
  VULNERABILITY_MANAGEMENT_QUALYS_DISABLED: "Qualys vulnerability importer disabled", //localizationKey("Qualys vulnerability importer disabled")
  VULNERABILITY_MANAGEMENT_QUALYS_ENABLED: "Qualys vulnerability importer enabled", //localizationKey("Qualys vulnerability importer enabled")
  VULNERABILITY_MANAGEMENT_RAPID7_DISABLED: "Rapid7 vulnerability importer disabled", //localizationKey("Rapid7 vulnerability importer disabled")
  VULNERABILITY_MANAGEMENT_RAPID7_ENABLED: "Rapid7 vulnerability importer enabled", //localizationKey("Rapid7 vulnerability importer enabled")
  VULNERABILITY_MANAGEMENT_SCAN_GROUP_CREATED: "Scan group created", //localizationKey("Scan group created")
  VULNERABILITY_MANAGEMENT_SCAN_GROUP_DELETED: "Scan group deleted", //localizationKey("Scan group deleted")
  VULNERABILITY_MANAGEMENT_SCAN_GROUP_FILE_UPDATED: "Scan group file updated", //localizationKey("Scan group file updated")
  VULNERABILITY_MANAGEMENT_SCAN_GROUP_UPDATED: "Scan group updated", //localizationKey("Scan group updated")
  VULNERABILITY_MANAGEMENT_SPOTLIGHT_DISABLED: "CrowdStrike Spotlight vulnerability importer disabled", //localizationKey("CrowdStrike Spotlight vulnerability importer disabled")
  VULNERABILITY_MANAGEMENT_SPOTLIGHT_ENABLED: "CrowdStrike Spotlight vulnerability importer enabled", //localizationKey("CrowdStrike Spotlight vulnerability importer enabled")
  VULNERABILITY_MANAGEMENT_TENABLE_DISABLED: "Tenable vulnerability importer disabled", //localizationKey("Tenable vulnerability importer disabled")
  VULNERABILITY_MANAGEMENT_TENABLE_ENABLED: "Tenable vulnerability importer enabled", //localizationKey("Tenable vulnerability importer enabled")
  WARRANTY_TRACKING_WARRANTY_THRESHOLD_EXCEEDED: "Warranty Threshold Exceeded", // localizationKey("Warranty Threshold Exceeded")
  WEBAPP_MESSAGE: "Webapp Message", // localizationKey("Webapp Message")
  WEBROOT_COMMAND_SUBMITTED: "Webroot command submitted", // localizationKey("Webroot command submitted")
  WEBROOT_INSTALL_FAILED: "Webroot install failed", // localizationKey("Webroot install failed")
  WEBROOT_THREAT_DETECTED: "Webroot threat detected", // localizationKey("Webroot threat detected")
  WINDOWS_PM_MESSAGE: "This device needs a reboot to finalize some process", // localizationKey("This device needs a reboot to finalize some process")
  WINDOWS_SERVICE_START: "Windows Service Start", // localizationKey("Windows Service Start")
  WINDOWS_SERVICE_STARTED: "Windows service started", // localizationKey("Windows service started")
  WINDOWS_SERVICE_STOP: "Windows Service Stop", // localizationKey("Windows Service Stop")
  WINDOWS_SERVICE_STOPPED: "Windows service stopped", // localizationKey("Windows service stopped")
  ZENDESK_CLIENT_MAPPING_CREATED: "Zendesk client mapping created", //localizationKey("Zendesk client mapping created")
  ZENDESK_CLIENT_MAPPING_DELETED: "Zendesk client mapping deleted", //localizationKey("Zendesk client mapping deleted")
  ZENDESK_CLIENT_MAPPING_UPDATED: "Zendesk client mapping updated", //localizationKey("Zendesk client mapping updated")
  ZENDESK_UPDATED: "Zendesk updated", //localizationKey("Zendesk updated")
}

export const getActivityStatusTextToken = serverStatusToken => {
  return activityStatusMapper[serverStatusToken] ?? serverStatusToken
}
export const getLocalizedActivityStatus = serverStatusToken => {
  return _localized(getActivityStatusTextToken(serverStatusToken))
}

export const isActivityStatusSMSNotSupported = (activityStatus = "") => {
  if (!activityStatus) return false
  const unsupportedSMSStatuses = ["SOFTWARE_ADDED", "SOFTWARE_REMOVED"]
  return unsupportedSMSStatuses.includes(activityStatus)
}

const activityTypesMapper = {
  ACTION: "Action", // localizationKey("Action")
  ACTIONSET: "Action Set", // localizationKey("Action Set")
  ACTIVEDIRECTORY: "Active Directory", // localizationKey("Active Directory")
  ANTIVIRUS: "Antivirus", // localizationKey("Antivirus")
  API: "API",
  CHECKLIST_TEMPLATE: "Checklist Template", // localizationKey("Checklist Template")
  CLIENT_CHECKLIST: "Organization Checklist", // localizationKey("Organization Checklist")
  CLOUDBERRY: "Cloudberry",
  CLOUDBERRY_BACKUP: "Cloudberry Backup",
  COMMENT: "Comment", // localizationKey("Comment")
  CONDITION: "Condition", // localizationKey("Condition")
  CONDITION_ACTION: "Condition Action", // localizationKey("Condition Action")
  CONDITION_ACTIONSET: "Condition Action Set", // localizationKey("Condition Action Set")
  DOCUMENTATION: "Documentation", // localizationKey("Documentation")
  DOCUMENTATION_TEMPLATE: "Document Template", // localizationKey("Document Template")
  DOCUMENTATION_CLIENT_DOCUMENT: "Organization Document", // localizationKey("Organization Document")
  DOCUMENTATION_KNOWLEDGE_BASE_FOLDER: "Knowledge Base Folder", // localizationKey("Knowledge Base Folder")
  DOCUMENTATION_PUBLIC_LINK: "Public Link", // localizationKey("Public Link")
  HELP_REQUEST: "Help Request", // localizationKey("Help Request")
  IMAGEMANAGER: "ImageManager",
  INTERNAL: "Internal", // localizationKey("Internal")
  KNOWLEDGE_BASE: "Knowledge Base", // localizationKey("Knowledge Base")
  LOCKHART: "Backup",
  LOCKHART_DASHBOARDS: "Backup Dashboards", // localizationKey("Backup Dashboards")
  LOCKHART_MANAGEMENT: "Backup Management", // localizationKey("Backup Management")
  MAINTENANCE_MODE: "Maintenance Mode", // localizationKey("Maintenance Mode")
  MDM: "Mobile Device Management", // localizationKey("Mobile Device Management"),
  MONITOR: "Monitor", // localizationKey("Monitor")
  NETWORK: "Network", //localizationKey("Network")
  NINJA_CONNECT: "Ninja Remote",
  NINJA_PSA: "Ninja PSA",
  NINJA_QUICK_CONNECT: "Ninja Quick Connect",
  NINJA_TICKETING: "Ticketing", // localizationKey("Ticketing")
  NODE_CUSTOM_FIELD: "Device Custom Field", // localizationKey("Device Custom Field")
  NODE_CACHING: "Node Caching", // localizationKey("Node Caching")
  PATCH_MANAGEMENT: "Patch Management", // localizationKey("Patch Management")
  PSA: "PSA",
  RDP: "Remote Desktop", // localizationKey("Remote Desktop")
  RELATED_ITEM: "Related Item", // localizationKey("Related Item")
  REMOTE_TOOLS: "Remote Tools", // localizationKey("Remote Tools")
  SCHEDULED_TASK: "Scheduled Task", // localizationKey("Scheduled Task")
  SCRIPTING: "Scripting", // localizationKey("Scripting")
  SECURITY: "Security", // localizationKey("Security")
  SHADOWPROTECT: "ShadowProtect",
  SOFTWARE_PATCH_MANAGEMENT: "Software Patch Management", // localizationKey("Software Patch Management")
  SPLASHTOP: "Splashtop",
  SSO_SCIM: "SSO",
  SYSTEM: "System", // localizationKey("System")
  TEAMVIEWER: "TeamViewer",
  VULNERABILITY_MANAGEMENT: "Vulnerabilities", //localizationKey("Vulnerabilities")
  VIRTUALIZATION: "Virtualization", // localizationKey("Virtualization")
  WARRANTY: "Warranty", // localizationKey("Warranty")
}

export const getActivityTypeTextToken = serverActivityTypeToken => {
  return activityTypesMapper[serverActivityTypeToken] ?? serverActivityTypeToken
}
export const getLocalizedActivityType = serverActivityTypeToken => {
  return _localized(getActivityTypeTextToken(serverActivityTypeToken))
}
